/**
 * Created by makkuz on 06.02.18.
 */

import { Component } from "react";
import AddSchoolForm from "./SchoolformComponent";

class addSchool extends Component {
  constructor(props) {
    super(props);
    this.nextSectionSchoolyear = this.nextSectionSchoolyear.bind(this);
  }

  /**
   * create scoolyear
   * save data and go to next step
   */
  nextSectionSchoolyear(data) {
    this.props.updateSchool(data);
    this.props.jumpToStep(1);
  }

  render() {
    const { timeframe } = this.props;

    return (
      <div id="school" className="step step4">
        <div className="step-text">
          <div className="textwrapper">
            <div className="title">Schule</div>
            Trage hier die Schule ein, an der du {timeframe} unterrichten wirst.
            Du kannst das später unter Einstellungen ändern.
          </div>
        </div>
        <AddSchoolForm
          prefill={this.props.prefill}
          schoolyear={this.props.schoolyear}
          nextSectionSchoolyear={this.nextSectionSchoolyear}
        />
      </div>
    );
  }
}
export default addSchool;
