import { Component } from "react";

export default class ABWeeksDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_menu: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.setWeek = this.setWeek.bind(this);
    this.renderDayDialog = this.renderDayDialog.bind(this);
    this.renderHolidayDialog = this.renderHolidayDialog.bind(this);
    this.toggleHollidayRhythm = this.toggleHollidayRhythm.bind(this);
    this.onMouseLeaveClose = this.onMouseLeaveClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active_idx !== this.props.active_idx) {
      this.setState({
        show_menu:
          Number(this.props.active_idx) === Number(this.props.calendar_week),
      });
    }
  }

  onMouseLeaveClose() {
    this.setState({
      show_menu: false,
    });
    this.props.resetActive();
  }

  setWeek(week, idx) {
    this.props.updateABList(week, idx);
  }

  showMenu() {
    this.props.setActiveCol(this.props.calendar_week);
  }

  closeMenu() {
    this.setState({
      show_menu: false,
    });
    this.props.resetActive();
  }

  toggleHollidayRhythm(active) {
    this.props.updateHolidayRhythm(active);
  }

  renderDayDialog(week_type, idx, visible) {
    const css_a_icon = week_type === "A" ? "visible" : "";
    const css_b_icon = week_type === "B" ? "visible" : "";

    return (
      <div
        className={`dialog col-${idx} ${visible}`}
        onMouseLeave={this.onMouseLeaveClose}
      >
        <div className="triangle-wrapper">
          <div className="triangle-top" />
        </div>

        <div className="dialog-wrapper">
          <div>
            <span className="icon">
              <img src="/assets/images/icon-haken.png" className={css_a_icon} />
            </span>
            <span
              onClick={() => {
                this.setWeek("A", idx);
              }}
              className={`ab_changer list ${css_a_icon}`}
            >
              A Woche
            </span>
          </div>
          <div>
            <span className="icon">
              <img src="/assets/images/icon-haken.png" className={css_b_icon} />
            </span>
            <span
              onClick={() => {
                this.setWeek("B", idx);
              }}
              className={`ab_changer list ${css_b_icon}`}
            >
              B Woche
            </span>
          </div>

          <div className="info">
            Bei Änderungen passen sich nachfolgende Wochen automatisch an.
          </div>
        </div>
      </div>
    );
  }

  renderHolidayDialog(week_type, idx, visible, holiday_break) {
    const css_break_icon = holiday_break === true ? "visible" : "";
    const css_continue_icon = !holiday_break ? "visible" : "";

    return (
      <div
        className={`dialog col-${idx} ${visible} holiday`}
        onMouseLeave={this.onMouseLeaveClose}
      >
        <div className="triangle-wrapper">
          <div className="triangle-top" />
        </div>
        <div className="dialog-wrapper holiday">
          <div>
            <span className="icon">
              <img
                src="/assets/images/icon-haken.png"
                className={css_continue_icon}
              />
            </span>
            <span
              onClick={() => {
                this.toggleHollidayRhythm(false);
              }}
              className={`ab_changer list ${css_continue_icon}`}
            >
              Rythmus in Ferien fortführen
            </span>
          </div>
          <div>
            <span className="icon">
              <img
                src="/assets/images/icon-haken.png"
                className={css_break_icon}
              />
            </span>
            <span
              onClick={() => {
                this.toggleHollidayRhythm(true);
              }}
              className={`ab_changer list ${css_break_icon}`}
            >
              Rythmus in Ferien pausieren
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { week_type, idx, holiday, holiday_break } = this.props;
    const { show_menu } = this.state;
    const visible = show_menu ? "active" : "";

    const dialog = holiday
      ? this.renderHolidayDialog(week_type, idx, visible, holiday_break)
      : this.renderDayDialog(week_type, idx, visible);

    return (
      <div className="inner-wrapper">
        <a
          className={week_type}
          onClick={() => {
            this.showMenu();
          }}
        >
          {week_type === "-" ? <span>&nbsp;</span> : <span>{week_type}</span>}
        </a>
        {dialog}
      </div>
    );
  }
}
