import { Component } from "react";
import AddSchoolForm from "../../../common/components/settings/school/AddSchoolForm";

class addSchool extends Component {
  constructor(props) {
    super(props);
    this.prevSection = this.prevSection.bind(this);
    this.nextSectionSchoolyear = this.nextSectionSchoolyear.bind(this);
    this.prevSectionSchoolyear = this.prevSectionSchoolyear.bind(this);
  }

  /**
   * create scoolyear
   * save data and go to next step
   */
  nextSectionSchoolyear() {
    this.props.updateSchool();
    this.props.jumpToStep(1);
  }

  prevSection() {
    this.props.jumpToStep(3);
  }

  prevSectionSchoolyear() {
    this.props.jumpToStep(3);
  }

  render() {
    return (
      <div id="school" className="step step4">
        <div className="step-text">
          <div className="textwrapper">
            <div className="title">Schule</div>
            Die Angaben zur Schule verwenden wir in den Plänen, die wir für dein
            Schriftwesen erstellen. Du kannst die Felder auch leer lassen.
          </div>
        </div>
        <AddSchoolForm
          schoolyear={this.props.schoolyear}
          context="registration"
          prevSection={this.prevSection}
          nextSectionSchoolyear={this.nextSectionSchoolyear}
          prevSectionSchoolyear={this.prevSectionSchoolyear}
        />
      </div>
    );
  }
}
export default addSchool;
