// import svgs as components
// import IconWord from "../../../assets/images/svg/icon-word.svg";
// import IconPdf from "../../../assets/images/svg/icon-pdf.svg";
// import IconPpt from "../../../assets/images/svg/icon-ppt.svg";
// import IconXls from "../../../assets/images/svg/icon-excel.svg";
// import IconKeyNote from "../../../assets/images/svg/icon-keynote.svg";
// import IconUnknown from "../../../assets/images/svg/icon-unbekannt.svg";
// import IconImg from "../../../assets/images/svg/icon-bilddatei.svg";
// import IconNumbers from "../../../assets/images/svg/icon-numbers.svg";
// import IconPages from "../../../assets/images/svg/icon-pages.svg";
// import IconVideo from "../../../assets/images/svg/icon-videodatei.svg";

// use as image instead of inline svg because of a parcel bug that seems to break the shadows
// when using these icons as React components
// https://github.com/parcel-bundler/parcel/issues/9916

/**
 * map filename or extension to display file icons
 * @param name
 * @returns {string}
 */

const fileExtensionRegex = /([^.]+)$/;
export function FileIcon({ name }: { name: string }) {
  const match = name.match(fileExtensionRegex);
  const extension = match ? match[0].toLowerCase() : "";
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
    case "tiff":
    case "bmp":
    case "svg":
      // return <IconImg />;
      return <img src="/assets/images/svg/icon-bilddatei.svg" />;

    case "mpg":
    case "mpeg":
    case "avi":
    case "wmv":
    case "mov":
    case "flv":
    case "mp4":
      // return <IconVideo />;
      return <img src="/assets/images/svg/icon-videodatei.svg" />;

    case "numbers":
      // return <IconNumbers />;
      return <img src="/assets/images/svg/icon-numbers.svg" />;

    case "pages":
      // return <IconPages />;
      return <img src="/assets/images/svg/icon-pages.svg" />;

    case "pdf":
      // return <IconPdf />;
      return <img src="/assets/images/svg/icon-pdf.svg" />;

    case "doc":
    case "docx":
      // return <IconWord />;
      return <img src="/assets/images/svg/icon-word.svg" />;

    case "key":
      // return <IconKeyNote />;
      return <img src="/assets/images/svg/icon-keynote.svg" />;

    case "ppt":
    case "pptx":
      // return <IconPpt />;
      return <img src="/assets/images/svg/icon-ppt.svg" />;

    case "xls":
    case "xlsx":
      // return <IconXls />;
      return <img src="/assets/images/svg/icon-excel.svg" />;

    default:
      // return <IconUnknown />;
      return <img src="/assets/images/svg/icon-unbekannt.svg" />;
  }
}
