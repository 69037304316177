import { useState } from "react";
import { DateTime } from "luxon";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import FGEditor from "../../../../common/components/sequences/FGEditor";
import PdfSequence from "../../../../common/pdf/PdfSequence";
import ArrowBack from "../../../../assets/images/svg/pfeil-zurueck.svg";
import {
  selectCurrentSchoolyearData,
  selectCurrentSchoolyear,
} from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { api, selectGetSchoolData } from "../../../../services/api";
import { type RootState } from "../../../store";
import { useAppSelector } from "../../../hooks";
import {
  selectSchoolRegion,
  selectSequenceDetail,
} from "../../../combined-selectors/index";
import { type School } from "../../../../features/school/types";
import { type Slot } from "../../../../features/sequence/types";

const selectProps = createSelector(
  [
    selectSequenceDetail,
    selectCurrentSchoolyearData,
    (state: RootState): School =>
      selectGetSchoolData(state, selectCurrentSchoolyear(state)),
    (state: RootState) => state.authentication,
    selectSchoolRegion,
    (state: RootState) => state.timetable,
  ],
  (
    sequenceState,
    schoolyearData,
    school,
    authentication,
    region,
    timetable,
  ) => ({
    active_sequence: sequenceState.active_sequence_item,
    active_sequence_cluster: sequenceState.sequenceCluster,
    active_sequence_didakt: sequenceState.active_sequence_didakt,
    timeslots: sequenceState.timeslots,
    dayslot_lehrplanbezug: sequenceState.dayslot_lehrplanbezug,
    editor_idx: sequenceState.editor_idx,
    dayslotid: sequenceState.dayslotid,
    timetable,
    authentication,
    school,
    current_schoolyear: schoolyearData,
    region,
  }),
);

type Props = ReturnType<typeof selectProps>;

const INPUT_DATE_FORMAT: string = "yMMdd";

function printSequencePdf(props: Props) {
  const { calc_date, calc_end_date } = props.active_sequence;
  const display_start_date = DateTime.fromFormat(
    calc_date,
    INPUT_DATE_FORMAT,
  ).toLocaleString({
    day: "numeric",
    month: "long",
  });
  const display_end_date = DateTime.fromFormat(
    calc_end_date,
    INPUT_DATE_FORMAT,
  ).toLocaleString({
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const display_date = `${display_start_date} bis ${display_end_date}`;

  const seq_didakt_text =
    (!props.active_sequence_didakt ||
      props.active_sequence_didakt === "<p><br></p>") &&
    props.active_sequence_cluster.diktat
      ? props.active_sequence_cluster.diktat
      : props.active_sequence_didakt;

  PdfSequence(
    props.school,
    props.authentication,
    props.active_sequence_cluster,
    {
      display_date,
      didaktik: seq_didakt_text,
      lehrplanbezug: props.dayslot_lehrplanbezug,
      sequence_title: props.active_sequence.title,
      saturday: props.timetable.saturday,
    },
    props.timeslots,
    props.active_sequence.calc_end_date,
    props.current_schoolyear,
  );
}

type ListItem = { label: string; value: string };

function SequenceMetaHeaderComponent() {
  const [queryParams] = useSearchParams();
  const ueId = queryParams.get("ueId");
  const { sequenceId = "" } = useParams();
  const props = useAppSelector((state) =>
    selectProps(state, { sequenceId, ueId }),
  );

  const [updateSequenceItemDataTrigger] =
    api.endpoints.updateSequenceItemData.useMutation();

  const updateSequenceItemsForDiktat = (html: string) => {
    const newSequenceItem = { ...props.active_sequence, didakt: html };
    updateSequenceItemDataTrigger({
      sequenceId,
      schoolyear: props.school.year,
      region: props.region,
      sequenceItem: newSequenceItem,
    });
  };

  const [state, setState] = useState<{ show_details: boolean }>({
    show_details: false,
  });
  const idx_update_key = `${props.dayslotid}-${props.editor_idx}`;

  const toggleDetails = () => {
    setState((prevState) => ({ show_details: !prevState.show_details }));
  };
  const { title, calc_date, calc_end_date } = props.active_sequence;

  const display_start_date = calc_date
    ? DateTime.fromFormat(calc_date, INPUT_DATE_FORMAT).toLocaleString({
        day: "numeric",
        month: "long",
      })
    : "";
  const display_end_date = calc_end_date
    ? DateTime.fromFormat(calc_end_date, INPUT_DATE_FORMAT).toLocaleString({
        day: "numeric",
        month: "long",
      })
    : "";
  const weeks = props.active_sequence.label_weeks
    ? props.active_sequence.label_weeks
    : props.active_sequence.weeks;
  const hours = props.active_sequence.label_hours
    ? props.active_sequence.label_hours
    : props.active_sequence.hours;

  // auslagern
  let show_detail_text = "Details einblenden";
  const show_details = state.show_details ? " show " : "";
  if (show_details === " show ") {
    show_detail_text = "Details ausblenden";
  }
  const seq_didakt_text =
    (!props.active_sequence_didakt ||
      props.active_sequence_didakt === "<p><br></p>") &&
    props.active_sequence_cluster.diktat
      ? props.active_sequence_cluster.diktat
      : props.active_sequence_didakt;

  const lehrplansequence: ListItem[] = [];
  const seq_lehrplanbezug_list: Record<string, string> = {};

  props.timeslots.forEach((elem: Slot) => {
    if (elem.lehrplanbezug) {
      elem.lehrplanbezug.forEach((lb: ListItem) => {
        if (!seq_lehrplanbezug_list[lb.value]) {
          seq_lehrplanbezug_list[lb.value] = lb.value;
          lehrplansequence.push({
            label: lb.label,
            value: lb.value,
          });
        }
      });
    }
  });

  return (
    <div className="sequence-meta-header-wrapper">
      <div className="meta-wrapper">
        <div className="relative">
          <div
            className="flex items-center box-border absolute top-1 transform -translate-x-full font-bold mr-2.5 h-[35px]
                  after:border-0 after:border-r-2 after:border-solid after:border-[#afafaf] after:h-full after:content-[''] after:block"
          >
            <Link to="/zeitleiste" className="flex items-center h-full pr-2.5 ">
              <ArrowBack width="0.5rem" />
            </Link>
          </div>
          <div className="ml-2.5 inline-block">
            <h1>{title}</h1>
            <div className="meta">
              <a>{display_start_date}</a> - <a>{display_end_date}</a>
              <span className="inline-block pl-2">
                {" "}
                | {weeks} Wochen / {hours} Stunden{" "}
              </span>
              <span className="inline-block pl-2">
                {" "}
                |{" "}
                <a className="anchor" onClick={toggleDetails}>
                  {show_detail_text}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div style={{ clear: "both" }} />
      </div>

      <div className={`details_wrapper ${show_details}`}>
        <div className="pdf_download">
          <span className="icon-wrapper">
            <img src="/assets/images/pdf-icon.png" className="icon" />
          </span>
          <span className="text-wrapper">
            <a href="#" onClick={() => printSequencePdf(props)}>
              Sequenzplan herunterladen
            </a>
          </span>
        </div>
        <div className="didakt_wrapper">
          <div className="editor-wrapper">
            <div className="section">
              <div className="title">Didaktischer Hintergrund (Sequenz)</div>
              <div className="text">
                <FGEditor
                  key={`seq_ditakt_editor-${idx_update_key}`}
                  placeholder="Hier hinklicken um Informationen zum didaktischen Hintergrund der Sequenz einzutragen"
                  value={seq_didakt_text}
                  onChange={() => {}}
                  onBlurCall={updateSequenceItemsForDiktat}
                />
              </div>
            </div>
          </div>
          <div className="tags_wrapper">
            <div className="section">
              <div className="title readonly">Gefundener Lehrplanbezug</div>
              <div className="text css-1rtrksz">
                {lehrplansequence.map((elem, idx) => (
                  <div key={`tag-${idx}`} className="css-xwjg1b">
                    <div className="css-10odw4q">{elem.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
}

export default SequenceMetaHeaderComponent;
