import { createSelector } from "@reduxjs/toolkit";
import * as CONST from "../../common/constants/Components";
import {
  getWeekNummer2PositionMap,
  getYearScooldDays,
  findDatesInMap,
} from "../../common/components/sequence_calendar/Utils";
import {
  setUpSequenceItem,
  calendar_days,
  prepareCalendarDataFromTimeslots,
} from "../../common/components/sequences/Utils.js";
import {
  createPatchSequenceDetailResultSelector,
  selectGetHolidaysData,
  selectGetSchoolData,
} from "../../services/api";
import {
  selectLessonDuration,
  selectABWeekSettings,
} from "../../features/settings/settings-slice";
import {
  selectUeMaterial,
  selectSequenceMaterial,
} from "../../features/sequence/sequence-slice";
import { type SliceState as TimelineState } from "../../features/timeline/timeline-slice";
import {
  type SequenceItem,
  type SequenceItemData,
} from "../../features/sequence_items/types";

import { selectCurrentSchoolyear } from "../../features/current-schoolyear/current-schoolyear-slice";
import { type RootState } from "../store";
import { type ABWeekSettingsForYear } from "../../features/settings/types";
import { type Slotday } from "../../features/sequence/types";

type SequenceParams = { sequenceId: string; ueId: string | null };

type WeekNumber2PositionEntry = {
  id: number;
  pos_start: number;
  pos_end: number;
  kw: string;
  year: string;
  date: string;
  end_date: string;
  compDate: string;
  compEndDate: string;
  date_short: string;
  kw_human: string;
  calc_date: string;
  month_break: boolean;
  month_name: string;
  calc_end_date: string;
  holiday: string;
  ab_week: string;
};

export function selectSchoolRegion(state: RootState) {
  return selectGetSchoolData(state, selectCurrentSchoolyear(state)).region;
}

export const selectSchoolWithHolidays = createSelector(
  [
    (state: RootState) =>
      selectGetSchoolData(state, selectCurrentSchoolyear(state)),
    (state: RootState) =>
      selectGetHolidaysData(state, {
        region: selectSchoolRegion(state),
        schoolyear: selectCurrentSchoolyear(state),
      }),
  ],
  (schoolData, holidayData) => {
    const {
      start: startDate,
      end: endDate,
      holidays,
      id,
      modified,
      modifiedPublicHolidays,
      modifiedHolidays,
      label,
    } = holidayData;

    return {
      ...schoolData,
      startDate,
      endDate,
      holidays,
      modified,
      modifiedPublicHolidays,
      modifiedHolidays,
      schoolYear: id,
      schoolyearLabel: label,
      calendarMap: getWeekNummer2PositionMap(
        startDate,
        endDate,
        CONST.CALENDAR_WEEK_UNIT,
        holidays,
      ) satisfies WeekNumber2PositionEntry[],
      schoolYearDays: getYearScooldDays(
        startDate,
        endDate,
        CONST.CALENDAR_WEEK_UNIT,
      ),
    };
  },
);

// SEQUENCE STUFF

export const selectSequenceItems = createSelector(
  [
    (state) => state.sequence_items,
    selectSchoolWithHolidays,
    selectABWeekSettings,
    selectLessonDuration,
  ],
  (seqItems, school, abWeekSettings, lessonDuration) =>
    prepareSequenceItems(
      seqItems,
      school,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      lessonDuration,
    ),
);

/**
 * handle and prepare initial state data
 * @param data
 * @return {{type: string, sequence_items: Array, data: *, message: *}}
 */
function prepareSequenceItems(
  data: TimelineState,
  school: ReturnType<typeof selectSchoolWithHolidays>,
  ab_weeks: boolean,
  ab_settings: ABWeekSettingsForYear | null,
  lessonDuration: number,
): SequenceItem[] {
  const class_subject_list: SequenceItem[] = [];
  const classlist = data.classlists;

  classlist.forEach((elem) => {
    elem.subjects.forEach((subs) => {
      const tmp_item: SequenceItem = {
        ...subs,
        classroom_id: elem.classId,
        schoolyear: Number(elem.schoolyear),
        classroom_title: elem.title,
        subjectlist_id: subs._id,
        scooldays: [],
        height: 0,
        data: [],
      };

      if (subs.schooldays.length > 0) {
        const new_school_list: SequenceItem["schooldays"] = [];
        subs.schooldays.forEach((schoolday) => {
          if (ab_settings && schoolday.week_type) {
            const elem_exists_idx = new_school_list.findIndex(
              (list) =>
                list.day === schoolday.day &&
                list.week_type === schoolday.week_type,
            );
            if (elem_exists_idx === -1) {
              // not exists push it
              new_school_list.push({
                count: 1,
                day: schoolday.day,
                day_name: schoolday.day_name,
                week_type: schoolday.week_type,
              });
            } else if (new_school_list[elem_exists_idx]) {
              // element exists, increment hours
              new_school_list[elem_exists_idx].count += 1;
            }
          } else {
            const elem_exists_idx = new_school_list.findIndex(
              (list) => list.day === schoolday.day,
            );
            if (elem_exists_idx === -1) {
              // erstellen der Stunden usw. ohne AB Wochen
              new_school_list.push({
                count: 1,
                day: schoolday.day,
                day_name: schoolday.day_name,
                week_type: schoolday.week_type,
              });
            } else if (new_school_list[elem_exists_idx]) {
              new_school_list[elem_exists_idx].count += 1;
            }
          }
        });

        const result = new_school_list;

        result.forEach((sd) => {
          sd.hour = sd.count * lessonDuration;
          sd.day = Number(sd.day) + 1;
        });

        tmp_item.scooldays = result;
      } else {
        tmp_item.scooldays = [];
      }
      class_subject_list.push(tmp_item);
    });
  });

  let items: SequenceItem[] = [];
  if (data.sequences) {
    // push data elements from exists sequence items to subject list.
    // subject list comes from settings an is newer in some cases.
    items = data.sequences.sequence_items;
    const new_list: SequenceItem[] = class_subject_list;
    new_list.forEach((elem) => {
      items.forEach((item) => {
        const mappedDataList: SequenceItemData[] = [];
        if (
          item._id === elem._id &&
          Number(item.classroom_id) === Number(elem.classroom_id)
        ) {
          if (item.data) {
            item.data.forEach((ed) => {
              const map_res = findDatesInMap(
                school.calendarMap,
                ed.pos,
                ed.weeks,
                school.holidays,
                elem.scooldays,
                ab_weeks,
                ab_settings ?? undefined,
                lessonDuration,
              );

              mappedDataList.push({
                ...ed,
                calc_date: map_res.calc_date,
                calc_end_date: map_res.calc_end_date,
                date: map_res.date,
                daterange: map_res.date_range,
                label_weeks: map_res.label_weeks,
                label_hours: map_res.label_scoolhours,
              });
            });
          }

          elem.data = mappedDataList;
          elem.height = item.height;
        }
        if (!elem.data) {
          elem.data = [];
        }
      });
    });

    // check of origin subjects are same length, if not push an empty data array.
    if (new_list.length > items.length) {
      new_list.forEach((elem) => {
        if (!elem.data) {
          elem.data = [];
        }
      });
    }
    items = new_list;
  } else {
    // nothing exists - set new
    items = class_subject_list;
    items.forEach((elem) => {
      elem.data = [];
      elem.height = CONST.CALENDAR_ITEMS_ROW_HEIGHT;
    });
  }
  return items;
}

const emptySequenceData = {
  sequence: {
    _id: "",
    sequence_item_id: 0,
    user_id: 0,
    timeslots: [],
    schoolyear: 0,
    userId: "",
  },
  sequenceItemData: {
    id: "",
    schoolyear: 0,
    schooldays: [],
    title: "",
    color: "",
    classroomId: 0,
    classroomTitle: "",
    subjectlistId: "",
    scooldays: [],
    height: 0,
    data: {
      pos: 0,
      pos_end: 0,
      id: 0,
      note: "",
      didakt: "",
      weeks: 0,
      calc_date: "",
      calc_end_date: "",
      date: "",
      daterange: "",
      label_weeks: 0,
      label_hours: 0,
    },
  },

  subject: {
    color: "",
    createdAt: "",
    import_id: "",
    schooldays: [],
    schoolyear: 0,
    title: "",
    user_id: 0,
    _id: "",
  },
};

function selectPatchSequenceDetailData(state: RootState, id: string) {
  const schoolyear = selectCurrentSchoolyear(state);
  const region = selectSchoolRegion(state);

  const selectPatchSequenceDetailResult =
    createPatchSequenceDetailResultSelector({ id, schoolyear, region });
  const result = selectPatchSequenceDetailResult(state);
  return result.data ?? emptySequenceData;
}

export const selectSequenceDetail = createSelector(
  [
    (state: RootState, seqParams: SequenceParams) =>
      selectPatchSequenceDetailData(state, seqParams.sequenceId),
    selectSequenceMaterial,
    selectUeMaterial,
    selectABWeekSettings,
    selectSchoolWithHolidays,
    selectLessonDuration,
    (_, seqParams: SequenceParams) => seqParams.ueId,
  ],
  (
    sequencesState,
    sequenceMaterial,
    ueMaterial,
    abWeekSettings,
    schoolWithHolidays,
    lessonDuration,
    ueId,
  ) => {
    /**
     * wenn timeslots leer sind, also noch keiner angelegt ist, timeslots für die komplette zeitleiste anlegen
     * key 0 = erstes element zeitleiste im array.
     *
     * beim existierenden timeslosts liste prüfen ob diese noch passt und entsprechend anpassen.
     *
     * */

    const sequenceItem = setUpSequenceItem(
      sequencesState.subject,
      sequencesState.sequenceItemData,
      schoolWithHolidays,
      abWeekSettings.ab_settings,
      abWeekSettings.ab_weeks,
      lessonDuration,
    );

    const seq_didakt = sequenceItem.data.didakt ? sequenceItem.data.didakt : "";

    const tabValue = sequenceItem.data.tab_created
      ? "notes-unit"
      : "articulation";

    const timeslots =
      sequencesState.sequence && sequencesState.sequence.timeslots
        ? [...sequencesState.sequence.timeslots]
        : [];

    const ueIndex = ueId
      ? timeslots.findIndex((list) => list.ueId === ueId)
      : 0;

    const calendarData: Slotday[] = calendar_days(
      sequenceItem.data.calc_date,
      sequenceItem.data.calc_end_date,
      sequenceItem.scooldays,
      schoolWithHolidays.holidays,
      schoolWithHolidays.calendarMap,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
    );

    const calDays: Slotday[] = prepareCalendarDataFromTimeslots(
      calendarData,
      timeslots,
    );

    calDays.forEach((elem, i: number) => {
      if (timeslots[i]) {
        timeslots[i] = { ...timeslots[i], id: elem.day_id, date: elem.date };
        elem.title = timeslots[i].title;
      } else {
        elem.title = "";
        timeslots.push({
          id: elem.day_id,
          data: [],
          title: "",
          tab: tabValue,
          note: "",
          homework: "",
          idx: i,
          date: elem.date,
          didakt: "",
          lehrplanbezug: [],
          ueId: "",
        });
      }
    });

    // take first day that isn't a holiday as start day
    const startDay: Slotday | undefined = calDays.find(
      (day, i) => i >= ueIndex && !day.holiday,
    );

    const foundTimeslot = timeslots.find(
      (el) => startDay && String(el.id) === String(startDay.day_id),
    );

    const foundIndex: number = foundTimeslot
      ? timeslots.indexOf(foundTimeslot)
      : -1;

    // if timeslots exists, use it otherwise create new one
    const daySlotProps = foundTimeslot
      ? {
          active_timeslot: foundTimeslot.data,
          dayslot_title: foundTimeslot.title,
          dayslot_tab: foundTimeslot.tab || tabValue,
          dayslot_note: foundTimeslot.note,
          dayslot_homework: foundTimeslot.homework,
          dayslot_didakt: foundTimeslot.didakt || "",
          dayslot_lehrplanbezug: foundTimeslot.lehrplanbezug || "",
        }
      : {
          active_timeslot: [],
          dayslot_title: "",
          dayslot_tab: tabValue,
          dayslot_note: "",
          dayslot_homework: "",
          dayslot_didakt: "",
          dayslot_lehrplanbezug: [],
        };
    const startDayProps = startDay
      ? {
          dayslotid: startDay.day_id,
          slot_duration: startDay.slot_duration,
          dayslotdate: startDay.dayslotdate,
          dayslot_hours: startDay.hours_text,
        }
      : {
          dayslotid: "",
          slot_duration: 0,
          dayslotdate: "",
          dayslot_hours: "",
        };

    return {
      ...sequencesState,
      materialModel: sequenceMaterial,
      ueMaterialModel: ueMaterial,
      dayObjId: foundTimeslot && foundTimeslot.ueId ? foundTimeslot.ueId : null,
      ...startDayProps,
      active_sequence_item: sequenceItem.data,
      sequenceCluster: sequenceItem,
      timeslots,
      slotdays: calDays,
      active_slot_idx: foundIndex,
      ...daySlotProps,
      active_sequence_didakt: seq_didakt,
      editor_idx: sequencesState.sequence.sequence_item_id,
      _id:
        sequencesState.sequence && sequencesState.sequence._id
          ? sequencesState.sequence._id
          : "",
      note: sequenceItem.data.note ?? "",
    };
  },
);
