import type { LinkHTMLAttributes, ReactNode } from "react";
import { classList } from "../utils/classList";
import LogoFreigeist from "../../assets/images/svg/logo-freigeist.svg";
import { type Class } from "../../features/classes/types";

function Link({
  to,
  children,
  ...props
}: {
  to: string;
  children: ReactNode;
} & LinkHTMLAttributes<unknown>) {
  return (
    <a href={to} {...props}>
      {children}
    </a>
  );
}

type MenuLinks = {
  text: string;
  href: string;
  colorClass: string;
  followedBySeparator: boolean;
};

const menuLinks: MenuLinks[] = [
  {
    text: "Einstellungen",
    href: "/einstellungen",
    colorClass: "text-gray-10",
    followedBySeparator: false,
  },
  {
    text: "Downloads",
    href: "/downloads",
    colorClass: "text-gray-10",
    followedBySeparator: false,
  },
  {
    text: "Vorlagen",
    href: "/vorlagen",
    colorClass: "text-gray-10",
    followedBySeparator: false,
  },
  {
    text: "Archiv",
    href: "/schuljahre",
    colorClass: "text-gray-10",
    followedBySeparator: true,
  },
  {
    text: "Tutorials",
    href: "https://www.freigeist-app.de/tutorials",
    colorClass: "text-gray-30",
    followedBySeparator: false,
  },
  {
    text: "Kontakt",
    href: "mailto: hallo@freigeist-app.de",
    colorClass: "text-gray-30",
    followedBySeparator: true,
  },
  {
    text: "Abmelden",
    href: "/login",
    colorClass: "text-gray-60",
    followedBySeparator: false,
  },
];

function HeaderTabs({
  activeTab,
  classes,
}: {
  activeTab: string;
  classes: Class[];
}) {
  return (
    <ul className="flex flex-nowrap">
      <TabItem
        key="name"
        text="Kalender"
        href="/kalender"
        isActive={activeTab === "kalender"}
      />
      {classes.map((p: Class) => {
        const name = `class-${p.classId}`;
        return (
          <TabItem
            key={name}
            text={p.title}
            href={`/zeitleiste?classId=${p.classId}`}
            isActive={name === activeTab}
          />
        );
      })}
    </ul>
  );
}

function TabItem({
  text,
  isActive,
  href,
}: {
  text: string;
  isActive: boolean;
  href: string;
}) {
  const linkClasses = classList({
    "block px-5 font-bold leading-10 whitespace-nowrap transition-colors": true,
    "bg-gray-90": isActive,
    "opacity-60 hover:bg-gray-70": !isActive,
    "relative before:top-0 before:inset-x-0 before:h-1 before:bg-[#1ca0e2] before:block before:absolute":
      isActive,
  });
  return (
    <li>
      <Link to={href} className={linkClasses}>
        {text}
      </Link>
    </li>
  );
}

function FreigeistHeader({
  name,
  activeTab,
  classes,
}: {
  name: string;
  activeTab: string;
  classes: Class[];
}) {
  return (
    <header className="relative z-[200] flex items-center w-screen tw-pf tw bg-gray-80">
      <div className="flex items-center flex-none h-10 mx-4">
        <Link className="block" to="/zeitleiste">
          <LogoFreigeist className="w-[26px] h-[26px]" />
        </Link>
      </div>

      <div className="flex items-center self-stretch flex-none px-4">
        {name}
      </div>

      <nav className="overflow-x-auto">
        <HeaderTabs classes={classes} activeTab={activeTab} />
      </nav>

      <div className="relative flex items-center self-stretch flex-none px-4 ml-auto cursor-pointer group shadow-left">
        <div>
          <img className="inline" src="/assets/images/pfeil-lehrername.png" />{" "}
          Account
        </div>

        <nav className="absolute right-0 hidden top-8 group-hover:block">
          <div className="w-0 mx-auto border-b-8 border-transparent border-b-gray-100 border-t-transparent border-x-8" />
          <ul className="w-32 bg-gray-100 shadow-xl px-3.5 py-2.5">
            {menuLinks.map(
              ({ text, href, colorClass, followedBySeparator }) => {
                const url = new URL(href, window.location.href);
                const isHttpCrossOrigin =
                  url.protocol.startsWith("http") &&
                  url.origin !== window.location.origin;
                const listItemClass = classList({
                  "leading-8": true,
                  "border-b border-gray-60": followedBySeparator,
                });
                return (
                  <li className={listItemClass} key={href}>
                    <Link
                      className={`transition-[color,opacity] hover:underline block ${colorClass}`}
                      to={href}
                      {...(isHttpCrossOrigin ? { target: "_blank" } : {})}
                    >
                      {text}
                    </Link>
                  </li>
                );
              },
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

/*

This component can be exposed as a web component in the future
Not necessary yet

import { register } from "web-react-components";

// wrapper to set tw-* classes and inject stylesheets into shadow DO
function FreigeistHeaderWC(props) {
  const preflightUrl = new URL("../../styles/preflight.css", import.meta.url);
  const styleSheetUrl = new URL("../../styles/style.css", import.meta.url);

  return (
    <div className="tw-pf tw" style={{ display: "contents" }}>
      <link rel="stylesheet" href={preflightUrl} />
      <link rel="stylesheet" href={styleSheetUrl} />
      <FreigeistHeader {...props} />
    </div>
  );
}

// expose this react component as a web component
register(FreigeistHeader, "freigeist-header", ["name", "classes", "activeTab"]);

*/

export { FreigeistHeader };
