import { Component } from "react";
import { connect } from "react-redux";

import FormField from "./FormField";
import PasswordField from "./PasswordField";
import ScreenNote from "../../ScreenNotes";

import * as ACTIONS from "../../../../features/authentication/actions";
import * as PASSWORD_ACTIONS from "../../../../features/password/password-slice";

const mapStateToProps = (state, ownProps) => ({
  authentication: state.authentication,
  password: state.password,
  registration: state.registration,
  isChangePassword: ownProps.changePassword,
});

const mapDispatchToProps = {
  updatePassword: PASSWORD_ACTIONS.updatePassword,
  updatePasswordRepeat: PASSWORD_ACTIONS.updatePasswordRepeat,
  changePassword: ACTIONS.changePassword,
  stampNoteMessage: ACTIONS.stampNoteMessage,
};

class PasswordForm extends Component {
  constructor(props) {
    super(props);

    this.passwordChanged = this.fieldStateChanged("password");
    this.passwordRepeatChanged = this.fieldStateChanged("password_repeat");

    this.state = {
      show_success_note: false,
      show_error_note: false,
    };
    this.setPasswordValue = this.setPasswordValue.bind(this);
    this.validatePasswordRepeat = this.validatePasswordRepeat.bind(this);
    this.renderProcessButtons = this.renderProcessButtons.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    this.getSuccessNote = this.getSuccessNote.bind(this);
    this.getErrorNote = this.getErrorNote.bind(this);
    this.closeErrorNote = this.closeErrorNote.bind(this);
    this.closeSuccessNote = this.closeSuccessNote.bind(this);
    // this.stamtNoteMessage = this.stamtNoteMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const changePassNoteChanged =
      prevProps.authentication?.changePassNote !==
      props.authentication?.changePassNote;
    if (
      changePassNoteChanged &&
      props.authentication?.changePassNote === "success"
    ) {
      this.setState({
        show_success_note: true,
      });
      this.props.stampNoteMessage();
    }
    if (
      changePassNoteChanged &&
      props.authentication.changePassNote === "error"
    ) {
      this.setState({
        show_error_note: true,
      });
      this.props.stampNoteMessage();
    }
  }

  setPasswordValue(value) {
    this.props.updatePassword(value);
  }

  /**
   */

  /**
   * show success note
   * @returns {*}
   */
  getSuccessNote() {
    return (
      <ScreenNote
        type="success"
        message="Das Passwort wurde erfolgreich geändert."
        closeCallback={this.closeSuccessNote}
        onAnimationEnd={this.closeSuccessNote}
      />
    );
  }

  /**
   * close the error note layer
   */

  /**
   * show error note
   * @returns {*}
   */
  getErrorNote() {
    return (
      <ScreenNote
        type="error"
        message="Das Passwort konnte nicht geändert werden."
        target="einstellungen"
        closeCallback={this.closeErrorNote}
      />
    );
  }

  // higher-order function that returns a state change watch function
  // sets the corresponding state property to true if the form field has no errors
  fieldStateChanged(field) {
    return (state) => this.setState({ [field]: state.errors.length === 0 });
  }

  closeSuccessNote() {
    this.setState({
      show_success_note: false,
    });
  }

  validatePasswordRepeat(value) {
    this.props.updatePasswordRepeat(value);
  }

  changePassword() {
    this.props.changePassword(this.props.password.password);
  }

  resetPassword() {
    this.props.resetPassword(this.props.password.password);
  }

  /**
   * close the error note layer
   */
  closeErrorNote() {
    this.setState({
      show_error_note: false,
    });
  }

  renderProcessButtons() {
    const nextenabled =
      this.props.password.password === this.props.password.password_repeat;
    if (this.props.isChangePassword) {
      return (
        <div className="steps-buttons">
          <button
            disabled={!nextenabled}
            className="btn btn-step"
            href="#"
            onClick={this.changePassword}
          >
            Passwort ändern
          </button>
        </div>
      );
    }
    if (this.props.passwordReset) {
      return (
        <div className="steps-buttons">
          <button
            disabled={!nextenabled}
            className="btn btn-step"
            href="#"
            onClick={this.resetPassword}
          >
            Passwort ändern
          </button>
        </div>
      );
    }
    return (
      <div className="steps-buttons">
        <button
          disabled={!nextenabled}
          className="btn btn-step"
          href="#"
          onClick={() => this.props.nextSection(1)}
        >
          Weiter zu Fächern
        </button>
      </div>
    );
  }

  render() {
    const { fullname, email } = this.state;
    const formValidated = fullname && email;

    let label_field_1 = "Passwort";
    let label_field_2 = "Passwort wiederholen";

    if (this.props.changePasswordForm) {
      label_field_1 = "Neues Passwort";
      label_field_2 = "Neues Passwort wiederholen";
    }
    let notes;
    if (this.state.show_success_note) notes = this.getSuccessNote();
    if (this.state.show_error_note) notes = this.getErrorNote();

    return (
      <div id="password_form">
        {notes}

        <div className="password-form">
          {/** Show the form button only if all fields are valid * */}
          {formValidated && (
            <button
              type="button"
              className="btn btn-primary text-uppercase px-3 py-2"
            >
              Join
            </button>
          )}

          <div className="password_field_wrapper">
            {/** Render the password field component using thresholdLength of 7 and minStrength of 3 * */}
            <PasswordField
              fieldId="password"
              label={label_field_1}
              placeholder=""
              setPasswordValue={this.setPasswordValue}
              onStateChanged={this.passwordChanged}
              thresholdLength={7}
              minStrength={3}
              required
            />

            <FormField
              type="password"
              fieldId="password_repeat"
              label={label_field_2}
              placeholder=""
              validator={this.validatePasswordRepeat}
              onStateChanged={this.passwordRepeatChanged}
              required
            />
          </div>

          {this.renderProcessButtons()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
