import { Component } from "react";

export default class TagItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
    this.setActiveState = this.setActiveState.bind(this);
  }

  setActiveState() {
    const { active } = this.state;
    this.setState({
      active: active === "" ? "active" : "",
    });

    if (this.state.active === "") {
      this.props.setToFilter(this.props.title);
    } else {
      this.props.removeFromFilter(this.props.title);
    }
  }

  render() {
    const { active } = this.state;
    // let default_css = this.props.title == this.props.default ? ' active ' : '';
    return (
      <span
        key={`tag-${this.props.title}`}
        className={`item ${active}`}
        onClick={this.setActiveState}
      >
        {this.props.title}
      </span>
    );
  }
}
