import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { cutUrl, cutFilename } from "./Utils";
import { ImageWithFallback } from "./ImageWithFallback";
import { colors } from "../../../common/constants/colorScheme";
import type {
  Material,
  MaterialLinkPreview,
} from "../../../features/material/types";
import { classList } from "../../../common/utils/classList";

/**
 * if no image exists, show an div container as default
 */
function DefaultImagePreview(props: { previewTitle: string }) {
  const firstChar = props.previewTitle.charAt(0);
  return (
    <div className="text-gray-100 bg-green w-11 aspect-square rounded text-center flex items-center justify-center">
      <span>{firstChar}</span>
    </div>
  );
}

/**
 * get an random value from freigeist color scheme
 */
const getPlaceholderColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

/**
 * little helper to check if have url an valid protocol
 */
const ensureLinkProtocol = (url: string): string =>
  url.replace(/^(?!https?:\/\/)(.*)$/, "https://$1");

const ensureValidLink = (url: string): string | false => {
  // treat very short strings as invalid
  if (url.length < 4) {
    return false;
  }
  try {
    return new URL(ensureLinkProtocol(url.trim())).toString();
  } catch (e) {
    return false;
  }
};
// @TODO: erstmal den Fehlertext statisch, mit Tobi klären was wir hier alles anzeigen wollen,
// zb. msg aus dem backend
const linkErrorMsg: string =
  "Der Link ist keine gültige URL und kann deswegen nicht gespeichert werden.";

export function MaterialActionLink(props: {
  linkValue: string;
  linkPreview: MaterialLinkPreview;
  onLinkChange: (val: string) => void;
  onDebouncedLinkChange: (url: string, isValidUrl: boolean) => void;
  onSave: (
    material: Pick<Material, "title" | "link" | "metaData">,
    isValid: boolean,
  ) => void;
  onCancel: () => void;
}) {
  const [debouncedLinkValue] = useDebounce(props.linkValue, 1000);
  const validatedDebouncedLinkValue = ensureValidLink(debouncedLinkValue);
  const showPreviewPlaceholder =
    validatedDebouncedLinkValue && Object.keys(props.linkPreview).length === 0;

  useEffect(() => {
    if (debouncedLinkValue !== "") {
      props.onDebouncedLinkChange(
        validatedDebouncedLinkValue || "",
        Boolean(validatedDebouncedLinkValue),
      );
    }
  }, [debouncedLinkValue, validatedDebouncedLinkValue]);

  const previewTitle = props.linkPreview.title ?? cutUrl(props.linkValue);

  // default image
  const previewImage = props.linkPreview.imageUrl ?? false;
  const previewDomain = props.linkPreview.domain ?? "";
  const previewFavicon = props.linkPreview.favicon ?? "";
  const btnDisabled = props.linkValue === "";

  const showError =
    debouncedLinkValue.length !== 0 && !validatedDebouncedLinkValue;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const value = formData.get("link") as string;
        const validatedUrlValue = ensureValidLink(value);
        const material = {
          link: validatedUrlValue || value,
          metaData:
            props.linkPreview && props.linkPreview.domain
              ? props.linkPreview
              : { color: getPlaceholderColor() },
          title: props.linkPreview.domain ?? props.linkValue,
        };
        props.onSave(material, Boolean(validatedUrlValue));
      }}
    >
      <div className="mb-3">Link speichern</div>
      <div
        className={classList(
          "bg-gray-90 rounded-md py-5 px-4",
          "transition-colors",
          { "bg-red": showError },
        )}
      >
        <div>
          <input
            onChange={(e) => {
              props.onLinkChange(e.target.value);
            }}
            value={props.linkValue}
            className="bg-gray-100 input"
            name="link"
            type="text"
          />
        </div>

        {showError && (
          <div className="mt-2.5 text-gray-100 ">{linkErrorMsg}</div>
        )}

        {showPreviewPlaceholder && (
          <div className="mt-2.5">
            <div className="bg-gray-70 aspect-square w-11 relative">
              <div className="bg-gray-50 aspect-square w-4 absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4" />
            </div>
            <div className="mt-4 bg-gray-70 w-20 h-4" />
          </div>
        )}

        {Object.keys(props.linkPreview).length > 0 && (
          <div className="mt-2.5">
            <div className="rounded aspect-square w-11 relative">
              {previewImage ? (
                <>
                  <ImageWithFallback
                    className="w-full h-full rounded object-cover"
                    src={previewImage}
                    placeholderTxt=""
                  />
                  <img
                    className="aspect-square rounded w-4 absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4"
                    src={previewFavicon}
                  />
                </>
              ) : (
                <DefaultImagePreview previewTitle={previewTitle} />
              )}
            </div>
            <div className="mt-1 font-bold text-sm">
              {cutFilename(previewTitle)}
            </div>
            <div className="mt-1 text-2xs uppercase">{previewDomain}</div>
          </div>
        )}
        <div className="mt-5 flex gap-4">
          <button
            type="submit"
            className="fg-btn fg-btn-primary"
            disabled={btnDisabled}
          >
            speichern
          </button>
          <button
            type="button"
            className="fg-btn fg-btn-secondary"
            disabled={btnDisabled}
            onClick={props.onCancel}
          >
            abbrechen
          </button>
        </div>
      </div>
    </form>
  );
}
