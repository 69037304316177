import { DateTime } from "luxon";

export const ABO_TYPES = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export function getProductName(aboType) {
  return aboType === ABO_TYPES.MONTHLY ? "Monatsabo" : "Jahresabo";
}

export function getOtherProductName(aboType) {
  return aboType === ABO_TYPES.YEARLY ? "Monatsabo" : "Jahresabo";
}

/**
 * @param isoDate
 * @returns {DateTime}
 */
export function getFirstPayDateObj(isoDate) {
  const endDate = DateTime.fromISO(isoDate);
  return endDate.plus({ day: 1 });
}

function getPriceDescription(product) {
  if (product) {
    return product === ABO_TYPES.MONTHLY
      ? "für 99,99 € pro Jahr und sparst damit jährlich 19,89 €."
      : "für 9,99 € im Monat.";
  }
  return "";
}

export function getSubscriptionScheduleText(paymentData, aboType) {
  const aboData = paymentData.payment_provider_customer_data;

  const newPeriodStartDate = aboData.current_period_end
    ? DateTime.fromSeconds(aboData.current_period_end)
        .plus({ days: 1 })
        .toFormat("dd. MMMM yyyy")
    : "";

  const priceDesc = getPriceDescription(aboType);

  return (
    <span>
      Am <strong>{newPeriodStartDate}</strong> wechselst du zum{" "}
      <strong>{getOtherProductName(aboType)}</strong>, {priceDesc}
    </span>
  );
}

export function getBookedProductPrice(paymentData) {
  return paymentData.payment_provider_customer_data &&
    paymentData.payment_provider_customer_data.product_price
    ? paymentData.payment_provider_customer_data.product_price
    : "";
}

export function getBookedProductName(paymentData) {
  return paymentData.payment_provider_customer_data &&
    paymentData.payment_provider_customer_data.product
    ? paymentData.payment_provider_customer_data.product
    : "";
}
