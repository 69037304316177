import type {
  DataStrategyMatch,
  DataStrategyFunction,
  DataStrategyResult,
} from "react-router-dom";

type OnUnloadArg = () => void;
export type HandlerCtx = { onUnload: (fn: OnUnloadArg) => void };

export function createDataStrategy() {
  const unloaderEventTarget = new EventTarget();
  let previousMatches: string[] = [];

  // fire unload event for each unloading route
  const dispatchUnloadEvents = (matches: DataStrategyMatch[]) => {
    previousMatches.forEach((id) => {
      const match = matches.find((m) => m.route.id === id);
      // Dispatch unload event IF
      // - if the entry is not matched anymore
      // - if the entry is still matched but shouldLoad is true, because the new loader will add this again
      if (!match || match.shouldLoad) {
        unloaderEventTarget.dispatchEvent(new CustomEvent(`unload-${id}`));
      }
    });
    // store matches for next run
    previousMatches = matches.map((match) => match.route.id);
  };

  // add second param to loaders which is a callback which get's called when the route is not a match anymore
  const dataStrategy: DataStrategyFunction = async ({ matches }) => {
    dispatchUnloadEvents(matches);

    // Run loaders in parallel with the `context` value
    const matchesToLoad = matches.filter((m) => m.shouldLoad);
    const results: DataStrategyResult[] = await Promise.all(
      matchesToLoad.map((match: DataStrategyMatch) =>
        match.resolve(async (handler) => {
          // add unload handler and pass it to loader as argument
          const onUnload = (fn: () => void) => {
            unloaderEventTarget.addEventListener(
              `unload-${match.route.id}`,
              fn,
              { once: true },
            );
          };
          // Whatever you pass to `handler` will be passed as the 2nd parameter
          // to your loader/action
          return handler({ onUnload });
        }),
      ),
    );
    const dataStrategyResults: Array<[string, DataStrategyResult]> =
      results.map((result, i) => [matchesToLoad[i]?.route.id ?? "", result]);
    return Object.fromEntries(dataStrategyResults);
  };

  return { dataStrategy, dispatchUnloadEvents };
}
