import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as ACTIONS from "../../features/authentication/actions";
import * as SACTIONS from "../../features/subjects/actions";
import * as CACTIONS from "../../features/classes/actions";
import * as TTACTIONS from "../../features/timetable/actions";
import * as PAYMENT_ACTIONS from "../../features/payment/actions";
import * as VIEW_ACTION from "../../features/dataview/actions";
import Auth from "../../common/services/Auth";
import LoginForm from "../../common/components/auth/LoginForm";
import * as API from "../../common/constants/ApiRoutes";
import { calcSchoolyearSettings } from "../../common/config/schoolyear";
import { useDocumentTitle } from "../../common/utils/hooks";

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

const mapDispatchToProps = {
  doLoginSuccess: ACTIONS.doLoginSuccess,
  fetchSubjectsList: SACTIONS.fetchSubjectsList,
  fetchClassesList: CACTIONS.fetchClassesList,
  fetchTimetable: TTACTIONS.fetchTimetable,
  fetchPayment: PAYMENT_ACTIONS.getPaymentSettings,
  setViewData: VIEW_ACTION.switchDataView,
  triggerLogout: ACTIONS.logout,
};

function getRedirectTarget(search) {
  // is going directly to a page
  const from = new URLSearchParams(search).get("from");
  if (from) {
    return from;
  }

  return "/zeitleiste";
}

/**
 * Process the form.
 * @param {object} event - the JavaScript event object
 */
async function processForm(event, navigate, props, setState) {
  event.preventDefault();

  const res = await fetch(API.API_ROUTE_AUTH_LOGIN, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(new FormData(event.target)),
  });

  if (res.ok) {
    const { user, token } = await res.json();
    const appData = user.holder;

    // save the token
    Auth.authenticateUser(token);
    Auth.customerData(user);

    const auth = { ...user, token };

    if (appData.schooldata) {
      // new data settings
      props.doLoginSuccess(auth);
      props.setViewData(appData);
      // redirect to appropriate location
      const target = getRedirectTarget(window.location.search);

      navigate(target);
      return;
    }
    // OLD STYLE - not yet finished registered
    // fake the schoolyear for next setup registration step
    const calc_year = calcSchoolyearSettings();
    props.fetchClassesList(Auth.getUserId(), calc_year.year);
    props.fetchTimetable(Auth.getUserId(), calc_year.year);
    props.fetchSubjectsList(Auth.getUserId(), calc_year.year);
    props.fetchPayment(Auth.getUserId());
    props.doLoginSuccess(auth);

    navigate("/registrieren");
  } else {
    const data = await res.json();
    // change the component state
    const wrongCredentials =
      data.message === "Bitte überprüfen Sie Ihre Zugangsdaten!" ||
      data.message === "Bitte überprüfen Sie Ihre Eingaben"
        ? "errorTypeWrongCredentials"
        : "";

    setState({
      errorTypeWrongCredentials: wrongCredentials,
      errorTime: Date.now(),
    });
  }
}

function LoginPage(props) {
  const [state, setState] = useState({
    errorTypeWrongCredentials: "",
    errorTypeAutoLogoutOffline: "",
    successMessage: "",
    errorTime: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const storedMessage = localStorage.getItem("successMessage");

    if (storedMessage) {
      setState({ ...state, successMessage: storedMessage });
      localStorage.removeItem("successMessage");
    }
  }, []);

  /**
   * on mount - clear authenticate user
   */
  useEffect(() => {
    if (props.authentication) {
      props.triggerLogout();
    }
    Auth.deauthenticateUser();
  }, []);

  useDocumentTitle("Freigeist | Login");

  return (
    <div className="wrapper-login-form">
      <LoginForm
        onSubmit={(event) =>
          processForm(event, navigate, props, (val) =>
            setState((oldState) => ({ ...oldState, ...val })),
          )
        }
        errorTypeWrongCredentials={state.errorTypeWrongCredentials}
        errorTypeAutoLogoutOffline={state.errorTypeAutoLogoutOffline}
        errorTime={state.errorTime}
        successMessage={state.successMessage}
      />
    </div>
  );
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
