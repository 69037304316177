/**
 * Created by makkuz on 06.02.18.
 */
/**
 * Created by makkuz on 06.02.18.
 */
import { Component } from "react";
import AddTimetableForm from "./AddTimetableFormComponent";

class addTimetable extends Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    this.props.finalizeSchoolyear();
  }

  prevSection() {
    this.props.jumpToStep(2);
  }

  render() {
    return (
      <div id="timetable" className="step step3">
        <div className="step-text">
          <div className="title">Stundenplan</div>
          Hier kannst du deinen Stundenplan hinterlegen. Falls du noch keinen
          Stundenplan für das Schuljahr 2024/2025 hast, kannst du das später
          nachholen.
        </div>

        <AddTimetableForm
          updateTimetable={this.props.updateTimetable}
          updateClasses={this.props.updateClasses}
          updateSettings={this.props.updateSettings}
          schoolyear={this.props.schoolyear}
          subjects={this.props.subjects}
          timeframe={this.props.timeframe}
          classes={this.props.classes}
          settings={this.props.settings}
          timetable={this.props.timetable}
          prevSection={this.prevSection}
          nextSection={this.nextSection}
        />
      </div>
    );
  }
}
export default addTimetable;
