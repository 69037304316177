import { Component } from "react";
import PropTypes from "prop-types";
import { classList } from "../../../utils/classList";

class FormField extends Component {
  // initialize state
  constructor() {
    super();
    this.state = { value: "", dirty: false, errors: [] };
  }

  hasChanged(e) {
    e.preventDefault();

    // destructure props - assign default dummy functions to validator and onStateChanged props
    const {
      label,
      required = false,
      validator = (f) => f,
      onStateChanged = (f) => f,
    } = this.props;

    const { value } = e.target;
    const isEmpty = value.length === 0;
    const requiredMissing = this.state.dirty && required && isEmpty;

    let errors = [];

    if (requiredMissing) {
      // if required and is empty, add required error to state
      errors = [...errors, `${label} is required`];
    } else if (typeof validator === "function") {
      try {
        validator(value);
      } catch (err) {
        // if validator throws error, add validation error to state
        errors = [...errors, err.message];
      }
    }

    // update state and call the onStateChanged callback fn after the update
    // dirty is only changed to true and remains true on and after the first state update
    this.setState(
      ({ dirty = false }) => ({ value, errors, dirty: !dirty || dirty }),
      () => onStateChanged(this.state),
    );
  }

  render() {
    const { value, dirty, errors } = this.state;
    const { type, label, fieldId, placeholder, children } = this.props;

    const hasErrors = errors.length > 0;

    const controlClass = classList({
      "form-control": true,
      "is-invalid": dirty && hasErrors,
      "is-valid": dirty && !hasErrors,
    });

    return (
      <div className="form-row">
        <div className="label-wrapper">
          <label htmlFor={fieldId} className="control-label">
            {label}
          </label>
          {/** Render the first error if there are any errors * */}
          {/* hasErrors && <div className="error form-hint font-weight-bold text-right m-0 mb-2">{ errors[0] }</div> */}
        </div>
        <input
          type={type}
          className={controlClass}
          id={fieldId}
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.hasChanged(e)}
        />

        <div className="after-field-wrapper">
          {/* Render the children nodes passed to component */}
          {children}
        </div>
      </div>
    );
  }
}

FormField.propTypes = {
  type: PropTypes.oneOf(["text", "password"]).isRequired,
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  validator: PropTypes.func,
  onStateChanged: PropTypes.func,
};

export default FormField;
