import { Component } from "react";
import { connect } from "react-redux";
import Auth from "../../../services/Auth";
import { withRouter } from "../../../utils/routing";
import { deepSet } from "../../../utils/assortments";
import SchoolTypeStateBox from "../../generic/schoolTypeStateBox/SchoolTypeStateBox";
import * as TTACTIONS from "../../../../features/timetable/actions";
import * as CLASS_ACTIONS from "../../../../features/classes/actions";
import { selectSettings } from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { api, selectGetSchoolData } from "../../../../services/api";

const mapStateToProps = (state) => ({
  schoolyear: selectCurrentSchoolyear(state),
  school: selectGetSchoolData(state, selectCurrentSchoolyear(state)),
  registration: state.registration,
  subjects: state.subjects,
  classes: state.classes,
  timetable: state.timetable,
  settings: selectSettings(state), // setting active tabs from database
  authentication: state.authentication,
});

const mapDispatchToProps = {
  upsertSchoolData: api.endpoints.upsertSchool.initiate,
  updateClassList: CLASS_ACTIONS.updateClassListItem,
  upsertTimetable: TTACTIONS.upsertTimetable,
  addClassList: CLASS_ACTIONS.addClassList,
  updateSettings: api.endpoints.updateSettings.initiate,
};

class AddSchoolForm extends Component {
  constructor(props) {
    super(props);
    this.prevSection = this.prevSection.bind(this);
    this.finishRegistration = this.finishRegistration.bind(this);
    this.updateSchooldata = this.updateSchooldata.bind(this);
    this.isFormFilled = this.isFormFilled.bind(this);
    this.setupExampleData = this.setupExampleData.bind(this);
    this.setupExampleClass = this.setupExampleClass.bind(this);
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    //
    // if (!this.props.school.region_label && this.props.registration.bundesland) {
    //   const regions = region_countries();
    //   let default_region = false;
    //   regions.forEach((region, index) => {
    //     if (region.label === this.props.registration.bundesland) {
    //       default_region = regions[index];
    //     }
    //   });
    //   if (default_region) {
    //     this.props.updateRegion(default_region);
    //   }
    // }
    //
    // // falls noch keine region beim laden existiert
    // if (!this.props.school.region_label) {
    //   const regions = region_countries();
    //   this.props.updateRegion(regions[0]);
    // }
    // if (!this.props.school.schooltype_label) {
    //   const schooltypes = school_types();
    //   this.props.updateSchooltype(schooltypes[0]);
    // }
  }

  async setupExampleClass() {
    const { props } = this;
    // check if user have no classes - create dummy class first
    if (props.classes.length === 0) {
      const subjects = props.subjects.raw.map((subj, index) =>
        index === 0
          ? {
              ...subj,
              schooldays: [
                {
                  day: 0,
                  day_name: "Montag",
                  hour: 0,
                },
                {
                  day: 0,
                  day_name: "Montag",
                  hour: 1,
                },
                {
                  day: 1,
                  day_name: "Dienstag",
                  hour: 0,
                },
                {
                  day: 1,
                  day_name: "Dienstag",
                  hour: 1,
                },
                {
                  day: 2,
                  day_name: "Mittwoch",
                  hour: 0,
                },
                {
                  day: 2,
                  day_name: "Mittwoch",
                  hour: 1,
                },
              ],
            }
          : subj,
      );

      const class_obj = {
        user_id: Auth.getUserId(),
        title: "Beispielklasse",
        subjects,
        schoolyear: props.schoolyear,
        schooldays: [],
      };

      const { data: classObject } = await props.addClassList(class_obj);
      return [classObject];
    }
    return props.classes;
  }

  setupExampleData(classes) {
    // check if user has a timetable enty - if not create it
    if (this.props.timetable && this.props.timetable.entries.length === 0) {
      // create entries
      // list schooldays for classes
      const schooldays = [
        {
          day: 0,
          day_name: "Montag",
          hour: 0,
        },
        {
          day: 0,
          day_name: "Montag",
          hour: 1,
        },
        {
          day: 1,
          day_name: "Dienstag",
          hour: 0,
        },
        {
          day: 1,
          day_name: "Dienstag",
          hour: 1,
        },
        {
          day: 2,
          day_name: "Mittwoch",
          hour: 0,
        },
        {
          day: 2,
          day_name: "Mittwoch",
          hour: 1,
        },
      ];

      const first_class = deepSet(
        ["subjects", 0, "schooldays"],
        schooldays,
        classes[0],
      );
      const tt_class = { ...first_class, subject: first_class.subjects[0] };

      const timetable = {
        ...this.props.timetable,
        entries: [
          {
            day: 0,
            day_name: "Montag",
            hour: 0,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
          {
            day: 0,
            day_name: "Montag",
            hour: 1,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
          {
            day: 1,
            day_name: "Dienstag",
            hour: 0,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
          {
            day: 1,
            day_name: "Dienstag",
            hour: 1,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
          {
            day: 2,
            day_name: "Mittwoch",
            hour: 0,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
          {
            day: 2,
            day_name: "Mittwoch",
            hour: 1,
            classId: tt_class._id,
            subjectId: tt_class.subject._id,
          },
        ],
        schoolyear: this.props.schoolyear,
      };

      this.props.updateClassList(first_class, this.props.schoolyear);
      this.props.upsertTimetable(timetable, this.props.schoolyear, false);
    }
  }

  /**
   * @param update
   */
  async finishRegistration(schoolData) {
    const { props } = this;

    const classes = await this.setupExampleClass();

    // first add Settings taktung to minute as default
    // bestehende Settings übernehmen.
    const settings = {
      ...props.settings,
      schoolyear: props.schoolyear,
      settings: { ...props.settings.settings, taktung: "20" },
    };
    props.updateSettings(settings);

    const auth = props.authentication;
    const data = {
      ...props.school,
      ...schoolData,
      user_id: auth.userId,
      schoolyear: props.schoolyear,
      register_state: 1,
      finish_register: 1,
      username: auth.firstname,
      register_email: props.registration.email,
      is_active: true,
      email: auth.email,
      name: auth.name,
    };

    props.upsertSchoolData(data);

    // now wait to check if class (user or example class) exists
    // setup new data and finish registration
    this.setupExampleData(classes);

    // finish with registration ... redirect to sequence planung
    // TODO would be good to check if update worked somehow
    // move logic outside of component
    props.navigate("/zeitleiste");
  }

  /**
   * @param update
   */
  updateSchooldata(schoolData) {
    const { school } = this.props;
    const newSchool = {
      ...school,
      ...schoolData,
      datapolicy: true,
      ref: false,
    };
    this.props.upsertSchoolData(newSchool);
  }

  prevSection() {
    this.props.prevSection();
  }

  /**
   * checks if needed fields exits
   */
  isFormFilled() {
    const fields = ["datapolicy"];
    return fields.every((elem) => this.props.school[elem] !== "");
  }

  render() {
    const { props } = this;
    const enabled = this.isFormFilled();

    return (
      <form
        id="school"
        className="block tw-pf"
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(
            event.target,
            event.nativeEvent.submitter,
          );
          const intent = formData.get("intent");
          const formObject = Object.fromEntries(formData.entries());
          const schoolData = {
            school: formObject.school,
            street: formObject.street,
            nr: formObject.street_nr,
            zip: formObject.zip,
            location: formObject.location,
            ref: Object.hasOwn(formObject, "referendariat"),
            datapolicy: Object.hasOwn(formObject, "datapolicy"),
          };

          if (intent === "registration") {
            this.finishRegistration({
              ...schoolData,
              region: formObject.region,
              schooltype: formObject.schooltype,
            });
            return;
          }

          if (intent === "settings") {
            this.updateSchooldata(schoolData);
          }
        }}
      >
        <div className="grid grid-cols-4 bg-gray-100 p-2.5 gap-x-2.5 gap-y-5 shadow">
          <div className="col-span-4 flex flex-col items-stretch">
            <div className="">
              <label htmlFor="school" className="school-label">
                Name der Schule
              </label>
              <input
                id="school-name"
                className="school-input"
                type="text"
                name="school"
                defaultValue={props.school.school}
                placeholder="z.B. Mustermann Schule"
              />
            </div>
          </div>

          <div className="col-span-3">
            <label htmlFor="street" className="school-label">
              Straße
            </label>
            <input
              id="street"
              className="school-input"
              type="text"
              name="street"
              defaultValue={props.school.street}
              placeholder="Musterstraße"
            />
          </div>
          <div className="col-span-1">
            <label htmlFor="street-nr" className="school-label">
              Nr.
            </label>
            <input
              id="street_nr"
              className="school-input"
              type="text"
              name="street_nr"
              defaultValue={props.school.nr}
              placeholder="123"
            />
          </div>

          <div className="col-zip">
            <label htmlFor="zip" className="school-label">
              PLZ
            </label>
            <input
              id="zip"
              className="school-input"
              type="text"
              name="zip"
              defaultValue={props.school.zip}
              placeholder="12345"
            />
          </div>
          <div className="col-span-3">
            <label className="school-label" htmlFor="location">
              Ort
            </label>
            <input
              id="location"
              className="school-input"
              type="text"
              name="location"
              placeholder="Musterstadt"
              defaultValue={props.school.location}
            />
          </div>
        </div>

        <div id="student">
          <div className="heading step-text">
            <div className="head second">Referendariat und Studium</div>
            Im Referendariat und Studium ist Freigeist für dich kostenfrei.
            Schreibe uns innerhalb des Testzeitraums eine E-Mail mit einem
            Nachweis dazu, dass du aktuell im Studium oder im Referendariat bist
            und wir stellen das für dich ein.
          </div>
          <div className="form-wrapper">
            <div className="row ref-row">
              <div className="field-group dropdowns">
                <label htmlFor="referendariat">
                  <input
                    id="referendariat"
                    className="checkbox"
                    name="referendariat"
                    defaultChecked={props.school.ref}
                    type="checkbox"
                    value=""
                  />
                  Ich bin aktuell im Referendariat
                </label>
              </div>
            </div>
          </div>
        </div>

        {props.context === "registration" && (
          <SchoolTypeStateBox
            schoolData={props.school}
            context={props.context}
          />
        )}

        <div className="policy-data head second">
          Hinweis zu personenbezognen Daten
        </div>
        <div className="policy-data form-wrapper">
          <div className="text">
            Freigeist ist nicht für das speichern personenbezogener Daten
            ausgelegt. Außer deiner persönlichen Anmeldedaten, dürfen keine
            personenbezogenen Daten (z.B. Schülernamen, Schülerbeobachtungen,
            Kontaktdaten, persönliche Emailadressen, Telefonnummern,…) mit
            Freigeist gespeichert werden.
            <br />
            Wenn du dennoch personenbezogene Daten mit Freigeist speichern
            solltest, dann müssten wir deinen Account sperren.
          </div>

          <div className="row">
            <div className="field-group refs">
              <label htmlFor="datapolicy">
                <input
                  id="datapolicy"
                  className="checkbox"
                  name="datapolicy"
                  defaultChecked={props.school.datapolicy}
                  type="checkbox"
                  value=""
                />
                Ich habe den Hinweis gelesen und werde{" "}
                <strong>keine personenbezogenen Daten</strong> mit Freigeist
                speichern
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {props.context === "registration" && (
            <div className="btn-wrapper steps-btns">
              <div className="back">
                <a href="#" onClick={() => this.prevSection()}>
                  Zurück zu Stundenplan
                </a>
              </div>
              <div className="next">
                <button
                  type="submit"
                  name="intent"
                  value="registration"
                  disabled={!enabled}
                  className="btn btn-step bg-green"
                >
                  Registrierung abschließen
                </button>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          )}
          {props.context === "settings" && (
            <div className="btn-wrapper dashboard-btns">
              <div className="next">
                <button
                  type="submit"
                  name="intent"
                  value="settings"
                  className="btn btn-step bg-green"
                >
                  Speichern
                </button>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AddSchoolForm));
