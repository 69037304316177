import { useState } from "react";

export function ImageWithFallback(props: {
  placeholderTxt: string;
  src: string;
  className: string;
}) {
  const [imgHasError, setImgHasError] = useState<boolean>(false);
  const onError = () => {
    setImgHasError(true);
  };
  if (imgHasError) {
    // TODO fix this
    return (
      <div className="bg-gray-20 mx-auto h-[38px] aspect-square rounded text-gray-100 uppercase leading-[38px] text-center">
        {props.placeholderTxt}
      </div>
    );
  }
  return <img src={props.src} className={props.className} onError={onError} />;
}
