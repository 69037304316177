/**
 * Created by makkuz on 06.02.18.
 */
/**
 * Created by makkuz on 06.02.18.
 */
import { Component } from "react";
import AddSubjectForm from "./AddSubjectFormComponent";

class addSubjects extends Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.updateSubjects = this.updateSubjects.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection(subjects) {
    this.props.updateSubjects(subjects);
    this.props.jumpToStep(2);
  }

  prevSection() {
    this.props.jumpToStep(0);
  }

  updateSubjects(subjects) {
    this.props.updateSubjects(subjects);
  }

  render() {
    return (
      <div id="subjects" className="step step2">
        <div className="step-text">
          <div className="title">Fächer</div>
          Stimmen die Fächer für das Schuljahr {this.props.timeframe}, oder
          musst du weitere Fächer ergänzen?
          <br />
          Wir empfehlen keine Fächer zu löschen, die du zukünftig unterrichten
          könntest, selbst wenn du sie 2024/2025 nicht brauchst.
        </div>

        <AddSubjectForm
          nextSection={this.nextSection}
          prevSection={this.prevSection}
          storage="local"
          subjects={this.props.subjects}
          classes={this.props.classes}
          timetable={this.props.timetable}
          schoolyear={this.props.schoolyear}
          updateSubjects={this.updateSubjects}
          updateTimetable={this.props.updateTimetable}
          updateClasses={this.props.updateClasses}
        />
      </div>
    );
  }
}
export default addSubjects;
