import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import * as ACTIONS from "../../../../features/authentication/actions";

export function UserNameForm() {
  const firstname = useAppSelector((store) => store.authentication.firstname);
  const lastname = useAppSelector((store) => store.authentication.lastname);
  const fullName = `${firstname} ${lastname}`;
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    edit_mode: false,
    firstname,
    lastname,
  });

  return (
    <div className="action_form_wrapper">
      {state.edit_mode ? (
        <div className="edit">
          <div className="field_input">
            <label>
              Vorname
              <input
                placeholder={firstname}
                className="inputfield"
                value={state.firstname}
                onChange={(e) => {
                  setState({
                    ...state,
                    firstname: e.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="mt-2 field_input">
            <label>
              Nachname
              <input
                placeholder={lastname}
                className="inputfield"
                value={state.lastname}
                onChange={(e) => {
                  setState({
                    ...state,
                    lastname: e.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="btn-wrapper dashboard-btns">
            <button
              onClick={() => {
                dispatch(
                  ACTIONS.changeUsername(state.firstname, state.lastname),
                );
                setState({
                  ...state,
                  edit_mode: false,
                });
              }}
              className="btn btn-step"
            >
              Änderungen Speichern
            </button>
          </div>
        </div>
      ) : (
        <div className="readonly">
          <span className="label">Name</span>
          <span className="text">{fullName}</span>

          <span className="actions">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setState({
                  ...state,
                  edit_mode: true,
                });
              }}
            >
              <img src="/assets/images/edit-icon.png" className="form-icon" />
            </a>
          </span>
        </div>
      )}
    </div>
  );
}
