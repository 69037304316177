import { Component as ReactComponent } from "react";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import { Rnd } from "react-rnd";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { connect } from "react-redux";
import ScreenNote from "../../../common/components/ScreenNotes";
import CreateTemplate from "../templates/CreateTemplate";
import UseTemplateList from "../templates/UseTemplateComponent";
import { withRouter } from "../../../common/utils/routing";
import { isBrightColor } from "../../../common/utils/colors";
import { classList } from "../../../common/utils/classList";
import { deepSet } from "../../../common/utils/assortments";
import { fixReactRndTouchClicks } from "../../../common/utils/rnd-click-fix";
import * as ACTIONS from "../../../features/timeline/timeline-slice";
import * as CLASSES_ACTIONS from "../../../features/classes/actions";
import {
  stampShowCreateSchoolyear,
  selectCurrentSchoolyearData,
} from "../../../features/current-schoolyear/current-schoolyear-slice";
import {
  selectSchoolWithHolidays,
  selectSequenceItems,
} from "../../combined-selectors/index";

import {
  selectLessonDuration,
  selectSettings,
} from "../../../features/settings/settings-slice";
import { api } from "../../../services/api";
import {
  getClusterHeight,
  getOverlappingPositions,
  getSequenceOverlappings,
} from "./computations";
import {
  getScoolHours,
  findDatesInMap,
  guid,
  uniqueId,
} from "../../../common/components/sequence_calendar/Utils";
// load components
import CalMonthCol from "./CalMonthCol";
import CalendarSequenceDialog from "./CalendarSequenceDialog";
import ABWeeksCalendar from "./ABWeeksCalendar";
import * as CONST from "../../../common/constants/Components";

function shouldSetupNextYear(currentSchoolyear) {
  return currentSchoolyear.year < 2024 && !currentSchoolyear.is_archive;
}

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  active_view: state.active_view,
  sequence_items: selectSequenceItems(state),
  subjects: state.subjects,
  classes: state.classes,
  school: selectSchoolWithHolidays(state),
  settings: selectSettings(state),
  lessonDuration: selectLessonDuration(state),
});

const mapDispatchToProps = {
  fetchClassrooms: CLASSES_ACTIONS.fetchClassesList,
  deleteSequences: api.endpoints.deleteSequenceDetail.initiate,
  getSequenceItems: ACTIONS.getSequenceItems,
  stampClusterItem: ACTIONS.stampClusterItem,
  updateSequnceItems: ACTIONS.updateSequenceItems,
  updateSequnceItemsFromTemplate: ACTIONS.updateSequenceItemsFromTemplate,
  stampShowCreateSchoolyear,
  updateSettings: api.endpoints.updateSettings.initiate,
};

const makePathToSequence = (sequenceId) => `/sequenzen/${sequenceId}`;

function blurOnEnter(event) {
  if (event.key === "Enter") {
    event.target.blur();
  }
}

function getABWeeksSettings(settings, year) {
  const currentAbSettings = settings.settings.ab_weeks?.[year];
  if (currentAbSettings && Number(currentAbSettings.active) === 1) {
    return {
      ab_weeks: true,
      ab_settings: currentAbSettings,
    };
  }
  return { ab_weeks: false, ab_settings: false };
}

// DRAG and Move params:
const enables = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

/**
 * super class sequence calendar
 */
class SequenceCalendar extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeCreateElement: null,
      dialogFocus: 0,
      renaming: null,
      resizing: null,
      dragFocusIndex: null,
      hiddenDelete: 0,
      show_create_template_modal: false,
      show_create_template_success_note: false,
      show_use_template_modal: false,
      pointerDownElement: null,
    };

    // functions declarations
    this.addItem = this.addItem.bind(this);
    this.handleOnDragStop = this.handleOnDragStop.bind(this);
    this.handleOnResize = this.handleOnResize.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.saveSequenceTitle = this.saveSequenceTitle.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);

    this.renderSetupNoteNewSchoolyear =
      this.renderSetupNoteNewSchoolyear.bind(this);
    this.renderOverlayCreateTemplate =
      this.renderOverlayCreateTemplate.bind(this);
    this.closeOverlayCreateTemplate =
      this.closeOverlayCreateTemplate.bind(this);
    this.openOverlayCreateTemplate = this.openOverlayCreateTemplate.bind(this);

    this.renderOverlayUseTemplate = this.renderOverlayUseTemplate.bind(this);
    this.openOverlayUseTemplate = this.openOverlayUseTemplate.bind(this);
    this.closeOverlayUseTemplate = this.closeOverlayUseTemplate.bind(this);

    this.setSequenzDialogFocus = this.setSequenzDialogFocus.bind(this);

    this.addTemplateItem = this.addTemplateItem.bind(this);
    this.getNoteEmptySubjects = this.getNoteEmptySubjects.bind(this);

    this.listTextInputs = [];
  }

  componentDidMount() {
    document.title = "Freigeist | Zeitleiste";
    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );
    this.props.getSequenceItems(
      this.props.current_schoolyear.year,
      this.props.school,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.current_schoolyear.year !== prevProps.current_schoolyear.year
    ) {
      const abWeekSettings = getABWeeksSettings(
        this.props.settings,
        this.props.current_schoolyear.year,
      );

      this.props.getSequenceItems(
        this.props.current_schoolyear.year,
        this.props.school,
        abWeekSettings.ab_weeks,
        abWeekSettings.ab_settings,
      );
    }

    if (
      Number(this.state.dialogFocus) !== 0 &&
      this.listTextInputs[this.state.dialogFocus]
    ) {
      this.listTextInputs[this.state.dialogFocus].focus();
    }
  }

  handleDrag(ix) {
    // set dragFocusIndex
    if (this.state.dragFocusIndex !== ix) {
      this.setState({
        dragFocusIndex: ix,
      });
    }
  }

  /**
   * handler afer resizing
   */
  handleOnDragStop(e, node) {
    this.setState({
      dragFocusIndex: null,
    });
    const sid = Number(node.node.dataset.id);
    const { cluster } = node.node.dataset;

    const obj = this.props.sequence_items[cluster].data.find(
      (element) => Number(element.id) === sid,
    );

    let pos = node.x;
    const mod = pos % CONST.CALENDAR_WEEK_UNIT;
    // if ( mod > 0 ) item_width = item_width - mod + CONST.CALENDAR_WEEK_UNIT;
    if (mod > 0) {
      pos -= mod;
    }
    pos /= CONST.CALENDAR_WEEK_UNIT;
    const seq_items = this.props.sequence_items;
    const { scooldays } = seq_items[cluster];

    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );
    const result = findDatesInMap(
      this.props.school.calendarMap,
      pos,
      obj.weeks,
      this.props.school.holidays,
      scooldays,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      this.props.lessonDuration,
    );
    const pos_end = Number(pos) + Number(obj.weeks);

    const hours = getScoolHours(scooldays, result, this.props.lessonDuration);

    const newData = seq_items[cluster].data.map((o) => {
      if (Number(o.id) === sid) {
        return {
          ...o,
          label_weeks: result.label_weeks,
          date: result.date,
          daterange: result.date_range,
          pos,
          pos_end,
          calc_end_date: result.calc_end_date,
          calc_date: result.calc_date,
          hours,
          label_hours: result.label_scoolhours,
        };
      }
      return o;
    });

    const newSeqItems = deepSet([Number(cluster), "data"], newData, seq_items);
    this.updateColPos(newSeqItems, pos, pos_end, cluster);
  }

  handleOnResize(e, dir, refToElement, delta, position) {
    const sid = Number(refToElement.dataset.id);
    const { cluster } = refToElement.dataset;

    let item_width = refToElement.style.width;
    item_width = parseInt(item_width, 10);

    const mod = item_width % CONST.CALENDAR_WEEK_UNIT;
    if (mod > 0) {
      item_width = item_width - mod + CONST.CALENDAR_WEEK_UNIT;
    }

    const weeks = Math.round(item_width / CONST.CALENDAR_WEEK_UNIT);
    const current_pos = Math.round(position.x) / CONST.CALENDAR_WEEK_UNIT;

    const seq_items = this.props.sequence_items;
    const { scooldays } = seq_items[cluster];

    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );
    const result = findDatesInMap(
      this.props.school.calendarMap,
      current_pos,
      weeks,
      this.props.school.holidays,
      scooldays,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      this.props.lessonDuration,
    );
    const pos_end = Number(current_pos) + Number(weeks);

    const hours = getScoolHours(scooldays, result, this.props.lessonDuration);

    const newData = seq_items[cluster].data.map((o) => {
      if (Number(o.id) === sid) {
        return {
          ...o,
          weeks,
          label_weeks: result.label_weeks,
          date: result.date,
          daterange: result.date_range,
          pos_end,
          calc_end_date: result.calc_end_date,
          calc_date: result.calc_date,
          hours,
          label_hours: result.label_scoolhours,
        };
      }
      return o;
    });
    const newSeqItems = deepSet([Number(cluster), "data"], newData, seq_items);

    let elem_width = weeks * CONST.CALENDAR_WEEK_UNIT;
    if (elem_width === 0) elem_width = CONST.CALENDAR_WEEK_UNIT;

    this.props.stampClusterItem(cluster);
    this.updateColPos(newSeqItems, current_pos, pos_end, cluster);
    this.setState({ resizing: null });
  }

  handleResize(e, id) {
    this.setState({ resizing: id });
    e.preventDefault();
  }

  setSequenzDialogFocus(id) {
    this.setState({
      dialogFocus: id,
    });
  }

  getNoteEmptySubjects() {
    return (
      <div className="layer-note-empty-subject">
        <div className="content-layer">
          Bisher ist kein Fach dieser Klasse im Stundenplan eingetragen. Sobald
          die Fächer im Stundenplan vorkommen, werden Sie hier angezeigt.
          <div className="btn-wrapper">
            <div className="link-wrapper">
              <Link className="setup-link" to="/einstellungen#stundenplan">
                <span className="linktext">Stundenplan bearbeiten</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  addTemplateItem(sequenceTemplate) {
    const templateId = sequenceTemplate._id;
    const { template_data } = this.state;
    const { pos, cluster } = template_data;
    const seq_items = this.props.sequence_items;
    const { scooldays } = seq_items[cluster];
    const { weeks } = sequenceTemplate.sequence_cal;

    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );

    const result = findDatesInMap(
      this.props.school.calendarMap,
      pos,
      weeks,
      this.props.school.holidays,
      scooldays,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      this.props.lessonDuration,
    );
    if (result) {
      const hours = getScoolHours(scooldays, result, this.props.lessonDuration);

      const id = uniqueId();
      const pos_end = Number(pos) + weeks;

      // set / override valuees
      const template = {
        ...sequenceTemplate.sequence_cal,
        id,
        pos,
        pos_end,
        date: result.date,
        daterange: result.date_range,
        calc_end_date: result.calc_end_date,
        calc_date: result.calc_date,
        hours,
        label_hours: result.label_scoolhours,
        autofocus: true,
        label_weeks: result.label_weeks,
      };

      // using the Immer library as a shortcut because this logic was using mutation
      const newSeqItems = immutableUpdate(seq_items, (draft) => {
        draft[cluster].data.push(template);
      });

      const sequences = immutableUpdate(
        sequenceTemplate.sequences_list,
        (draft) => {
          if (draft && draft.sequence_item_id) {
            const delKey = "_id";
            delete draft[delKey];
            delete draft.__v;
            draft.schoolyear = this.props.current_schoolyear.year;
            draft.sequence_item_id = id; // new seq id
          }
        },
      );

      this.props.updateSequnceItemsFromTemplate(
        newSeqItems,
        sequences,
        this.props.current_schoolyear.year,
        templateId,
      );
    }
    document.getElementById("content-container").classList.remove("isblured");
    this.setState({
      show_use_template_modal: false,
      template_data: {},
    });
  }

  /**
   * add an item to given position
   * @param pos
   */
  editItem(cluster, id) {
    const seq_items = this.props.sequence_items;
    seq_items[cluster].data.forEach((o) => {
      if (Number(o.id) === Number(id)) {
        o.autofocus = true;
      }
    });
    this.props.updateSequnceItems(
      seq_items,
      this.props.current_schoolyear.year,
    );
    this.listTextInputs[id].focus();
  }

  /**
   *
   * @param cluster
   * @param id
   */
  deleteItem(cluster, id, cid) {
    const { props } = this;
    this.setState({
      hiddenDelete: id,
    });
    const seqItem = props.sequence_items?.[cluster];
    const data_list = seqItem?.data;
    const newDataList = data_list.filter((item, index) => index !== id);

    // sequences löschen:
    //  "sequence_item_id" : 932351754945, "user_id" : 5,

    const newSeqItems = props.sequence_items.with(cluster, {
      ...seqItem,
      newCompKey: uniqueId(),
      data: newDataList,
    });
    props.updateSequnceItems(newSeqItems, props.current_schoolyear.year);
    props.deleteSequences({ id: cid, year: props.current_schoolyear.year });
  }

  saveSequenceTitle(cluster_nr, ix, text) {
    const itemsWithoutAutoFocus = deepSet(
      [cluster_nr, "data", ix, "autofocus"],
      false,
      this.props.sequence_items,
    );

    const textToSet = text || "Neu!";

    const newItems = deepSet(
      [cluster_nr, "data", ix, "title"],
      textToSet,
      itemsWithoutAutoFocus,
    );
    this.props.updateSequnceItems(newItems, this.props.current_schoolyear.year);
  }

  closeOverlayCreateTemplate(success_add = false) {
    document.getElementById("content-container").classList.remove("isblured");
    this.setState({
      show_create_template_modal: false,
      template_data: {},
      show_create_template_success_note: success_add,
    });
  }

  openOverlayCreateTemplate(cluster, idx, id) {
    this.setState({
      show_create_template_modal: true,
      template_data: {
        cluster,
        idx,
        id,
      },
    });
  }

  openOverlayUseTemplate(pos, weeknumber, cluster) {
    this.setState({
      show_use_template_modal: true,
      template_data: {
        cluster,
        pos,
        weeknumber,
      },
    });
  }

  closeOverlayUseTemplate() {
    document.getElementById("content-container").classList.remove("isblured");
    this.setState({
      show_use_template_modal: false,
      template_data: {},
    });
  }

  /**
   * add an item to given position
   * @param pos
   */
  addItem(pos, kw, cluster) {
    const { props } = this;
    const { scooldays } = props.sequence_items[cluster];

    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );

    const result = findDatesInMap(
      props.school.calendarMap,
      pos,
      3,
      props.school.holidays,
      scooldays,
      abWeekSettings.ab_weeks,
      abWeekSettings.ab_settings,
      this.props.lessonDuration,
    );

    const hours = getScoolHours(scooldays, result, this.props.lessonDuration);

    if (result) {
      const id = uniqueId();

      const pos_end = Number(pos) + CONST.CALENDAR_INIT_ITEM_WEEKS;
      const item = {
        id,
        cluster: "",
        kw,
        pos: Number(pos),
        pos_end,
        title: "",
        weeks: CONST.CALENDAR_INIT_ITEM_WEEKS,
        label_weeks: result.label_weeks,
        date: result.date,
        daterange: result.date_range,
        item_width: "",
        item_height: CONST.CALENDAR_ITEM_DEF_HEIGHT,
        item_y: CONST.CALENDAR_ITEM_DEF_Y,
        calc_end_date: result.calc_end_date,
        calc_date: result.calc_date,
        autofocus: true,
        hours,
        label_hours: result.label_scoolhours,
        tab: "notes", // default on create new one
        tab_created: true,
      };

      const newIndex = props.sequence_items[cluster].data.length;
      const newSeqItems = deepSet(
        [Number(cluster), "data", newIndex],
        item,
        props.sequence_items,
      );

      props.updateSequnceItems(newSeqItems, props.current_schoolyear.year);
    }
  }

  updateColPos(seq_items, start_pos, end_pos, cluster) {
    // TODO REMOVE THIS
    // We deeply clone the seq items because the code below uses so much mutation
    // Rewrite this code in the future to be immutable
    const clonedSeqItems = structuredClone(seq_items).map((outer) => ({
      ...outer,
      data: outer.data.map((item, index) => ({ ...item, index })),
    }));

    const overlappingRows = clonedSeqItems[cluster].data.filter(
      (l) =>
        (start_pos >= l.pos && start_pos < l.pos_end) ||
        (end_pos > l.pos && end_pos < l.pos_end) ||
        (start_pos <= l.pos && end_pos >= l.pos_end) ||
        (Number(start_pos) === Number(l.pos) &&
          Number(end_pos) === Number(l.pos_end)),
    );
    const uid = guid();

    /**
     * have multiple elements - set new heights and refs...
     */
    if (overlappingRows.length > 1) {
      const heightToAdd =
        overlappingRows.length > 2
          ? overlappingRows.length * (CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT + 15)
          : CONST.CALENDAR_ITEMS_ROW_HEIGHT;

      const rowsWithAdjustedHeight = deepSet(
        [cluster, "height"],
        heightToAdd,
        clonedSeqItems,
      );

      let y_start = CONST.CALENDAR_ITEM_MULTIPLE_MARGIN;
      overlappingRows.forEach((item) => {
        rowsWithAdjustedHeight[cluster].data[item.index].item_height =
          CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT;
        rowsWithAdjustedHeight[cluster].data[item.index].item_y = y_start;
        rowsWithAdjustedHeight[cluster].data[item.index].refids = uid;
        y_start +=
          CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT +
          CONST.CALENDAR_ITEM_MULTIPLE_MARGIN;
      });

      const newSeqItems = deepSet(
        [Number(cluster), "lastupdate"],
        String(Date.now()),
        rowsWithAdjustedHeight,
      );
      this.props.updateSequnceItems(
        newSeqItems,
        this.props.current_schoolyear.year,
      );
      return;
    }

    /**
     * have only one on same place
     */
    if (overlappingRows.length === 1) {
      overlappingRows.forEach((item) => {
        // check if have an exists refid.
        if (item.refids) {
          clonedSeqItems[cluster].data.forEach((l) => {
            if (l.refids === item.refids) {
              l.item_height = CONST.CALENDAR_ITEM_DEF_HEIGHT;
              l.item_y = CONST.CALENDAR_ITEM_DEF_Y;
              l.refids = "";
            }
          });
        }
      });

      const newSeqItems = deepSet(
        [Number(cluster), "lastupdate"],
        String(Date.now()),
        clonedSeqItems,
      );
      this.props.updateSequnceItems(
        newSeqItems,
        this.props.current_schoolyear.year,
      );
      return;
    }

    const newSeqItems = deepSet(
      [Number(cluster), "lastupdate"],
      String(Date.now()),
      seq_items,
    );
    this.props.updateSequnceItems(
      newSeqItems,
      this.props.current_schoolyear.year,
    );
  }

  /**
   * show template overlay
   * @returns {*}
   */
  renderOverlayCreateTemplate() {
    document.getElementById("content-container").classList.add("isblured");

    const { template_data } = this.state;
    let selected_sequence = {};
    const tmp_sequence = this.props.sequence_items.slice();
    const obj = tmp_sequence?.[template_data.cluster];
    obj.data.forEach((o) => {
      if (Number(o.id) === Number(template_data.id)) {
        selected_sequence = o;
      }
    });
    selected_sequence = { ...selected_sequence };

    return (
      <div className="modal-wrapper" id="create-template-modal-wrapper">
        <Modal
          isOpen={this.state.show_create_template_modal}
          onRequestClose={this.closeOverlayCreateTemplate}
          ariaHideApp
          className="commonModal"
          overlayClassName="commonOverlay"
          contentLabel="Probezeit"
        >
          <div className="commonModalContent template-modal">
            <div className="closer">
              <a
                onClick={() => {
                  this.closeOverlayCreateTemplate();
                }}
              >
                <img src="/assets/images/close_modal.png" />
              </a>
            </div>
            <CreateTemplate
              classroom_title={obj.classroom_title}
              schoolyear={this.props.current_schoolyear.year}
              classlist={this.props.classes}
              subjects={this.props.subjects.raw}
              selected_subject={obj.subjectlist_id}
              selectedclass={obj.classroom_id}
              selected_sequence={selected_sequence}
              schoolyear_label={this.props.current_schoolyear.label}
              cancelTemplate={this.closeOverlayCreateTemplate}
              subject=""
              sequence=""
            />
          </div>
        </Modal>
      </div>
    );
  }

  renderSetupNoteNewSchoolyear() {
    const new_label = `${Number(
      this.props.current_schoolyear.year + 1,
    )}/${Number(this.props.current_schoolyear.year + 2)}`;

    return (
      <div className="note-setup-newyear-layer">
        <div className="note-setup-newyear">
          <div className="inner-wrapper">
            <div className="text">
              Das Schuljahr {this.props.current_schoolyear.label} ist rum. Wir
              wünschen dir tolle Sommerferien. 🏖
            </div>
            <div className="btn-wrapper">
              <div className="link-wrapper">
                <Link className="dash-link" to="/schuljahre/einrichten">
                  <span className="linktext">
                    Schuljahr {new_label} einrichten
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * show template overlay
   * @returns {*}
   */
  renderOverlayUseTemplate() {
    document.getElementById("content-container").classList.add("isblured");
    return (
      <div className="modal-wrapper" id="create-template-modal-wrapper">
        <Modal
          isOpen={this.state.show_use_template_modal}
          onRequestClose={this.closeOverlayUseTemplate}
          ariaHideApp
          className="commonModal topModal"
          overlayClassName="commonOverlay"
          contentLabel="Vorlage anwenden"
        >
          <div className="commonModalContent template-modal">
            <div className="closer">
              <a onClick={this.closeOverlayUseTemplate}>
                <img src="/assets/images/close_modal.png" />
              </a>
            </div>
            <UseTemplateList useTemplate={this.addTemplateItem} />
          </div>
        </Modal>
      </div>
    );
  }

  render() {
    const { props, state } = this;
    let active_classroom = props.active_view.classroom_id;
    if (!active_classroom && props.classes.length > 0) {
      active_classroom = props.classes[0].classId;
    }
    // get values from state
    const { show_create_template_modal, show_use_template_modal } = state;
    const setupNewyear = shouldSetupNextYear(props.current_schoolyear)
      ? this.renderSetupNoteNewSchoolyear()
      : "";

    const successNote = props.current_schoolyear.show_created_success ? (
      <ScreenNote
        type="success_in"
        message={`Das Schuljahr ${this.props.current_schoolyear.label} wurde erfolgreich angelegt. Das letzte Schuljahr findest du unter Account > <a>Archiv</a>`}
        linkname="Archiv"
        linktarget="/schuljahre"
        closeCallback={() => this.props.stampShowCreateSchoolyear()}
      />
    ) : (
      ""
    );

    const templateNote = state.show_create_template_success_note ? (
      <ScreenNote
        type="success_in"
        message="Die Vorlage wurde erfolgreich erstellt. Du findest die Vorlage unter > <a>Vorlagen</a>"
        linkname="Vorlagen"
        linktarget="/vorlagen"
        closeCallback={() =>
          this.setState({ show_create_template_success_note: false })
        }
      />
    ) : (
      ""
    );

    const searchParams = new URLSearchParams(this.props.location.search);
    const bookedProduct = searchParams.get("bookedProductName");
    const successBookedProductNote = bookedProduct ? (
      <ScreenNote
        type="success"
        message={`Du hast erfolgreich das ${bookedProduct} gebucht`}
        linkname=""
        linktarget=""
        closeCallback={() =>
          this.props.navigate(this.props.location.pathname, { replace: true })
        }
        onAnimationEndClose={() =>
          this.props.navigate(this.props.location.pathname, { replace: true })
        }
      />
    ) : (
      ""
    );

    let modal;
    if (show_create_template_modal) {
      modal = this.renderOverlayCreateTemplate();
    }

    let use_modal;
    if (show_use_template_modal) {
      use_modal = this.renderOverlayUseTemplate();
    }

    let show_empty_subjectst_note;
    let template_show_note_empty_subjects;
    const checklist_empty = props.sequence_items.filter(
      (item) => Number(item.classroom_id) === Number(active_classroom),
    );
    if (checklist_empty.length > 0) {
      show_empty_subjectst_note = true;
      checklist_empty.forEach((item) => {
        if (item.schooldays.length > 0) {
          show_empty_subjectst_note = false;
        }
      });
    }

    if (show_empty_subjectst_note) {
      template_show_note_empty_subjects = this.getNoteEmptySubjects();
    }

    const currentlyEditingASequence =
      state.renaming || state.dragFocusIndex || state.resizing;

    const abWeekSettings = getABWeeksSettings(
      this.props.settings,
      this.props.current_schoolyear.year,
    );

    return (
      <div className="calendar-wrapper">
        {templateNote}
        {successNote}
        {successBookedProductNote}
        <div id="component_nav">
          <div className="content-inner-wrapper">
            <div className="navigation" />
          </div>
        </div>

        <div className="relative" id="sequence_calendar">
          {template_show_note_empty_subjects}
          {/*
            add extra padding to increase container size so that overflow menu is not cut off at bottom when shown
            overflow-y cannot be set to visible when overflow-x is set to scroll
            https://medium.com/@justicart/overflow-x-scroll-overflow-y-visible-c1a98238e002
          */}
          <div className="row w-full flex overflow-x-scroll pb-8">
            <div className="cluster flex-none w-[130px] mt-[25px] sticky left-0 z-40">
              {props.sequence_items.map((o, cluster_nr) => {
                if (o.schooldays.length === 0) {
                  return null;
                } // kein Eintrag im Stundenplan
                if (Number(o.classroom_id) !== Number(active_classroom)) {
                  return null;
                }

                const overlapCounts = o.data.map((item) =>
                  getSequenceOverlappings(item, o.data),
                );

                // get the max overlap count
                const maxOverlaps = Math.max(...overlapCounts);

                const clusterHeight = getClusterHeight(maxOverlaps);

                const cluster_key = `cluster_${cluster_nr}`;
                const useDarkText = isBrightColor(o.color);
                return (
                  <div
                    key={cluster_key}
                    className="item"
                    style={{ backgroundColor: o.color, height: clusterHeight }}
                  >
                    <div
                      className={classList({
                        "p-2.5 font-bold": true,
                        "text-gray-10": useDarkText,
                      })}
                    >
                      {o.title}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="cal-grid flex-none" id="cal-grid-wrapper">
              <div className="date-desc">
                {props.school.schoolYearDays.months.map((elem, i) => {
                  const ddkey = `dd-${i}`;
                  const style = { left: elem.pos };
                  if (
                    i === 0 &&
                    props.school.schoolYearDays.months[1].pos < 100
                  ) {
                    return <div key={ddkey} className="item" style={style} />;
                  }
                  return (
                    <div key={ddkey} className="item" style={style}>
                      {elem.month}
                    </div>
                  );
                })}
              </div>

              <div
                className="date-grid"
                style={{
                  width:
                    CONST.CALENDAR_WEEK_UNIT * props.school.calendarMap.length,
                }}
              >
                {this.props.sequence_items.map(
                  (sequencesForClass, cluster_nr) => {
                    if (sequencesForClass.schooldays.length === 0) {
                      return null;
                    } // kein Eintrag im Stundenplan
                    if (
                      Number(sequencesForClass.classroom_id) !==
                      Number(active_classroom)
                    ) {
                      return null;
                    }
                    const weeksWithSequences = sequencesForClass.data.map(
                      (seq) => ({ start: seq.pos, end: seq.pos_end }),
                    );
                    const calmonth_key = `${this.props.current_schoolyear.year}_CalMonthCol_${cluster_nr}`;

                    const overlapCounts = sequencesForClass.data.map((item) =>
                      getSequenceOverlappings(item, sequencesForClass.data),
                    );

                    // get the max overlap count
                    const maxOverlaps = Math.max(...overlapCounts);
                    const clusterHeight = getClusterHeight(maxOverlaps);

                    return (
                      <CalMonthCol
                        key={calmonth_key}
                        showCreateMenu={!currentlyEditingASequence}
                        childsContainerHeight={clusterHeight}
                        cssWidth={CONST.CALENDAR_WEEK_UNIT - 1}
                        data={props.school.calendarMap}
                        dataCluster={cluster_nr}
                        callAddItem={this.addItem}
                        showTemplates={this.openOverlayUseTemplate}
                        hasAbWeeks={abWeekSettings.ab_weeks}
                        activeCreateMenu={
                          state.activeCreateElement?.row === cluster_nr
                            ? state.activeCreateElement.item
                            : null
                        }
                        onCreateMenuChange={(item) => {
                          this.setState({
                            activeCreateElement: { row: cluster_nr, item },
                          });
                        }}
                        weeksWithSequences={weeksWithSequences}
                      />
                    );
                  },
                )}
              </div>

              <div className="items-grid">
                {this.props.sequence_items.map((o, cluster_nr) => {
                  if (o.schooldays.length === 0) {
                    return null;
                  } // kein Eintrag im Stundenplan
                  if (Number(o.classroom_id) !== Number(active_classroom)) {
                    return null;
                  }

                  let key_items = `itemslist_${cluster_nr}`;
                  if (o.newCompKey) {
                    key_items = `itemslist_${o.newCompKey}`;
                  }

                  const overlapCounts = o.data.map((item) =>
                    getSequenceOverlappings(item, o.data),
                  );

                  // get the max overlap count
                  const maxOverlaps = Math.max(...overlapCounts);
                  const clusterHeight = getClusterHeight(maxOverlaps);

                  const items = o.data.map((c, ix) => {
                    const calculatedPositions = getOverlappingPositions(
                      c.pos,
                      c.pos_end,
                      o.data,
                      c.id,
                    );

                    const keyAndName = `seq_item_${cluster_nr + ix}`;
                    const elem_width =
                      (c.pos_end - c.pos) * CONST.CALENDAR_WEEK_UNIT ||
                      CONST.CALENDAR_WEEK_UNIT;

                    const computedPosition = {
                      x: calculatedPositions.posX,
                      y: calculatedPositions.posY,
                    };
                    const size = {
                      width: elem_width,
                      height: calculatedPositions.height,
                    };

                    let autofocus = false;
                    let css_focus = { display: "none" };
                    let css_title = { display: "block" };

                    if (c.autofocus) {
                      autofocus = true;
                      css_focus = { display: "block" };
                      css_title = { display: "none" };
                    }

                    const tmpId = c.id;

                    const zIndex = (() => {
                      switch (true) {
                        case state.dragFocusIndex === c.id:
                          return 20;
                        case state.dialogFocus === c.id:
                          return 10;
                        default:
                          return "auto";
                      }
                    })();

                    const elemDisplay =
                      state.hiddenDelete === c.id ? "hidden" : "block";

                    const label_hours = c.label_hours ? c.label_hours : c.hours;
                    const label_weeks = c.label_weeks ? c.label_weeks : c.weeks;

                    const isEditingThisSequence = [
                      state.renaming,
                      state.dragFocusIndex,
                      state.resizing,
                    ].includes(c.id);

                    const disabledDrag = isEditingThisSequence;

                    return (
                      <Rnd
                        key={keyAndName}
                        style={{
                          width: elem_width,
                          zIndex,
                          display: elemDisplay,
                        }}
                        bounds=".date-grid"
                        enableResizing={enables}
                        dragAxis="x"
                        size={size}
                        position={computedPosition}
                        onDrag={() => {
                          this.handleDrag(tmpId);
                        }}
                        onDragStop={(e, node) => {
                          this.handleOnDragStop(e, node);
                        }}
                        onResizeStop={this.handleOnResize}
                        dragGrid={[1, 50]}
                        onResize={(e) => {
                          this.handleResize(e, tmpId);
                        }}
                        cancel=".no-move"
                        disableDragging={disabledDrag}
                        data-id={c.id}
                        data-cluster={cluster_nr}
                        resizeHandleWrapperClass="resizer"
                      >
                        <div
                          className={classList({
                            seq_item: true,
                            active: isEditingThisSequence,
                            idle:
                              currentlyEditingASequence &&
                              !isEditingThisSequence,
                          })}
                          type="text"
                          key={keyAndName}
                          name={keyAndName}
                        >
                          <div className="dialog-handler no-move">
                            <CalendarSequenceDialog
                              deleteItem={() =>
                                this.deleteItem(cluster_nr, ix, c.id)
                              }
                              editItem={() => this.editItem(cluster_nr, c.id)}
                              setZindexDialog={() =>
                                this.setSequenzDialogFocus(c.id, ix)
                              }
                              saveAsTemplate={() =>
                                this.openOverlayCreateTemplate(
                                  cluster_nr,
                                  ix,
                                  c.id,
                                )
                              }
                              itemCluster={c}
                            />
                          </div>

                          <div className="dragger_left">
                            <img src="/assets/images/drag-left-calendar.png" />
                          </div>

                          <div className="data-wrapper">
                            <input
                              ref={(input) => {
                                this.listTextInputs[c.id] = input;
                              }}
                              type="text"
                              defaultValue={c.title}
                              onBlur={(e) => {
                                const title = e.target.value;
                                this.saveSequenceTitle(cluster_nr, ix, title);
                                this.setState({ renaming: null });
                              }}
                              style={css_focus}
                              onKeyDown={blurOnEnter}
                              autoFocus={autofocus}
                              placeholder="Bitte Titel eintragen..."
                              onFocus={() => {
                                this.setState({ renaming: c.id });
                              }}
                            />

                            <Link
                              to={makePathToSequence(c.id)}
                              style={css_title}
                              className="cursor-pointer text-gray-10 text-opacity-80"
                              {...fixReactRndTouchClicks(
                                this.state.pointerDownElement,
                                (val) => {
                                  this.setState({ pointerDownElement: val });
                                },
                              )}
                            >
                              {c.title}
                            </Link>

                            <div className="info-txt">
                              <span className="date-range no-move">
                                {c.daterange}
                              </span>
                            </div>
                            <div className="info-txt bottom">
                              <span className="weeks">
                                {label_weeks} Wochen
                              </span>{" "}
                              &nbsp; / &nbsp;
                              <span className="hours">
                                {label_hours} Stunden
                              </span>
                            </div>
                          </div>
                          <div className="dragger_right no-move">
                            <div className="overlay-content">
                              <div className="half-circle">
                                <div className="circle">
                                  <img
                                    className="icon"
                                    src="/assets/images/drag-right-calendar.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Rnd>
                    );
                  });

                  return (
                    <div
                      key={key_items}
                      className="items"
                      style={{ height: clusterHeight, position: "relative" }}
                    >
                      {items}
                    </div>
                  );
                })}
              </div>

              {abWeekSettings.ab_weeks && (
                <div id="ab-weeks-grid">
                  <ABWeeksCalendar
                    cssWidth={Number(CONST.CALENDAR_WEEK_UNIT)}
                    callAddItem={this.addItem}
                    data={props.school.calendarMap}
                    showTemplates={this.openOverlayUseTemplate}
                    settings={props.settings}
                    schoolyear={props.current_schoolyear}
                    updateSettings={props.updateSettings}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {modal}
        {use_modal}
        {setupNewyear}
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SequenceCalendar));
