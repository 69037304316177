import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { CacheBuster } from "../../common/components/CacheBuster";
import { COMMIT_REF, BUILD_TIME, STAGE } from "../config";

export default function LayoutLogin() {
  useEffect(() => {
    document.getElementById("body")?.classList.remove("archiv_view");

    // console.log('login page will mount - clear local storage');
    window.localStorage.clear();
    document.getElementById("content-container")?.classList.remove("isblured");
    document.getElementById("loading-spinner")?.classList.remove("running");

    document.body.classList.add("fullheight");
    return () => {
      document.body.classList.remove("fullheight");
    };
  }, []);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <div className="layout-login">
            <div className="left">&nbsp;</div>
            <div className="right">
              <div className="right-wrapper">
                <div className="header-section">
                  <div className="left-section">
                    <div className="item logo">
                      <h1 className="home">
                        <Link to="/login">
                          <img src="/assets/images/home-logo-rund.png" />
                        </Link>
                        <span className="text"> Freigeist </span>
                      </h1>
                    </div>
                  </div>
                  <div className="right-section">
                    <span className="text">
                      Du hast noch keinen Freigeist Account ?
                    </span>
                    <a
                      className="btn btn-dark"
                      target="_blank"
                      href="https://www.freigeist-app.de/registrieren"
                      rel="noreferrer"
                    >
                      kostenlos testen
                    </a>
                  </div>
                  <div style={{ clear: "both" }} />
                </div>

                <div className="Main">
                  <Outlet />
                </div>

                <div className="footer">
                  <a
                    href="https://www.freigeist-app.de/impressum"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Impressum
                  </a>
                  &nbsp; | &nbsp;
                  <a
                    href="https://www.freigeist-app.de/datenschutz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Datenschutz
                  </a>
                  <span className="bundle_version">
                    Freigeist -{" "}
                    <code>
                      {COMMIT_REF}
                      {STAGE !== "production" ? `, ${BUILD_TIME}` : null}
                    </code>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        );
      }}
    </CacheBuster>
  );
}
