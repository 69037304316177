import { useEffect, useRef, useState } from "react";
import "@draft-js-plugins/linkify/lib/plugin.css";
import Editor from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import {
  ItalicButton,
  BoldButton,
  HeadlineTwoButton,
} from "@draft-js-plugins/buttons";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import Immutable from "immutable";

const blockRenderMap = Immutable.Map({
  happyTimeBlock: {
    element: "div",
  },
  sadTimeBlock: {
    element: "div",
  },
});

function FGEditor(props) {
  const inlineToolbarPluginRef = useRef(null);
  const editorRef = useRef(null);
  useEffect(() => {
    if (!props.hideToolbar) {
      inlineToolbarPluginRef.current = createInlineToolbarPlugin();
    } else {
      inlineToolbarPluginRef.current = null;
    }
  }, [props.hideToolbar]);

  const init_value = props.value === "" ? "<p></p>" : props.value;
  const contentState = stateFromHTML(init_value);

  const [state, setState] = useState({
    editorState: EditorState.createWithContent(contentState),
  });

  const handleChange = (editorState) => {
    const currentContentState = state.editorState.getCurrentContent();
    const newContentState = editorState.getCurrentContent();
    if (currentContentState !== newContentState) {
      const html = stateToHTML(editorState.getCurrentContent());
      props.onChange(html);
      setState({ ...state, editorState });
    } else {
      // do nothing on change is triggered by focus
      setState({ ...state, editorState });
    }
  };

  const handleFocus = () => {
    if (props.onClick) {
      props.onClick();
    }
    editorRef.current.focus();
  };

  const handleBlur = () => {
    const html = stateToHTML(state.editorState.getCurrentContent());
    props.onBlurCall(html);
  };

  return (
    <div className="fgEditor" onClick={handleFocus}>
      {!inlineToolbarPluginRef.current ? (
        <Editor
          editorState={state.editorState}
          onChange={handleChange}
          plugins={[]}
          ref={editorRef}
          placeholder={props.placeholder}
          onBlur={handleBlur}
          blockRenderMap={blockRenderMap}
        />
      ) : (
        <div className="__editor_wrapper">
          <Editor
            editorState={state.editorState}
            onChange={handleChange}
            plugins={[inlineToolbarPluginRef.current]}
            ref={editorRef}
            placeholder={props.placeholder}
            onBlur={handleBlur}
            blockRenderMap={blockRenderMap}
          />
          <inlineToolbarPluginRef.current.InlineToolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <div>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                </div>
              )
            }
          </inlineToolbarPluginRef.current.InlineToolbar>
        </div>
      )}
    </div>
  );
}

export default FGEditor;
