import type { FunctionComponent, ComponentType, MouseEvent } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  type NavigateFunction,
  type Params,
  type Location,
} from "react-router-dom";

export type RoutingProps = {
  navigate: NavigateFunction;
  params: Params;
  location: Location;
};

// HOC for using react-router v6 navigation in class components
// (where hooks cannot be used)
function withRouter<T>(
  Component: ComponentType<T & RoutingProps>,
): FunctionComponent<T> {
  return function RoutingWrapper(props) {
    return (
      <Component
        {...props}
        navigate={useNavigate()}
        params={useParams()}
        location={useLocation()}
      />
    );
  };
}

type RouterType = { navigate: NavigateFunction };

// register global link click handler for routing
function globalNavigationClickHandler(
  event: MouseEvent<HTMLDivElement>,
  router: RouterType,
) {
  const { defaultPrevented, ctrlKey, altKey, metaKey, shiftKey, button } =
    event;
  const isRightOrMiddleMouseButton = button !== 0;
  const pressedModifierKey = ctrlKey || altKey || metaKey || shiftKey;

  // exit if not left mouse button, default is already prevented or any modifier key is pressed
  if (isRightOrMiddleMouseButton || defaultPrevented || pressedModifierKey) {
    return;
  }

  const findLinkInParents = (node: HTMLElement): HTMLAnchorElement | null => {
    if (node.tagName === "A") {
      return node as HTMLAnchorElement;
    }
    const parent = node.parentElement;
    return parent ? findLinkInParents(parent) : null;
  };

  const link = findLinkInParents(event.target as HTMLElement);
  const shouldDoSpaRouting =
    link && // link was found
    link.hasAttribute("href") && // has href
    new URL(link.href).origin === window.location.origin && // same origin
    !link.getAttribute("href")!.startsWith("#") && // href isn't hash only
    !link.hasAttribute("target") && // no target attribute
    !link.hasAttribute("download"); // no download link

  if (shouldDoSpaRouting) {
    event.preventDefault();

    const url = new URL(link.href);
    const relativeUrl = url.href.slice(url.origin.length);
    router.navigate(relativeUrl);
    // jump to top
    window.scrollTo({ top: 0, behavior: "auto" });
  }
}

export { withRouter, globalNavigationClickHandler };
