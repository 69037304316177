import { Component } from "react";
import { uniqueId } from "../../../../common/components/sequence_calendar/Utils";
import CheckBox from "../../../../common/components/settings/classes/CheckBox";
import ClassItem from "../../../../common/components/settings/classes/Class";
import Auth from "../../../../common/services/Auth";

export default class AddClassroomsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subjects: [],
      schoolyear: props.schoolyear,
      clearChecked: false,
    };
    this.addClassroom = this.addClassroom.bind(this);
    this.setClassroomId = this.setClassroomId.bind(this);

    // new methods
    this.addSubjectToClass = this.addSubjectToClass.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.lastSection = this.lastSection.bind(this);
    this.updateClassList = this.updateClassList.bind(this);
    this.updateTimeTable = this.updateTimeTable.bind(this);
    this.finalizeSchoolyear = this.finalizeSchoolyear.bind(this);

    // new methods
    this.addToList = this.addToList.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchData) {
      this.props.fetchClassesList(Auth.getUserId());
    }
  }

  setClassroomId(e) {
    this.props.setClassroomId(e.target.value);
  }

  /**
   * set the class title / name
   */
  setClassesTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  updateClassList(obj) {
    obj.schoolyear = this.state.schoolyear;
    obj.user_id = Auth.getUserId();

    const classes = this.props.classes.map((cl) => {
      if (String(cl._id) === String(obj._id)) {
        return {
          ...cl,
          title: obj.title,
          schoolyear: obj.schoolyear,
          subjects: obj.subjects,
        };
      }
      return cl;
    });
    this.props.updateClasses(classes);
  }

  updateTimeTable(obj) {
    this.props.updateTimetable(obj);
  }

  /**
   * add class with subjects to list via props method
   */
  addToList(newClass) {
    this.props.updateClasses([...this.props.classes, newClass]);
  }

  /**
   * add a class with subjects to an object for transport
   */
  addClassroom() {
    if (this.state.title) {
      const id = uniqueId();
      const class_obj = {
        _id: id,
        user_id: Auth.getUserId(),
        title: this.state.title,
        subjects: this.state.subjects,
        schoolyear: this.state.schoolyear,
        old_ref_id: id,
      };

      // reset for next class object
      this.setState({
        title: "",
        subjects: [],
        clearChecked: true,
      });
      this.addToList(class_obj);
    }
  }

  /**
   * add subject ids to classes
   * @param type
   * @param value
   */
  addSubjectToClass(type, value) {
    let { subjects } = this.state;
    const parsedValue = JSON.parse(value);

    if (type === "add") {
      subjects.push(parsedValue);
    } else {
      const list = subjects.filter((p) => p._id !== parsedValue._id);
      subjects = list;
    }
    this.setState({
      subjects,
      clearChecked: false,
    });
  }

  /**
   *
   * @param id
   */
  removeFromList(id) {
    const classes = this.props.classes.filter((p) => p._id !== id);
    this.props.updateClasses(classes);
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.nextSection(this.props.classes);
  }

  /**
   * fill storage in register and go away
   */
  lastSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.lastSection();
  }

  prevSection() {
    this.props.prevSection();
  }

  finalizeSchoolyear() {
    this.props.finalizeSchoolyear();
  }

  render() {
    const enabled = this.state.title && this.state.subjects.length > 0;
    const nextenabled = this.props.classes.length > 0;

    const subject_list =
      this.props.subjects && this.props.subjects.length > 0
        ? this.props.subjects
        : [];

    const class_list = this.props.classes;

    const show_jumper_school = this.props.classes.length === 0;

    return (
      <div id="classrooms" className="">
        <div className="classes_list">
          {class_list.map((item) => {
            const unique = uniqueId();

            return (
              <ClassItem
                key={`idx-${unique}`}
                title={item.title}
                subjects={item.subjects}
                subject_list={subject_list}
                id={item._id}
                updateClassList={this.updateClassList}
                removeFromList={this.removeFromList}
                timetable={this.props.timetable}
                updateTimeTable={this.updateTimeTable}
                item={item}
              />
            );
          })}
        </div>

        <div className="row">
          <div className="classes_item_container">
            <div className="fields">
              <div className="field_input">
                <label>Klasse</label>
                <input
                  placeholder="z.B. Klasse 6b"
                  className="inputfield"
                  value={this.state.title}
                  onChange={(e) => {
                    this.setClassesTitle(e);
                  }}
                />
              </div>
            </div>
            <div className="subjects">
              <label>
                Fächer{" "}
                <span className="info">
                  (die du in dieser Klasse unterrichtest)
                </span>
              </label>
              <div className="subject_items">
                {subject_list.map((item, idx) => {
                  const key = `select-${idx}`;
                  return (
                    <CheckBox
                      key={idx}
                      cssid={key}
                      item={item}
                      addSubjectToClass={this.addSubjectToClass}
                      value={item}
                      clearChecked={this.state.clearChecked}
                    />
                  );
                })}
              </div>
            </div>
            <div className="btn_wrapper">
              <button
                disabled={!enabled}
                className="btn btn-dark"
                onClick={this.addClassroom}
              >
                anlegen
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Fächer
              </a>
            </div>

            {show_jumper_school && (
              <div className="next">
                <button
                  className="btn btn-dark"
                  onClick={this.finalizeSchoolyear}
                >
                  Überspringen - neues Schuljahr 2024/2025 anlegen
                </button>
              </div>
            )}

            {!show_jumper_school && (
              <div className="next">
                <button
                  disabled={!nextenabled}
                  className="btn btn-step"
                  onClick={() => this.nextSection()}
                >
                  Weiter zu Stundenplan
                </button>
              </div>
            )}

            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}
