import { Component as ReactComponent } from "react";
import Dropdown from "react-dropdown";
import { DateTime } from "luxon";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import PdfWeekly from "../../common/pdf/PdfWeeklyOverview";
import PdfYearly from "../../common/pdf/PdfYearlyOverview";
import PdfMaterial from "../../common/pdf/PdfMaterialOverview";
import * as API from "../../common/constants/ApiRoutes";
import Auth from "../../common/services/Auth";
import { withRouter } from "../../common/utils/routing";
import { selectSettings } from "../../features/settings/settings-slice";
import {
  selectSchoolWithHolidays,
  selectSequenceItems,
} from "../combined-selectors/index";
import { selectCurrentSchoolyearData } from "../../features/current-schoolyear/current-schoolyear-slice";
import { api } from "../../services/api";

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  subjects: state.subjects,
  sequence_items: selectSequenceItems(state),
  school: selectSchoolWithHolidays(state),
  timetable: state.timetable,
  authentication: state.authentication,
  classes: state.classes,
  settings: selectSettings(state),
});

const mapDispatchToProps = {
  updateSettings: api.endpoints.updateSettings.initiate,
};

class PdfsDashboard extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      weekly: {
        idx: "",
        init: "",
      },
      yearly_class: "",
      material_period_1: {
        from: "",
        to: "",
      },
      material_period_2: {
        from: "",
        to: "",
      },
      material_period_3: {
        from: "",
        to: "",
      },
      material_class: { value: "" },
      material_subject: "",
    };

    this.print_weekly_pdf = this.print_weekly_pdf.bind(this);
    this._weeklyReportList = this._weeklyReportList.bind(this);
    this.setWeeklyRange = this.setWeeklyRange.bind(this);
    this.print_yearly_pdf = this.print_yearly_pdf.bind(this);
    this._yearlyReportClasses = this._yearlyReportClasses.bind(this);
    this.setYearlyClass = this.setYearlyClass.bind(this);
    this._getMaterialWeekList = this._getMaterialWeekList.bind(this);
    this.print_material_pdf = this.print_material_pdf.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.getClassSubjectList = this.getClassSubjectList.bind(this);
    this.setMaterialClass = this.setMaterialClass.bind(this);
    this.setMaterialSubject = this.setMaterialSubject.bind(this);
    this.setMaterialPeriod = this.setMaterialPeriod.bind(this);
  }

  componentDidMount() {
    document.title = "Freigeist | Downloads";
    const init_obj = this.state;
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.material_class
    ) {
      init_obj.material_class = this.props.settings.settings.pdf.material_class;
      init_obj.material_subject =
        this.props.settings.settings.pdf.material_subject;
    } else {
      // filter stuff classes and subjects
      const classesfilter = this.getClassSubjectList();
      const [firstClass] = classesfilter.classes;
      if (firstClass) {
        init_obj.material_class = firstClass;
        const [firstMaterialSubject] =
          classesfilter.subjects[init_obj.material_class.value];
        init_obj.material_subject = firstMaterialSubject;
      }
    }
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.material_period_1
    ) {
      init_obj.material_period_1.from =
        this.props.settings.settings.pdf.material_period_1.from;
      init_obj.material_period_1.to =
        this.props.settings.settings.pdf.material_period_1.to;
    }
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.material_period_2
    ) {
      init_obj.material_period_2.from =
        this.props.settings.settings.pdf.material_period_2.from;
      init_obj.material_period_2.to =
        this.props.settings.settings.pdf.material_period_2.to;
    }
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.material_period_3
    ) {
      init_obj.material_period_3.from =
        this.props.settings.settings.pdf.material_period_3.from;
      init_obj.material_period_3.to =
        this.props.settings.settings.pdf.material_period_3.to;
    }
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.yearly_class
    ) {
      init_obj.yearly_class = this.props.settings.settings.pdf.yearly_class;
    }
    if (
      !this.props.current_schoolyear.is_archive &&
      this.props.settings.settings &&
      this.props.settings.settings.pdf &&
      this.props.settings.settings.pdf.weekly
    ) {
      init_obj.weekly = this.props.settings.settings.pdf.weekly;
    }
    this.setState(init_obj);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.current_schoolyear.is_archive &&
      !this.props.current_schoolyear.is_archive
    ) {
      this.props.navigate("/zeitleiste");
    }
  }

  setMaterialPeriod(periodNumber, fromOrToObject) {
    const settingsWithPdf = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.pdf) {
        draft.settings.pdf = {};
      }
    });

    switch (periodNumber) {
      case "1": {
        const period = { ...this.state.material_period_1, ...fromOrToObject };
        this.setState({ material_period_1: period });
        const newSettings = immutableUpdate(settingsWithPdf, (draft) => {
          draft.settings.pdf.material_period_1 = period;
        });
        this.updateSettings(newSettings);
        break;
      }
      case "2": {
        const period = { ...this.state.material_period_2, ...fromOrToObject };
        this.setState({ material_period_2: period });
        const newSettings = immutableUpdate(settingsWithPdf, (draft) => {
          draft.settings.pdf.material_period_2 = period;
        });
        this.updateSettings(newSettings);
        break;
      }
      case "3": {
        const period = { ...this.state.material_period_3, ...fromOrToObject };
        this.setState({ material_period_3: period });
        const newSettings = immutableUpdate(settingsWithPdf, (draft) => {
          draft.settings.pdf.material_period_3 = period;
        });
        this.updateSettings(newSettings);
        break;
      }
      default:
        break;
    }
  }

  /**
   * set the date range from weekly report
   */
  setWeeklyRange(obj) {
    this.setState({
      weekly: {
        idx: obj.value,
        init: obj,
      },
    });
    const settings = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.pdf) {
        draft.settings.pdf = {};
      }
      draft.settings.pdf.weekly = {
        idx: obj.value,
        init: obj,
      };
    });
    if (!this.props.current_schoolyear.is_archive) {
      this.updateSettings(settings);
    }
  }

  setYearlyClass(obj) {
    this.setState({
      yearly_class: obj,
    });

    const settings = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.pdf) {
        draft.settings.pdf = {};
      }
      draft.settings.pdf.yearly_class = obj;
    });
    if (!this.props.current_schoolyear.is_archive) {
      this.updateSettings(settings);
    }
  }

  /**
   * build list with classes and subjects
   * @returns {{classes: Array}}
   */
  getClassSubjectList() {
    const classes = [];
    const subjects = {};
    const list = this.props.classes;
    list.forEach((elem) => {
      const class_item = {
        label: elem.title,
        value: elem.classId,
      };
      classes.push(class_item);

      if (elem.subjects && elem.subjects.length > 0) {
        if (!subjects[String(elem.classId)]) {
          subjects[String(elem.classId)] = [];
        }
        elem.subjects.forEach((subs) => {
          const sub_item = {
            label: subs.title,
            value: subs._id,
          };
          subjects[String(elem.classId)].push(sub_item);
        });
      }
    });
    return {
      classes,
      subjects,
    };
  }

  /**
   * set materical class, if change set as default
   * the first matched subject too
   */
  setMaterialClass(obj, list) {
    const subject = list.subjects[obj.value]?.[0];
    const settings = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.pdf) {
        draft.settings.pdf = {};
      }

      if (subject) {
        draft.settings.pdf.material_subject = subject;
      }
      // class setting
      draft.settings.pdf.material_class = obj;
    });

    this.setState({
      material_class: obj,
      material_subject: subject ?? "",
    });
    if (!this.props.current_schoolyear.is_archive) {
      this.updateSettings(settings);
    }
  }

  /**
   * set material subject
   */
  setMaterialSubject(obj) {
    this.setState({
      material_subject: obj,
    });
    const settings = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.pdf) {
        draft.settings.pdf = {};
      }
      draft.settings.pdf.material_subject = obj;
    });
    if (!this.props.current_schoolyear.is_archive) {
      this.updateSettings(settings);
    }
  }

  /**
   * build the week list for weekly dropdown
   * @private
   */
  _weeklyReportList() {
    const current_kw = DateTime.now().weekNumber;

    const list = [];
    let default_selected = false;
    this.props.school.calendarMap.forEach((elem, idx) => {
      const idx_number = idx + 1;

      let txt;
      if (current_kw === Number(elem.kw)) {
        txt = `Aktuelle Woche | ${elem.date.substr(
          0,
          3,
        )} bis ${elem.end_date.substr(0, 3)} ${elem.month_name}`;
        default_selected = {
          // label: txt, value: {compDate: elem.compDate, compEndDate: elem.compEndDate}
          label: txt,
          value: idx,
        };
      } else {
        txt = `${idx_number}. Woche | ${elem.date.substr(
          0,
          3,
        )} bis ${elem.end_date.substr(0, 3)} ${elem.month_name}`;
      }
      list.push({
        label: txt,
        // value: {compDate: elem.compDate, compEndDate: elem.compEndDate}
        value: idx,
      });
    });

    if (!default_selected) {
      [default_selected] = list;
    }

    return { list, init: default_selected };
  }

  /**
   *
   * @param section
   * @private
   */
  _getMaterialWeekList() {
    const list = [];
    this.props.school.calendarMap.forEach((elem, idx) => {
      const idx_number = idx + 1;
      const txt = `${idx_number}. Schulwoche | ${elem.date.substr(
        0,
        3,
      )} bis ${elem.end_date.substr(0, 3)} ${elem.month_name}`;
      list.push({
        label: txt,
        // value: {compDate: elem.compDate, compEndDate: elem.compEndDate}
        value: idx,
      });
    });

    let period = list.length / 3;
    period = parseInt(period);

    const period2 = period + 1;
    const period2_end = period2 + period;

    const period3 = period2_end + 1;
    const period3_end = list.length;

    return {
      period1_from: 0,
      period1_to: period - 1,
      period2_from: period2 - 1,
      period2_to: period2_end - 1,
      period3_from: period3 - 1,
      period3_to: period3_end - 1,
      list,
    };
  }

  /**
   * yearly class list dropdown valiues
   * @returns {Array}
   * @private
   */
  _yearlyReportClasses() {
    const list = [];
    this.props.classes.forEach((elem) => {
      list.push({ label: elem.title, value: elem.classId });
    });
    if (list.length === 0) {
      list.push({ label: "", value: "" });
    }
    return list;
  }

  /**
   *
   * @param e
   * @param sweekly_idx
   */
  print_weekly_pdf(e, weekly_idx) {
    e.preventDefault();

    const ids_list = [];
    const sequence_list = [];
    this.props.sequence_items.forEach((elem) => {
      // if ( elem.classroom_id == class_id ) {
      if (elem.data && elem.data.length > 0) {
        elem.data.forEach((seq) => {
          ids_list.push(seq.id);
        });
      }
      sequence_list.push(elem);
      // }
    });
    const payload = {
      user_id: Auth.getUserId(),
      sequence_item_ids: ids_list,
      schoolyear: this.props.current_schoolyear.year,
    };
    fetch(API.API_SEQUNECESLIST_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          const sequences_list = data.sequences;
          PdfWeekly(
            this.props.school.calendarMap[weekly_idx],
            this.props.sequence_items,
            this.props.school,
            this.props.authentication,
            this.props.timetable.saturday,
            sequences_list,
          );
        });
      }
      return response.json().then(({ message }) => {
        // eslint-disable-next-line no-console
        console.error(message);
      });
    });
  }

  /**
   *
   * @param e
   * @param sweekly_idx
   */
  print_material_pdf(e, period, from, to) {
    // filter sequences - class and subject
    const classes = this.state.material_class;
    const subject = this.state.material_subject;
    let items = "";
    const ids_list = [];
    this.props.sequence_items.forEach((item) => {
      if (
        Number(item.classroom_id) === Number(classes.value) &&
        item.subjectlist_id === subject.value
      ) {
        items = item;
        if (item.data) {
          item.data.forEach((seq) => {
            ids_list.push(seq.id);
          });
        }
      }
    });

    const payload = {
      user_id: Auth.getUserId(),
      sequence_item_ids: ids_list,
      schoolyear: this.props.current_schoolyear.year,
    };
    let sequences_list = [];
    fetch(API.API_SEQUNECESLIST_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          sequences_list = data.sequences;

          PdfMaterial(
            from,
            to,
            items,
            sequences_list,
            this.props.school,
            this.props.authentication,
            classes,
            subject,
            this.props.current_schoolyear,
          );
        });
      } else {
        response.json().then(() => {
          PdfMaterial(
            from,
            to,
            items,
            sequences_list,
            this.props.school,
            this.props.authentication,
            classes,
            subject,
            this.props.current_schoolyear,
          );
        });
      }
    });
  }

  /**
   *
   * @param e
   * @param sweekly_idx
   */
  print_yearly_pdf(e, class_id, class_name) {
    e.preventDefault();
    const ids_list = [];
    const sequence_list = [];
    this.props.sequence_items.forEach((elem) => {
      if (Number(elem.classroom_id) === Number(class_id)) {
        if (elem.data) {
          elem.data.forEach((seq) => {
            ids_list.push(seq.id);
          });
        }
        sequence_list.push(elem);
      }
    });

    const payload = {
      user_id: Auth.getUserId(),
      sequence_item_ids: ids_list,
      schoolyear: this.props.current_schoolyear.year,
    };
    let sequences_list = [];
    fetch(API.API_SEQUNECESLIST_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          sequences_list = data.sequences;
          const lehrplanbezug = [];
          const __lehrplanbezug = [];
          if (sequences_list.length > 0) {
            sequences_list.forEach((sl) => {
              if (sl.timeslots && sl.timeslots.length > 0) {
                sl.timeslots.forEach((ts) => {
                  if (!lehrplanbezug[String(sl.sequence_item_id)]) {
                    lehrplanbezug[String(sl.sequence_item_id)] = [];
                    __lehrplanbezug[String(sl.sequence_item_id)] = [];
                  }
                  if (ts.lehrplanbezug && ts.lehrplanbezug.length > 0) {
                    ts.lehrplanbezug.forEach((lp) => {
                      if (
                        !__lehrplanbezug[String(sl.sequence_item_id)][lp.label]
                      ) {
                        __lehrplanbezug[String(sl.sequence_item_id)][lp.label] =
                          [lp.label];
                        lehrplanbezug[String(sl.sequence_item_id)].push(
                          lp.label,
                        );
                      }
                    });
                  }
                });
              }
            });
          }
          PdfYearly(
            class_id,
            sequence_list,
            this.props.school,
            this.props.authentication,
            lehrplanbezug,
            class_name,
            this.props.current_schoolyear,
          );
        });
      } else {
        response.json().then(() => {
          const lehrplanbezug = [];
          PdfYearly(
            class_id,
            sequence_list,
            this.props.school,
            this.props.authentication,
            lehrplanbezug,
            class_name,
            this.props.current_schoolyear,
          );
        });
      }
    });
  }

  /**
   * update settings handler
   */
  updateSettings(settings) {
    const { props } = this;
    const { is_archive, year } = props.current_schoolyear;
    if (!is_archive) {
      this.props.updateSettings({ ...settings, schoolyear: year });
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    let show_fields;

    if (
      this.props.classes.length > 0 &&
      this.props.timetable.entries.length > 0 &&
      this.props.sequence_items.length > 0
    ) {
      show_fields = true;
    } else {
      show_fields = false;
    }

    // config weekly overview
    const week_list = this._weeklyReportList();

    // console.log('weeklist');
    // console.log(week_list);

    let weekly_idx = 0;
    let weekly_init = "";

    if (this.state.weekly.idx === "") {
      weekly_idx = week_list.init.value;
      weekly_init = week_list.init;
    } else {
      weekly_idx = this.state.weekly.idx;
      weekly_init = this.state.weekly.init;
    }

    // config yearly report
    const class_list = this._yearlyReportClasses();

    // console.log(class_list);

    let class_list_init = "";
    let yearly_idx = "";
    let yearly_classname = "";
    if (!this.state.yearly_class) {
      [class_list_init] = class_list;
      yearly_idx = class_list_init.value;
      yearly_classname = class_list_init.label;
    } else {
      class_list_init = this.state.yearly_class;
      yearly_idx = this.state.yearly_class.value;
      yearly_classname = this.state.yearly_class.label;
    }

    // material reports - three periods
    const materlial_weeks = this._getMaterialWeekList();
    // console.log('zeitblöcke');
    // console.log(materlial_weeks);

    let period1_init_from = "";
    let period1_init_to = "";

    let period2_init_from = "";
    let period2_init_to = "";

    let period3_init_from = "";
    let period3_init_to = "";

    if (!this.state.material_period_1.from) {
      period1_init_from = materlial_weeks.list[materlial_weeks.period1_from];
    } else {
      period1_init_from = this.state.material_period_1.from;
    }

    if (!this.state.material_period_1.to) {
      period1_init_to = materlial_weeks.list[materlial_weeks.period1_to];
    } else {
      period1_init_to = this.state.material_period_1.to;
    }

    if (!this.state.material_period_2.from) {
      period2_init_from = materlial_weeks.list[materlial_weeks.period2_from];
    } else {
      period2_init_from = this.state.material_period_2.from;
    }

    if (!this.state.material_period_2.to) {
      period2_init_to = materlial_weeks.list[materlial_weeks.period2_to];
    } else {
      period2_init_to = this.state.material_period_2.to;
    }

    if (!this.state.material_period_3.from) {
      period3_init_from = materlial_weeks.list[materlial_weeks.period3_from];
    } else {
      period3_init_from = this.state.material_period_3.from;
    }

    if (!this.state.material_period_3.to) {
      period3_init_to = materlial_weeks.list[materlial_weeks.period3_to];
    } else {
      period3_init_to = this.state.material_period_3.to;
    }

    // filter stuff classes and subjects
    const classesfilter = this.getClassSubjectList();
    // console.log(classesfilter);

    let def_material_class = this.state.material_class;
    if (def_material_class && def_material_class.value === "") {
      [def_material_class] = classesfilter.classes;
    }

    const material_subject_list =
      classesfilter.subjects[def_material_class.value];
    let def_material_subject = this.state.material_subject;
    if (
      !def_material_subject &&
      classesfilter.subjects[
        def_material_class.value &&
          classesfilter.subjects[def_material_class.value][0]
      ]
    ) {
      [def_material_subject] = classesfilter.subjects[def_material_class.value];
    }

    return (
      <div className="wrapper" id="pdf-dashboard">
        {show_fields && (
          <div className="fields">
            <div className="row-wrapper">
              <h2>Wochenpläne</h2>
              <div className="block">
                <div className="label">Woche</div>
                <Dropdown
                  className="dropdown"
                  key="drop-weekly-pdf"
                  options={week_list.list}
                  onChange={this.setWeeklyRange}
                  value={weekly_init}
                  placeholder=""
                />

                <div className="pdf_download">
                  <span className="icon-wrapper">
                    <img src="/assets/images/pdf-icon.png" className="icon" />
                  </span>
                  <span className="text-wrapper">
                    <a
                      href="#"
                      onClick={(e) => {
                        this.print_weekly_pdf(e, weekly_idx);
                      }}
                    >
                      Wochenplan herunterladen
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="row-wrapper list">
              <h2>Trimesterpläne</h2>

              <div className="block blank">
                <div className="dropdown-wrapper">
                  <div className="left">
                    <div className="label">Klasse</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={classesfilter.classes}
                      onChange={(d) => this.setMaterialClass(d, classesfilter)}
                      value={def_material_class}
                      placeholder=""
                    />
                  </div>
                  <div className="right">
                    <div className="label">Fach</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={material_subject_list}
                      onChange={this.setMaterialSubject}
                      value={def_material_subject}
                      placeholder=""
                    />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>
              </div>

              <div className="block">
                <div className="title">1. Trimesterplan</div>
                <div className="dropdown-wrapper">
                  <div className="left">
                    <div className="label">von</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("1", { from: d })}
                      value={period1_init_from}
                      placeholder=""
                    />
                  </div>
                  <div className="right">
                    <div className="label">bis</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("1", { to: d })}
                      value={period1_init_to}
                      placeholder=""
                    />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>

                <div className="pdf_download">
                  <span className="icon-wrapper">
                    <img src="/assets/images/pdf-icon.png" className="icon" />
                  </span>
                  <span className="text-wrapper">
                    <a
                      href="#"
                      onClick={(e) => {
                        this.print_material_pdf(
                          e,
                          "period1",
                          period1_init_from,
                          period1_init_to,
                        );
                      }}
                    >
                      Trimesterplan herunterladen
                    </a>
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="title">2. Trimesterplan</div>
                <div className="dropdown-wrapper">
                  <div className="left">
                    <div className="label">von</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("2", { from: d })}
                      value={period2_init_from}
                      placeholder=""
                    />
                  </div>
                  <div className="right">
                    <div className="label">bis</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("2", { to: d })}
                      value={period2_init_to}
                      placeholder=""
                    />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>

                <div className="pdf_download">
                  <span className="icon-wrapper">
                    <img src="/assets/images/pdf-icon.png" className="icon" />
                  </span>
                  <span className="text-wrapper">
                    <a
                      href="#"
                      onClick={(e) => {
                        this.print_material_pdf(
                          e,
                          "period2",
                          period2_init_from,
                          period2_init_to,
                        );
                      }}
                    >
                      Trimesterplan herunterladen
                    </a>
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="title">3. Trimesterplan</div>
                <div className="dropdown-wrapper">
                  <div className="left">
                    <div className="label">von</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("3", { from: d })}
                      value={period3_init_from}
                      placeholder=""
                    />
                  </div>
                  <div className="right">
                    <div className="label">bis</div>
                    <Dropdown
                      className="dropdown"
                      key="drop-yearly-pdf"
                      options={materlial_weeks.list}
                      onChange={(d) => this.setMaterialPeriod("3", { to: d })}
                      value={period3_init_to}
                      placeholder=""
                    />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>

                <div className="pdf_download">
                  <span className="icon-wrapper">
                    <img src="/assets/images/pdf-icon.png" className="icon" />
                  </span>
                  <span className="text-wrapper">
                    <a
                      href="#"
                      onClick={(e) => {
                        this.print_material_pdf(
                          e,
                          "period3",
                          period3_init_from,
                          period3_init_to,
                        );
                      }}
                    >
                      Trimesterplan herunterladen
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="row-wrapper">
              <h2>Jahresplan</h2>
              <div className="block">
                <div className="label">Klasse</div>
                <Dropdown
                  className="dropdown"
                  key="drop-yearly-pdf"
                  options={class_list}
                  onChange={this.setYearlyClass}
                  value={class_list_init}
                  placeholder=""
                />

                <div className="pdf_download">
                  <span className="icon-wrapper">
                    <img src="/assets/images/pdf-icon.png" className="icon" />
                  </span>
                  <span className="text-wrapper">
                    <a
                      href="#"
                      onClick={(e) => {
                        this.print_yearly_pdf(e, yearly_idx, yearly_classname);
                      }}
                    >
                      Jahresplan herunterladen
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {!show_fields && (
          <div className="message">
            <div className="row-wrapper">
              Du hast noch keine Klassen angelegt. Sobald du Klassen angelegt
              und für sie den Unterricht geplant hast, werden hier die einzelnen
              Pläne (Jahresplan, Trimesterpläne, Wochenpläne) zum Download
              bereitstehen.
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const Component = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PdfsDashboard)),
);
