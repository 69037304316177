import { Component as ReactComponent } from "react";
import { Link } from "react-router-dom";
import * as API from "../../common/constants/ApiRoutes";
import PasswordForm from "../../common/components/auth/password/PasswordForm";
import { withRouter } from "../../common/utils/routing";

class PasswordChangeResetPage extends ReactComponent {
  constructor(props) {
    super(props);
    // set the initial component state
    this.state = {
      errors: "",
      process: "",
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.renderMessages = this.renderMessages.bind(this);
  }

  componentDidMount() {
    document.title = "Freigeist | Passwort ändern";
    let token = false;
    // console.log(this.props);

    // get data from query string and prepare into object
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.has("token")) {
      token = queryParams.get("token");
    }

    if (token) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${API.API_CHECK_RESET_TOKEN}/${token}`);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.responseType = "json";
      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          this.setState({
            token,
          });
        } else {
          // failure
          this.setState({
            errors: "no_valid_token",
          });
        }
      });
      xhr.send(null);
    }
  }

  resetPassword(password) {
    if (this.state.token) {
      const token = encodeURIComponent(this.state.token);
      const formData = `token=${token}&password=${password}`;
      const xhr = new XMLHttpRequest();
      xhr.open("post", API.API_DO_PASSWORD_RESET);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.responseType = "json";
      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          // change the component-container state
          this.setState({ errors: "", process: "done" });
        } else {
          // failure
          this.setState({
            errors: "technical_error",
          });
        }
      });
      xhr.send(formData);
    }
  }

  renderMessages() {
    if (this.state.errors === "no_valid_token") {
      return (
        <div className="message-box notice">
          Der Link ist nicht mehr gültig, bitte neuen anfordern unter:{" "}
          <Link to="/passwort-vergessen">Passwort vergessen</Link>
        </div>
      );
    }
    if (this.state.process === "done") {
      return (
        <div className="message-box">
          <strong>Dein neues Passwort wurde erfolgreich gespeichert.</strong>
          <br />
          <br />
          Du kannst dich mit dem neuen Passwort hier wieder einloggen:{" "}
          <Link to="/login">zum Login</Link>
        </div>
      );
    }
    return null;
  }

  /**
   * Render the component.
   */
  render() {
    const display_form = !this.state.errors && !this.state.process;

    return (
      <div id="password" className="password-change">
        <div className="headline">
          <h1>Gib hier dein neues Passwort ein</h1>
        </div>

        {this.renderMessages()}

        {display_form && (
          <div className="process-wrapper">
            <div className="description" />
            <PasswordForm passwordReset resetPassword={this.resetPassword} />
          </div>
        )}
      </div>
    );
  }
}

export const Component = withRouter(PasswordChangeResetPage);
