import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  calculateEndDateFree,
  getFirstPayDate,
} from "../../../utils/PaymentCalculations";

import { API_URL as apiUrl } from "../../../../app/config";
import Auth from "../../../services/Auth";
import { isPayStateFree } from "../../../utils/paystates";
import { classList } from "../../../utils/classList";
import ScreenNote from "../../ScreenNotes";
import {
  getBookedProductPrice,
  getBookedProductName,
  getFirstPayDateObj,
  ABO_TYPES,
  getOtherProductName,
} from "./util";
import { AboBox } from "./AboBox";
import { AboModal } from "./AboModal";

const diffDaysShowPaymentInformation = 30;
const saveCostsYearlyPackage = (19.89).toLocaleString();

const timelineTarget = "/zeitleiste";
const settingsTarget = "/einstellungen";

const productNameYearly = "Jahresabo";
const productNameMonthly = "Monatsabo";

async function startCheckoutSession(subscriptionType, endDateFree) {
  const firstBillingDate = getFirstPayDate(endDateFree);
  const searchParams = new URLSearchParams(window.location.search);
  const showProductLayerFromTimeLine = searchParams.get("showProducts");

  const queryParamProductName =
    subscriptionType === "monthly" ? productNameMonthly : productNameYearly;

  const searchParamsToAdd = new URLSearchParams({
    statusProductCheckout: "success",
    bookedProductName: queryParamProductName,
  });

  const payload = {
    firstBillingDate,
    type: subscriptionType,
    returnTarget: showProductLayerFromTimeLine
      ? `${timelineTarget}?${searchParamsToAdd}`
      : `${settingsTarget}?${searchParamsToAdd}`,
    returnTargetCancel: showProductLayerFromTimeLine
      ? timelineTarget
      : settingsTarget,
  };

  const response = await fetch(`${apiUrl}stripe_checkout_session`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${Auth.getToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const data = await response.json();
    window.location.assign(data.session.url);
  } else {
    // @TODO: display error layer
  }
}

/**
 * API Call chang subscription Schedule
 * @TODO: Return noch abafangen:
 * - im erfolgsfall success layer und payment data neu ermitteln / store update
 * - im Fehlerfalle hinweis layer
 * @param paymentData
 * @param aboType
 * @param callBackPaymentData
 * @param successNoteHandler
 * @param setSuccessNoteTextHandler
 * @returns {Promise<void>}
 */
async function changeAbo(
  paymentData,
  aboType,
  callBackPaymentData,
  successNoteHandler,
  setSuccessNoteTextHandler,
) {
  const otherProduct = getOtherProductName(aboType);
  const response = await fetch(`${apiUrl}stripe_update_abo`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${Auth.getToken()}`,
    },
  });

  if (response.ok) {
    // const data = await response.json();
    callBackPaymentData();
    setSuccessNoteTextHandler(`Du hast zum ${otherProduct} gewechselt`);
    successNoteHandler();
  } else {
    // @TODO: display error layer
  }
}

async function cancelSchedule(
  paymentData,
  callBackPaymentData,
  successNoteHandler,
  setSuccessNoteTextHandler,
) {
  const response = await fetch(`${apiUrl}stripe_schedule_cancel`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${Auth.getToken()}`,
    },
  });
  if (response.ok) {
    // const data = await response.json();
    callBackPaymentData();
    setSuccessNoteTextHandler("Dein Abowechsel wurde zurückgezogen");
    successNoteHandler();
  } else {
    // @TODO: display error layer
  }
}

/**
 * open the stripe customer portal
 * @param customerId
 * @returns {Promise<void>}
 */
async function openStripePortal(customerId) {
  const stripeUserId = { customerId };
  const response = await fetch(`${apiUrl}stripe_portal`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${Auth.getToken()}`,
    },
    body: JSON.stringify(stripeUserId),
  });
  if (response.ok) {
    const data = await response.json();
    window.location.assign(data.session.url);
  } else {
    // @TODO: display error layer
  }
}

function BookedProductButtons({ paymentData, onButtonClick, aboType }) {
  const { hasBoughtFutureSubscription } =
    paymentData.payment_provider_customer_data;

  const isMonthly = aboType === ABO_TYPES.MONTHLY;
  const productNameToSwitchTo = isMonthly
    ? productNameYearly
    : productNameMonthly;

  return (
    <div className="flex">
      <button
        className="fg-btn fg-btn-secondary mt-4 self-start"
        onClick={() => {
          openStripePortal(paymentData.payment_provider_customer_id);
        }}
      >
        Zahlungseinstellungen & Kündigung
      </button>{" "}
      {hasBoughtFutureSubscription ? (
        <span className="pl-3">
          <button
            className={classList({
              "fg-btn mt-4": true,
              "fg-btn-primary": !isMonthly,
              "fg-btn-tertiary": isMonthly,
            })}
            onClick={onButtonClick}
          >
            Doch nicht wechseln
          </button>
        </span>
      ) : (
        <span className="pl-3">
          <button
            className={classList({
              "fg-btn mt-4": true,
              "fg-btn-primary": isMonthly,
              "fg-btn-tertiary": !isMonthly,
            })}
            onClick={onButtonClick}
          >
            zum {productNameToSwitchTo} wechseln
          </button>
          {productNameToSwitchTo === productNameYearly && (
            <span className="block py-2.5 text-xs text-gray-20">
              {saveCostsYearlyPackage} € pro Jahr günstiger
            </span>
          )}
        </span>
      )}
    </div>
  );
}

function ClassicAboButtons({ paymentData, aboCancelled }) {
  return (
    <div>
      <div className="bg-gray-80 mt-5 rounded p-5.5">
        <div className="font-bold">Preisinfo:</div>
        Wir haben zum 1. Oktober 2023 unsere Preise angepasst. Du profitierst
        weiterhin vom bisherige Preis von 4,99 € im Monat und behältst diesen
        bis September 2025. Ab dem 12. Oktober 2025 gelten auch für dich die
        neuen Preise. Ab dem 13. September 2025 kannst du zum Jahresabo
        wechseln, wenn du das möchtest.
      </div>
      {!aboCancelled && (
        <button
          className="fg-btn fg-btn-secondary mt-4 self-start"
          onClick={() => {
            openStripePortal(paymentData.payment_provider_customer_id);
          }}
        >
          Zahlungseinstellungen & Kündigung
        </button>
      )}
    </div>
  );
}

function TrialButtons({
  showNoticeForAboSelection,
  onButtonClick,
  buttonDisabled,
}) {
  return (
    <div className="flex">
      <button
        disabled={buttonDisabled}
        className="fg-btn fg-btn-primary mt-4"
        onClick={onButtonClick}
      >
        Abo Optionen anzeigen
      </button>
      {showNoticeForAboSelection && (
        <div className="flex basis-64 mt-6 ml-4 text-xs">
          Du kannst ein Abo ab 30 Tagen vor Ende der kostenfreien Nutzung wählen
        </div>
      )}
    </div>
  );
}

/**
 * main content abo management
 */
export function StripeSubscription(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hasSuccessNote, setSuccessNote] = useState(false);
  const [hasErrorNote, setErrorNote] = useState(false);
  const [noteMessage, setNoteMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { paymentData } = props;

  const showProductLayerFromTimeLine = searchParams.get("showProducts");
  const bookedProductInfo = searchParams.get("bookedProductName");

  const daysTillTrialEnd = paymentData.end_date_free
    ? calculateEndDateFree(paymentData.end_date_free)
    : 30; // <-- if end_date_free empty

  const isTrialOver = daysTillTrialEnd < 0;

  const aboCancelled = paymentData.abo_end_date;

  // Ref, Sonderstatus whatever...
  const payStateFree =
    !paymentData.payment_is_active &&
    isPayStateFree(paymentData.paystate_free) &&
    !aboCancelled;

  // Standard Trial (normalerweise 30 Tage)
  const payStateFreeTrial =
    !paymentData.payment_is_active &&
    !isPayStateFree(paymentData.paystate_free) &&
    !aboCancelled;

  const nextPayDate =
    paymentData && paymentData.next_billing_date
      ? DateTime.fromISO(paymentData.next_billing_date)
      : getFirstPayDateObj(paymentData.end_date_free);

  const formattedNextPayDate = nextPayDate.toFormat("dd. MMMM yyyy");

  const formattedTrialEndDate = DateTime.fromISO(
    paymentData.end_date_free,
  ).toFormat("dd. MMMM yyyy");

  const bookedProductName = getBookedProductName(paymentData);
  const bookedProductPrice = getBookedProductPrice(paymentData);
  const formattedProductPrice = (
    Number(bookedProductPrice) / 100
  ).toLocaleString();

  const hasBookedProduct =
    !payStateFree && bookedProductName !== "" && !aboCancelled;

  const hasClassicAboProduct =
    paymentData.payment_is_active && bookedProductName === "";

  const hasActiveAboWithTrial =
    daysTillTrialEnd >= 0 && bookedProductName !== "" && !aboCancelled;

  const aboType =
    paymentData.payment_provider_customer_data?.product === "Monatsabo"
      ? ABO_TYPES.MONTHLY
      : ABO_TYPES.YEARLY;

  const openModal = () => {
    setIsOpen(true);
  };

  if (showProductLayerFromTimeLine && !modalIsOpen) {
    setIsOpen(true);
  }

  if (bookedProductInfo && !hasSuccessNote) {
    setNoteMessage(
      `Du hast erfolgreich das Freigeist ${bookedProductInfo} gebucht.`,
    );
    setSuccessNote(true);
    // we have to delete the parameter, otherwise the
    // closing the success note hasSucceesNote is set to false
    // and the successNote layer is displayed again
    setSearchParams({}, { replace: true });
  }

  const closeModal = () => {
    setIsOpen(false);
    if (showProductLayerFromTimeLine) {
      navigate(timelineTarget);
    }
  };

  const showSuccessNote = () => {
    setSuccessNote(true);
  };
  const closeSuccessNote = () => {
    setSuccessNote(false);
    setNoteMessage("");
  };

  const closeErrorNote = () => {
    setErrorNote(false);
  };

  const successNote = hasSuccessNote ? (
    <ScreenNote
      type="success"
      message={noteMessage}
      closeCallback={closeSuccessNote}
      onAnimationEndClose={closeSuccessNote}
    />
  ) : null;

  const errorNote = hasErrorNote ? (
    <ScreenNote
      type="error"
      message="<strong>Das hat leider nicht geklappt</strong><br/>
Es kam zu einem technischen Fehler. Das Freigeist-Team wurde über das Problem informiert, kümmert sich darum und wird sich bei dir melden."
      closeCallback={closeErrorNote}
      onAnimationEndClose=""
    />
  ) : null;

  return (
    <div className="tw-pf relative aboForm">
      {successNote}
      {errorNote}
      {aboCancelled && (
        <div className="absolute -left-2.5 -top-3 rounded-xl py-0.5 px-2.5 bg-[#CC5959] text-gray-100 text-xs">
          gekündigt
        </div>
      )}
      <div className="modal-wrapper" id="create-template-modal-wrapper">
        <AboModal
          isOpen={modalIsOpen}
          hasBookedProduct={hasBookedProduct}
          paymentData={paymentData}
          aboType={aboType}
          isTrialOver={isTrialOver}
          formattedTrialEndDate={formattedTrialEndDate}
          onProductSelected={startCheckoutSession}
          onAbort={() => {
            cancelSchedule(
              paymentData,
              props.getPaymentSettings,
              showSuccessNote,
              setNoteMessage,
            );
            closeModal();
          }}
          onSubscriptionChange={() => {
            changeAbo(
              paymentData,
              aboType,
              props.getPaymentSettings,
              showSuccessNote,
              setNoteMessage,
            );
            closeModal();
          }}
          closeModal={() => {
            closeModal();
          }}
        />
      </div>
      <AboBox
        paymentData={paymentData}
        aboType={aboType}
        hasClassicAboProduct={hasClassicAboProduct}
        payStateFree={payStateFree}
        hasBookedProduct={hasBookedProduct}
        bookedProductName={bookedProductName}
        formattedProductPrice={formattedProductPrice}
        payStateFreeTrial={payStateFreeTrial}
        isTrialOver={isTrialOver}
        formattedNextPayDate={formattedNextPayDate}
        formattedTrialEndDate={formattedTrialEndDate}
        aboCancelled={aboCancelled}
        hasActiveAboWithTrial={hasActiveAboWithTrial}
      />
      {hasClassicAboProduct && (
        <ClassicAboButtons
          paymentData={paymentData}
          aboCancelled={aboCancelled}
        />
      )}
      {(payStateFreeTrial || payStateFree) && (
        <TrialButtons
          showNoticeForAboSelection={
            payStateFree && daysTillTrialEnd > diffDaysShowPaymentInformation
          }
          onButtonClick={openModal}
          buttonDisabled={
            payStateFree && daysTillTrialEnd > diffDaysShowPaymentInformation
          }
        />
      )}
      {hasBookedProduct && !aboCancelled && (
        <BookedProductButtons
          paymentData={paymentData}
          onButtonClick={openModal}
          aboType={aboType}
        />
      )}
      {aboCancelled && (
        <button
          className="fg-btn fg-btn-primary mt-4"
          onClick={() => {
            openStripePortal(paymentData.payment_provider_customer_id);
          }}
        >
          Kündigung wiederrufen
        </button>
      )}
    </div>
  );
}
