import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import type { CSSProperties, HTMLProps } from "react";

const sizerStyles: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  visibility: "hidden",
  height: 0,
  overflow: "scroll",
  whiteSpace: "pre",
};

const textWidthAffectingStyleProps = [
  "font-size",
  "font-family",
  "font-weight",
  "font-style",
  "letter-spacing",
  "text-transform",
  "box-sizing",
  "padding-left",
  "padding-right",
  "border-right-width",
  "border-left-width",
];

/*
 * Size an input based on measuring a hidden <div> and applying that size
 * to the input.
 * Adapted from https://github.com/JedWatson/react-input-autosize/blob/master/src/AutosizeInput.js
 * which is not updated for React 18 (as of Auf 24 2023)
 * */

type Props = HTMLProps<HTMLInputElement> & { minWidth?: number };

const AutosizeInput = forwardRef((props: Props, ref) => {
  const {
    defaultValue,
    value,
    placeholder,
    minWidth = 100,
    onChange,
    ...restProps
  } = props;
  const hasDefaultValue = typeof defaultValue === "string";
  const initValue = defaultValue ?? value ?? "";
  const [inputValue, setInputValue] =
    useState<HTMLProps<HTMLInputElement>["value"]>(initValue);
  const [inputWidth, setInputWidth] = useState(minWidth);
  const sizerRef = useRef<HTMLDivElement>(null);

  // share reference for forwardedRef and local ref for input used in this component
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  useEffect(
    () => {
      if (inputRef.current && sizerRef.current) {
        // copy over input styles that might affect sizer width
        const inputStyles = window.getComputedStyle(inputRef.current);
        const sizerStyle = sizerRef.current.style;
        textWidthAffectingStyleProps.forEach((styleProp) => {
          const val = inputStyles.getPropertyValue(styleProp);
          sizerStyle.setProperty(styleProp, val);
        });

        // measure the sizer element and set width on input
        const newInputWidth = sizerRef.current.scrollWidth + 2;

        setInputWidth(newInputWidth);
      }
    } /* actually run this on every render since we do not know 100% which props might change the size */,
  );

  return (
    <>
      <input
        {...(hasDefaultValue ? { defaultValue } : { value })}
        placeholder={placeholder}
        style={{ width: inputWidth }}
        ref={inputRef}
        onChange={(e) => {
          setInputValue(e.target.value);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        {...restProps}
      />
      <div className="autosize-input-sizer" ref={sizerRef} style={sizerStyles}>
        {String(inputValue).length > 0 ? inputValue : placeholder}
      </div>
    </>
  );
});

export { AutosizeInput };
