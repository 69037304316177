import { Component } from "react";
import Auth from "../../../../common/services/Auth";
import SchoolTypeStateBox from "../../../../common/components/generic/schoolTypeStateBox/SchoolTypeStateBox";

export default class AddSchoolForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.prefill,
    };
    this.setSchoolValue = this.setSchoolValue.bind(this);
    this.setStreetValue = this.setStreetValue.bind(this);
    this.setNrValue = this.setNrValue.bind(this);
    this.setZipValue = this.setZipValue.bind(this);
    this.setLocationValue = this.setLocationValue.bind(this);
    this.setRegionValue = this.setRegionValue.bind(this);
    this.setSchooltypeValue = this.setSchooltypeValue.bind(this);
    this.setRefValue = this.setRefValue.bind(this);
    this.setDataPolicy = this.setDataPolicy.bind(this);
    this.isFormFilled = this.isFormFilled.bind(this);
    this.nextSection = this.nextSection.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setSchoolValue(e) {
    this.setState({
      school: e.target.value,
    });
  }

  setStreetValue(e) {
    this.setState({
      street: e.target.value,
    });
  }

  setNrValue(e) {
    this.setState({
      nr: e.target.value,
    });
  }

  setZipValue(e) {
    this.setState({
      zip: e.target.value,
    });
  }

  setLocationValue(e) {
    this.setState({
      location: e.target.value,
    });
  }

  setRegionValue(option) {
    this.setState({
      region: option.value,
      region_label: option.label,
    });
  }

  setSchooltypeValue(option) {
    this.setState({
      schooltype: option.value,
      schooltype_label: option.label,
    });
  }

  setRefValue(e) {
    let checked = false;
    const { target } = e;
    if (target.checked) {
      checked = true;
    } else {
      checked = false;
    }
    this.props.updateSchoolRef(checked);
  }

  setDataPolicy(e) {
    let checked = false;
    const { target } = e;
    if (target.checked) {
      checked = true;
    } else {
      checked = false;
    }
    this.setState({
      ref: checked,
    });
  }

  /**
   * create schoolyear next section
   * pass data to hoc for create schoolyear
   */
  nextSection() {
    // console.log(this.props);

    const data = this.state;
    data.user_id = Auth.getUserId();
    data.schoolyear = this.props.schoolyear;
    data.is_active = true;
    data.register_state = 1; // bereits registriert
    data.change_year = true;
    this.props.nextSectionSchoolyear(data);
  }

  /**
   * checks if needed fields exits
   */
  isFormFilled() {
    let emptys = 0;
    const fields = [
      "school",
      "street",
      "nr",
      "zip",
      "location",
      "region",
      "region_label",
    ];
    fields.forEach((elem) => {
      if (!this.state[elem]) {
        emptys += 1;
      }
    });
    return emptys === 0;
  }

  render() {
    return (
      <div id="school" className="">
        <div className="form-wrapper">
          <div className="row">
            <div className="field-group">
              <label htmlFor="school">Name der Schule</label>
              <input
                id="school"
                type="text"
                name="school"
                value={this.state.school}
                placeholder="z.B. Mustermann Schule"
                onChange={(e) => {
                  this.setSchoolValue(e);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="field-group">
              <div className="col-street">
                <label htmlFor="street">Straße</label>
                <input
                  id="street"
                  className="street_field"
                  type="text"
                  name="street"
                  value={this.state.street}
                  placeholder="Musterstraße"
                  onChange={(e) => {
                    this.setStreetValue(e);
                  }}
                />
              </div>
              <div className="col-street-nr">
                <label htmlFor="street-nr">Nr.</label>
                <input
                  id="street_nr"
                  className="street_field_nr"
                  type="text"
                  name="street_nr"
                  value={this.state.nr}
                  placeholder="123"
                  onChange={(e) => {
                    this.setNrValue(e);
                  }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>

          <div className="row">
            <div className="field-group">
              <div className="col-zip">
                <label htmlFor="zip">PLZ</label>
                <input
                  id="zip"
                  className="zip_field"
                  type="text"
                  name="zip"
                  value={this.state.zip}
                  placeholder="12345"
                  onChange={(e) => {
                    this.setZipValue(e);
                  }}
                />
              </div>
              <div className="col-location">
                <label htmlFor="location">Ort</label>
                <input
                  id="location"
                  className="location_field"
                  type="text"
                  name="location"
                  placeholder="Musterstadt"
                  value={this.state.location}
                  onChange={(e) => {
                    this.setLocationValue(e);
                  }}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>
        </div>

        <SchoolTypeStateBox
          context="newSchoolYear"
          schoolData={this.state}
          onChangeRegion={this.setRegionValue}
          onChangeSchoolType={this.setSchooltypeValue}
        />

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back" />
            <div className="next">
              <button
                className="btn btn-step"
                onClick={() => this.nextSection()}
              >
                Zu den Fächern
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}
