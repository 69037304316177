import { useState, useEffect } from "react";
import { SelectableGroup } from "react-selectable-fast";
import { useParams, useSearchParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import {
  draw_timeline,
  getTimeRows,
} from "../../../../../common/components/sequences/Utils";
import SortableTimeItems from "./SortableTimeItems";
import SelectableRow from "./TimeRow";
import {
  type Slot,
  type LabelValue,
  type ArticulationBlock,
} from "../../../../../features/sequence/types";
import { selectSettings } from "../../../../../features/settings/settings-slice";
import {
  selectSchoolRegion,
  selectSequenceDetail,
} from "../../../../combined-selectors/index";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { type RootState } from "../../../../store";
import { api } from "../../../../../services/api";
import { selectCurrentSchoolyear } from "../../../../../features/current-schoolyear/current-schoolyear-slice";
import { uniqueId } from "../../../../../common/components/sequence_calendar/Utils";

type ChangeType = "text" | "title";

const selectProps = createSelector(
  [
    selectSequenceDetail,
    selectSettings,
    (state: RootState): { material_tags: Array<LabelValue> } => state.taglist,
    selectCurrentSchoolyear,
    selectSchoolRegion,
  ],
  (sequenceDetail, settings, taglist, schoolyear, region) => ({
    timeslots: sequenceDetail.timeslots,
    dayslotid: sequenceDetail.dayslotid,
    active_timeslot: sequenceDetail.active_timeslot,
    slot_duration: sequenceDetail.slot_duration,
    editor_idx: sequenceDetail.editor_idx,
    settings,
    taglist,
    schoolyear,
    region,
  }),
);

type Props = ReturnType<typeof selectProps>;

type SelectedItem = {
  node: HTMLElement;
  context: { selectable: { clearSelection: () => void } };
};

const SlotHeight = 100; // 5 * 20px;
const timeslotDurationInMinutes = 5; // 5 min

function setPropsOnTimeBlock(
  timeslots: Slot[],
  dayslotid: string,
  paneIndex: number,
  update: (block: ArticulationBlock) => ArticulationBlock,
): Slot[] {
  return timeslots.map((el) =>
    String(el.id) === dayslotid
      ? {
          ...el,
          data: el.data.map((pane, index) =>
            index === paneIndex ? update(pane) : pane,
          ),
        }
      : el,
  );
}

// this state will reset to false after the timeout
function useResettableState(timeout: number): [boolean, () => void] {
  const [newPaneCreated, setNewPaneCreated] = useState<boolean>(false);
  useEffect(() => {
    if (newPaneCreated) {
      setTimeout(() => {
        setNewPaneCreated(false);
      }, timeout);
    }
  }, [newPaneCreated]);
  return [newPaneCreated, () => setNewPaneCreated(true)];
}

function TimeslotsComponent() {
  const [newPaneCreated, setNewPaneCreated] = useResettableState(1000);
  const [queryParams] = useSearchParams();
  const { sequenceId = "" } = useParams();
  const dispatch = useAppDispatch();
  // if ueId not exists in queryString - ueId is null
  // example request: sequenzen/{sequenceId}?ueId=626a97b0e42e43f9ae6faf5d
  const requestedUeId = queryParams.get("ueId");
  const props: Props = useAppSelector((rootState: RootState) =>
    selectProps(rootState, { sequenceId, ueId: requestedUeId }),
  );

  const [putSequenceTrigger] = api.endpoints.putSequence.useMutation();
  const handleDuringSelection = (selectedItems: Array<SelectedItem>) => {
    const item = selectedItems[0];
    if (item) {
      const duration = Math.round(
        selectedItems.length * Number(timeslotDurationInMinutes),
      );
      const html = `<span classname="duringSelectionText">${duration} Minuten</span>`;
      if (item.node.firstElementChild) {
        item.node.firstElementChild.innerHTML = html;
      }
    }
  };

  /**
   * callback
   * @param sorted_timeslots
   */
  const handleReOrderPanes = (newBlocks: Slot["data"]) => {
    const newTimeslots = props.timeslots.map((el) =>
      String(el.id) === props.dayslotid ? { ...el, data: newBlocks } : el,
    );
    putSequenceTrigger({
      sequenceId: Number(sequenceId),
      schoolyear: props.schoolyear,
      region: props.region,
      timeslots: newTimeslots,
    });
  };

  /**
   * add new slot item on selected time line
   * @param selectedItems
   */
  const handleSelection = (selectedItems: SelectedItem[], id: string) => {
    if (selectedItems.length > 0) {
      // make sure newPaneCreated is set to true before new element renders
      // otherwise autoFocus won't work
      setNewPaneCreated();

      // neues objekt
      const newTimeslots = props.timeslots.map((el) => {
        if (el.id === id) {
          const newBlock: ArticulationBlock = {
            id: uniqueId(),
            duration: selectedItems.length * timeslotDurationInMinutes,
            title: "",
            text: "",
          };
          return { ...el, data: [...el.data, newBlock] };
        }

        return el;
      });

      putSequenceTrigger({
        sequenceId: Number(sequenceId),
        schoolyear: props.schoolyear,
        region: props.region,
        timeslots: newTimeslots,
      });

      selectedItems.forEach((item) => {
        item.context.selectable.clearSelection();
      });
      if (selectedItems[0] && selectedItems[0].node.firstElementChild) {
        selectedItems[0].node.firstElementChild.innerHTML = "";
      }
    }
  };

  const handleDeleteBlockClick = (paneIndex: number) => {
    const newTimeslots: Slot[] = props.timeslots.map((el) =>
      String(el.id) === props.dayslotid
        ? { ...el, data: el.data.filter((_, index) => paneIndex !== index) }
        : el,
    );

    putSequenceTrigger({
      sequenceId: Number(sequenceId),
      schoolyear: props.schoolyear,
      region: props.region,
      timeslots: newTimeslots,
    });
  };

  /**
   * calculate the real exists durations.
   * beim verschieben von tagen können diese unterschiedlich sein
   * @private
   */
  const _calcCurrentSlotDurations = () => {
    const { slot_duration } = props;
    const real_duration = props.active_timeslot.reduce(
      (count, elem) => count + Number(elem.duration),
      0,
    );
    return real_duration > slot_duration ? real_duration : slot_duration;
  };

  const slot_duration = _calcCurrentSlotDurations();
  const idx_update_key = `${props.dayslotid}-${props.editor_idx}`;

  const timeline = draw_timeline(5, slot_duration); // array for draw timeline
  const timeRows = getTimeRows(5, slot_duration);
  const material_preselect =
    props.taglist.material_tags && props.taglist.material_tags.length > 0
      ? props.taglist.material_tags
      : [];

  return (
    <div className="timeslots-component">
      <div className="timeline-wrapper">
        <div id="timeline-left">
          <ol id="rule">
            {timeline.map((elem) => {
              const time_key = `tl${elem.pos}`;
              const pos = elem.pos % 5 === 0 ? elem.pos : "";
              return (
                <li key={time_key} className={elem.marker} data-pos={elem.pos}>
                  <span className="unit-desc">{pos}</span>
                </li>
              );
            })}
          </ol>
        </div>

        <div id="timeline-items">
          <SortableTimeItems
            key={idx_update_key}
            panekey={idx_update_key}
            timeBlocks={props.active_timeslot}
            settings={props.settings}
            onTextChange={(
              paneIndex: number,
              changeType: ChangeType,
              val: string,
            ) => {
              if (changeType === "title") {
                dispatch(
                  api.util.updateQueryData(
                    "patchSequenceDetail",
                    {
                      id: sequenceId,
                      schoolyear: props.schoolyear,
                      region: props.region,
                    },
                    (sequenceData) => {
                      sequenceData.sequence.timeslots = setPropsOnTimeBlock(
                        props.timeslots,
                        props.dayslotid,
                        paneIndex,
                        (block) => ({
                          ...block,
                          [changeType]: val,
                        }),
                      );
                    },
                  ),
                );
              }
            }}
            onTextBlur={(
              paneIndex: number,
              changeType: ChangeType,
              text: string,
            ) => {
              const newTimeslots = setPropsOnTimeBlock(
                props.timeslots,
                props.dayslotid,
                paneIndex,
                (block) => ({ ...block, [changeType]: text }),
              );

              putSequenceTrigger({
                sequenceId: Number(sequenceId),
                schoolyear: props.schoolyear,
                region: props.region,
                timeslots: newTimeslots,
              });
            }}
            slotHeight={SlotHeight}
            timeslotDurationInMinutes={timeslotDurationInMinutes}
            newPaneCreated={newPaneCreated}
            handleOnResize={(id: number, duration: number) => {
              const newTimeslots = props.timeslots.map((el) =>
                String(el.id) === props.dayslotid
                  ? {
                      ...el,
                      data: el.data.map((pane, paneIndex) =>
                        id === paneIndex ? { ...pane, duration } : pane,
                      ),
                    }
                  : el,
              );

              putSequenceTrigger({
                sequenceId: Number(sequenceId),
                schoolyear: props.schoolyear,
                region: props.region,
                timeslots: newTimeslots,
              });
            }}
            handleOnReOrder={handleReOrderPanes}
            handleDeleteClick={handleDeleteBlockClick}
            update_material={(paneIndex: number, tags: LabelValue[]) => {
              const newTimeslots = props.timeslots.map((el) =>
                String(el.id) === props.dayslotid
                  ? {
                      ...el,
                      data: el.data.map((pane, index) =>
                        index === paneIndex
                          ? { ...pane, material: tags }
                          : pane,
                      ),
                    }
                  : el,
              );

              putSequenceTrigger({
                sequenceId: Number(sequenceId),
                schoolyear: props.schoolyear,
                region: props.region,
                timeslots: newTimeslots,
              });
            }}
            material_preselect={material_preselect}
          />

          <SelectableGroup
            className="main-select-duration"
            clickClassName="tick"
            enableDeselect
            deselectOnEsc={false}
            allowClickWithoutSelected={false}
            duringSelection={handleDuringSelection}
            onSelectionFinish={(keys: SelectedItem[]) =>
              handleSelection(keys, props.dayslotid)
            }
          >
            <div className="row-items">
              {timeRows.map((item, i) => (
                <SelectableRow
                  key={`str-${item.minute}${i}`}
                  minute={item.minute}
                />
              ))}
            </div>
          </SelectableGroup>
        </div>
      </div>
    </div>
  );
}

export default TimeslotsComponent;
