import Modal from "react-modal";
import { DateTime } from "luxon";
import IconCheck from "../../../../assets/images/svg/check.svg";
import { classList } from "../../../utils/classList";
import { ABO_TYPES, getProductName, getOtherProductName } from "./util";

function ModalScheduleAbortDescription({
  paymentData,
  aboType,
  onAbort,
  closeModal,
}) {
  const aboData = paymentData.payment_provider_customer_data;
  const newPeriodStartDate = DateTime.fromSeconds(aboData.current_period_end)
    .plus({ days: 1 })
    .toFormat("dd. MMMM yyyy");

  const isMonthly = aboType === ABO_TYPES.MONTHLY;
  const priceDesc = isMonthly
    ? "für 99,99 € pro Jahr und sparst damit jährlich 19,89 €."
    : "für 9,99 € im Monat.";

  return (
    <div className="max-w-[430px] bg-gray-100 rounded-lg">
      <div className="py-6 px-8 text-sm">
        <div className="text-xl font-bold">Doch nicht wechseln</div>
        <div className="pt-2 pb-10">
          Am dem <strong>{newPeriodStartDate}</strong> wechselst du zum{" "}
          <strong>{getOtherProductName(aboType)}</strong>, {priceDesc}
        </div>{" "}
        <div>
          Wenn du doch gerne weiterhin das {getProductName(aboType)} nutzen
          möchtest, kannst du den Abowechsel zurückziehen.
        </div>
        <div className="flex">
          <button
            className={classList({
              "fg-btn mt-4": true,
              "fg-btn-primary": !isMonthly,
              "fg-btn-secondary": isMonthly,
            })}
            onClick={onAbort}
          >
            Abowechsel zurückziehen
          </button>
          <span className="pl-3">
            <button
              className="fg-btn fg-btn-tertiary mt-4"
              onClick={closeModal}
            >
              Abbrechen
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

function ModalAboChangeDescription({
  paymentData,
  isTrialOver,
  aboType,
  onSubscriptionChange,
  closeModal,
}) {
  const aboData = paymentData.payment_provider_customer_data;
  const periodEndDate = DateTime.fromSeconds(aboData.current_period_end);
  const newPeriodStartDate = periodEndDate.plus({ days: 1 });

  const isMonthly = aboType === ABO_TYPES.MONTHLY;
  const priceDesc = isMonthly
    ? "für 99,99 € pro Jahr und sparst damit jährlich 19,89 €."
    : "für 9,99 € im Monat.";

  const otherProductTitle = getOtherProductName(aboType);

  const btnClass = isMonthly ? "fg-btn-primary" : "fg-btn-tertiary";

  return (
    <div className="max-w-[420px] bg-gray-100 rounded-lg">
      <div className="py-6 px-8 text-sm">
        <div className="text-xl font-bold">
          Zum {otherProductTitle} wechseln
        </div>
        <div className="pt-2">
          Wenn du den Abowechsel durchführst passiert folgendes
        </div>
        <ul
          className={`mt-4 payment-bullet-list ${
            isMonthly
              ? "payment-bullet-list-monthly"
              : "payment-bullet-list-yearly"
          }`}
        >
          <li className="pb-10">
            Bis zum <strong>{periodEndDate.toFormat("dd. MMMM yyyy")}</strong>{" "}
            nutzt du Freigeist
            {isTrialOver ? (
              <span>
                über dein bereits bezahltes{" "}
                <strong>{getProductName(aboType)}</strong>.
              </span>
            ) : (
              <span>weiter kostenlos.</span>
            )}
          </li>
          <li>
            Ab dem{" "}
            <strong>{newPeriodStartDate.toFormat("dd. MMMM yyyy")}</strong>{" "}
            nutzt du das Freigeist <strong>{otherProductTitle}</strong>,{" "}
            {priceDesc}
          </li>
        </ul>
        <div className="mt-2 flex gap-3">
          <button
            className={`fg-btn ${btnClass} mt-4`}
            onClick={onSubscriptionChange}
          >
            Jetzt Abo wechseln
          </button>
          {aboType === ABO_TYPES.YEARLY ? (
            <button
              className="fg-btn fg-btn-secondary mt-4"
              onClick={closeModal}
            >
              Abbrechen
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

/**
 * modal layer content for prodcut/pricing boxes
 * @param onProductSelected
 * @param authentication
 * @param endDateFree
 * @returns {JSX.Element}
 */
function ModalPriceBoxesContent({ onProductSelected, endDateFree }) {
  const productList = [
    {
      title: "Jahresabo",
      productType: "yearly",
      highlighted: true,
      price: "99,99",
      price_desc: "Euro im Jahr",
      price_footnote:
        "Das entpricht 8,33 € im Monat, oder anders gesagt: weniger als 2 Cappucini ☕️",
      bullets: [
        { text: "17% günstiger als das Monatsabo" },
        {
          text: "Keine Kündigungsfrist",
          footnote: "Du kannst bis zur Verlängerung jederzeit kündigen",
        },
        {
          text: "Kosten für Freigeist können als Arbeitsmittel steuerlich angesetzt werden.",
        },
      ],
    },
    {
      title: "Monatsabo",
      productType: "monthly",
      highlighted: false,
      price: "9,99",
      price_desc: "Euro im Monat",
      price_footnote: "",
      bullets: [
        { text: "Flexibel dank der kurzen Laufzeit" },
        {
          text: "Keine Kündigungsfrist",
          footnote: "Du kannst bis zur Verlängerung jederzeit kündigen",
        },
        {
          text: "Kosten für Freigeist können als Arbeitsmittel steuerlich angesetzt werden.",
        },
      ],
    },
  ];

  return (
    <div id="price-options" className="text-center">
      <ul className="list-none my-4 flex flex-col gap-9 justify-center items-center lg:items-stretch lg:flex-row px-5 lg:px-10">
        {productList.map((product) => (
          <li
            key={product.price}
            className="lg:w-[23.5rem] flex flex-col max-w-md relative rounded-lg bg-gray-100 shadow-2xl"
          >
            {product.highlighted && (
              <div className="absolute -top-0.5 -right-0.5 overflow-hidden rounded-tr-lg">
                <div className="pt-12 pb-1.5 px-6 bg-green text-xs rounded-md rotate-45 origin-bottom-right">
                  am <br /> beliebtesten
                </div>
              </div>
            )}
            <div className="pt-5 pb-2 px-4 h-[15rem]">
              <div className="text-xl font-bold">{product.title}</div>
              <div className="mt-11 text-[2.625rem] leading-[3.0625rem] text-green">
                {product.price}
              </div>
              <div className="text-base text-gray-40">{product.price_desc}</div>
              <div className="text-xs mt-3 text-gray-20 w-[17rem] mx-auto">
                {product.price_footnote}
              </div>
            </div>
            <ul className="list-none flex-1 pl-10 pr-4 pt-4 pb-8 space-y-2 text-left bg-gray-20 text-gray-100">
              {product.bullets.map((item) => (
                <li key={item.text} className="relative">
                  <span className="absolute inline-block w-4 left-[-1.5rem] top-2">
                    <IconCheck />
                  </span>
                  {item.text}
                  {item.footnote !== "" && (
                    <span className="text-xs">
                      <br />
                      {item.footnote}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            <div className="bg-gray-20 rounded-b-lg px-4 pb-4">
              <button
                className="fg-btn fg-btn-primary w-full"
                onClick={() => {
                  onProductSelected(product.productType, endDateFree);
                }}
              >
                wählen
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function AboModal({
  isOpen,
  closeModal,
  hasBookedProduct,
  aboType,
  paymentData,
  isTrialOver,
  formattedTrialEndDate,
  onProductSelected,
  onSubscriptionChange,
  onAbort,
}) {
  const hasBoughtFutureSubscription =
    paymentData.payment_provider_customer_data?.hasBoughtFutureSubscription ??
    false;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp
      className="commonModal transparent tw-pf"
      contentLabel="Produkte"
      style={{
        overlay: {
          backgroundColor: "rgba(242, 242, 242, 0.90)",
          backdropFilter: "blur(4px)",
          zIndex: 210,
        },
        content: {
          boxShadow: "none",
        },
      }}
    >
      <div className="commonModalContent template-modal w-auto">
        {hasBookedProduct ? (
          <div>
            {hasBoughtFutureSubscription ? (
              <div>
                <ModalScheduleAbortDescription
                  paymentData={paymentData}
                  aboType={aboType}
                  onAbort={onAbort}
                  closeModal={closeModal}
                />
              </div>
            ) : (
              <div>
                <ModalAboChangeDescription
                  paymentData={paymentData}
                  isTrialOver={isTrialOver}
                  aboType={aboType}
                  onSubscriptionChange={onSubscriptionChange}
                  closeModal={closeModal}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="products">
            <h3 className="text-center text-3xl">Deine Optionen</h3>
            <div className="flex">
              <ModalPriceBoxesContent
                onProductSelected={onProductSelected}
                endDateFree={paymentData.end_date_free}
              />
            </div>
            {!isTrialOver && (
              <div className="text-xl max-w-[600px] m-auto pt-4">
                Bis zum {formattedTrialEndDate} nutzt du weiterhin den
                kostenlosen Testzeitraum, auch wenn du jetzt ein Abo abschließt
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
