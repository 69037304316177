import { Component } from "react";
import { uniqueId } from "../../sequence_calendar/Utils";

class TimeTableItem extends Component {
  renderAddHour() {
    return (
      <div data-day="" className="item-inner">
        <div className="add_row_item_wrapper">
          <span className="add">+</span>
          <span className="title">
            Stunde
            <br /> hinzufügen
          </span>
        </div>
      </div>
    );
  }

  renderABitem() {
    const tt = this.props.timetable;
    const css_class = "fill";

    let a_classname = "Leerstunde";
    let a_subject = "ändern";
    let a_style = {};

    let b_classname = "Leerstunde";
    let b_subject = "ändern";
    let b_style = {};

    if (tt && tt.classes_ab_weeks && tt.classes_ab_weeks.A) {
      a_subject = tt.classes_ab_weeks.A.subjectTitle;
      a_classname = tt.classes_ab_weeks.A.className;
      a_style = {
        borderLeft: `5px solid ${tt.classes_ab_weeks.A.subjectColor}`,
      };
    }

    if (tt && tt.classes_ab_weeks && tt.classes_ab_weeks.B) {
      b_subject = tt.classes_ab_weeks.B.subjectTitle;
      b_classname = tt.classes_ab_weeks.B.className;
      b_style = {
        borderLeft: `5px solid ${tt.classes_ab_weeks.B.subjectColor}`,
      };
    }

    return (
      <div data-day="" className={`item-inner ${css_class}`}>
        <div className="item-inner-wrapper half">
          <div className="left" style={a_style}>
            <div className="placeholder">
              <div className="title">{a_classname}</div>
              <div className="subject">{a_subject}</div>
            </div>
            <div className="hover-text">ändern</div>
          </div>
          <div className="right" style={b_style}>
            <div className="placeholder">
              <div className="title">{b_classname}</div>
              <div className="subject">{b_subject}</div>
            </div>
            <div className="hover-text">ändern</div>
          </div>
        </div>
      </div>
    );
  }

  renderItem() {
    // check if for current item an timetable entry exists.
    const tt = this.props.timetable;

    let classname = "Leerstunde";
    let subject = "ändern";
    let css_class = "";
    let style = {};

    if (tt && tt.className && tt.subjectTitle) {
      subject = tt.subjectTitle;
      classname = tt.className;
      css_class = "fill";
      style = { borderLeft: `5px solid ${tt.subjectColor}` };
    }
    return (
      <div data-day="" className={`item-inner ${css_class}`} style={style}>
        <div className="item-inner-wrapper">
          <div className="placeholder">
            <div className="title">{classname}</div>
            <div className="subject">{subject}</div>
          </div>
          <div className="hover-text">ändern</div>
        </div>
      </div>
    );
  }

  render() {
    const { hour } = this.props;
    let add_css = "";
    if (String(hour) === "-1") {
      add_css = "add_element";
    }

    let subject_id = false;
    let class_id = false;

    let subject_id_b = false;
    let class_id_b = false;

    // fetch class and subject id
    if (
      this.props.timetable &&
      this.props.timetable.classId &&
      this.props.timetable.subjectId
    ) {
      subject_id = this.props.timetable.subjectId;
      class_id = this.props.timetable.classId;
    }

    if (
      this.props.ab_weeks &&
      this.props.timetable &&
      this.props.timetable.classes_ab_weeks
    ) {
      subject_id = false;
      class_id = false;

      if (
        this.props.timetable.classes_ab_weeks.A &&
        this.props.timetable.classes_ab_weeks.A.subjectId
      ) {
        subject_id = this.props.timetable.classes_ab_weeks.A.subjectId;
        class_id = this.props.timetable.classes_ab_weeks.A.classId;
      }
      if (
        this.props.timetable.classes_ab_weeks.B &&
        this.props.timetable.classes_ab_weeks.B.subjectId
      ) {
        subject_id_b = this.props.timetable.classes_ab_weeks.B.subjectId;
        class_id_b = this.props.timetable.classes_ab_weeks.B.classId;
      }
    }

    let render_content;
    if (String(hour) === "-1") {
      render_content = this.renderAddHour();
    } else if (this.props.ab_weeks && this.props.timetable.classes_ab_weeks) {
      // render_content = this.renderABitem();

      if (
        String(subject_id) === String(subject_id_b) &&
        String(class_id) === String(class_id_b)
      ) {
        render_content = this.renderItem();
      } else {
        render_content = this.renderABitem();
      }
    } else {
      render_content = this.renderItem();
    }

    return (
      <div
        className={`timetable-item ${add_css}`}
        onClick={() => {
          this.props.openModal(
            this.props.hour,
            this.props.day,
            class_id,
            subject_id,
            class_id_b,
            subject_id_b,
          );
        }}
        key={uniqueId()}
      >
        {render_content}
      </div>
    );
  }
}
export default TimeTableItem;
