import { useRef } from "react";
import Dropdown from "react-dropdown";
import { region_countries } from "../../../config/region_contries";
import { school_types } from "../../../config/school_types";

function SchoolTypeStateBox(props) {
  const regionInputRef = useRef();
  const schooltypeInputRef = useRef();
  const defaultTextEmptyField = "Bitte wählen";

  const setRegion = (option) => {
    regionInputRef.current.value = option.value;
    if (props.onChangeRegion) {
      // use component probs callback
      props.onChangeRegion(option);
    }
  };
  const setSchooltype = (option) => {
    schooltypeInputRef.current.value = option.value;
    if (props.onChangeSchoolType) {
      // use component probs callback
      props.onChangeSchoolType(option);
    }
  };

  const description =
    "Anhand deines Bundeslandes stellen wir Ferien und Feiertage für dich ein.";
  const regionsList = region_countries();
  const { region, schooltype } = props.schoolData ?? "andere";
  const schoolList = school_types();
  const contextExtraCssClass = props.context ? props.context : "";
  return (
    <div className="tw-pf">
      <div id="region-schooltype" className={contextExtraCssClass}>
        {props.context === "registration" ||
        props.context === "newSchoolYear" ? (
          <div className="heading step-text">
            <div className="head second">Bundesland und Schulart</div>
            {description}
          </div>
        ) : (
          <div>
            <h2 className="font-bold">Bundesland und Schulart</h2>
            <div className="description">{description}</div>
          </div>
        )}
        <div className="form-wrapper">
          <div className="row">
            <div className="field-group dropdowns box-content">
              <div className="col-region">
                <label>Bundesland</label>
                <Dropdown
                  options={regionsList}
                  onChange={setRegion}
                  value={region}
                  placeholder={defaultTextEmptyField}
                />
                {/* render a hidden field so we can use forms, because react-dropdown uses only divs */}
                <input
                  ref={regionInputRef}
                  type="hidden"
                  name="region"
                  defaultValue={region}
                />
              </div>
              <div className="col-schooltype">
                <label>Schulart (optional)</label>
                <Dropdown
                  options={schoolList}
                  onChange={setSchooltype}
                  value={schooltype}
                  placeholder={defaultTextEmptyField}
                />
                {/* render a hidden field so we can use forms, because react-dropdown uses only divs */}
                <input
                  ref={schooltypeInputRef}
                  type="hidden"
                  name="schooltype"
                  defaultValue={schooltype}
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SchoolTypeStateBox;
