import { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { classList } from "../../../common/utils/classList";
import EllipsisHorizontal from "../../../assets/images/hero-icons/ellipsis-horizontal.svg";

function hasEnoughRoomToTheRight(element) {
  const rect = element.getBoundingClientRect();
  const neededSpace = 200;
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;
  // check if there is enough space between the right border of the element and the viewport
  return rect.right + neededSpace < viewportWidth;
}

export default function CalendarSequenceDialog(props) {
  const [state, setState] = useState({
    show_menu: false,
    canShowToTheRight: true,
  });
  const containerRef = useRef(null);
  const handleShowMenu = useCallback(() => {
    props.setZindexDialog();

    setState({
      show_menu: true,
      canShowToTheRight: hasEnoughRoomToTheRight(containerRef.current),
    });
  }, [props.setZindexDialog, containerRef]);

  const dialogClasses = classList({
    "h-[115px] absolute bottom-0 top-[-25px] z-[9999]": true,
    hidden: !state.show_menu,
    "right-[-0.5rem] translate-x-full": state.canShowToTheRight,
    "left-[-0.5rem] -translate-x-full": !state.canShowToTheRight,
  });

  return (
    <div ref={containerRef} className="tw-pf calendar-sequence-dialog">
      <button onClick={handleShowMenu}>
        <EllipsisHorizontal className="text-green w-6 h-6" />
      </button>
      <div
        className={dialogClasses}
        onMouseLeave={() => {
          setState({ ...state, show_menu: false });
        }}
      >
        <div
          className={classList({
            "flex h-full relative": true,
            "flex-row-reverse": !state.canShowToTheRight,
          })}
        >
          {/* spacer to make mouse hover area bigger so item doesn't close to early */}
          <div
            className={classList({
              "absolute w-8 h-full": true,
              "left-0 translate-x-[-100%]": state.canShowToTheRight,
              "right-0 translate-x-[100%]": !state.canShowToTheRight,
            })}
          />
          <div
            className="relative top-[30px] w-0 h-0 border-solid border-transparent border-r-gray-100 border-t-[7.5px] border-r-[10px] border-b-[7.5px]"
            style={{
              transform: state.canShowToTheRight ? "none" : "scaleX(-1)",
            }}
          />
          <ul className="shadow-2xl bg-gray-100 w-[170px] h-full font-normal">
            <li className="pl-2.5 pt-1.5">
              <Link
                to={`/sequenzen/${props.itemCluster.id}`}
                className="hover:underline"
              >
                öffnen
              </Link>
            </li>
            <li className="pl-2.5 pt-1.5">
              <a className="hover:underline" onClick={props.saveAsTemplate}>
                als Vorlage speichern
              </a>
            </li>
            <li className="pl-2.5 pt-1.5">
              <a className="hover:underline" onClick={props.editItem}>
                umbenennen
              </a>
            </li>
            <li className="pl-2.5 pt-1.5">
              <a
                className="text-red hover:underline"
                onClick={props.deleteItem}
              >
                löschen
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
