import { type Action, createSlice } from "@reduxjs/toolkit";
import {
  sequenceMaterialSlice,
  ueMaterialSlice,
} from "../material/material-slice";
import { type MaterialModel } from "../material/types";

type SequenceState = {
  materialModel: MaterialModel;
  ueMaterialModel: MaterialModel;
};

const initState: SequenceState = {
  materialModel: sequenceMaterialSlice.getInitialState(),
  ueMaterialModel: sequenceMaterialSlice.getInitialState(),
};

const sequenceSlice = createSlice({
  name: "sequences",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    // delegate material actions to specific reducers
    builder
      // You can match a range of action types
      .addMatcher(
        (action: Action) =>
          action.type.startsWith(`${sequenceMaterialSlice.name}/`),
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          const newMaterialModel = sequenceMaterialSlice.reducer(
            state.materialModel,
            action,
          );
          return { ...state, materialModel: newMaterialModel };
        },
      )
      .addMatcher(
        (action: Action) => action.type.startsWith(`${ueMaterialSlice.name}/`),
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          const newUeMaterialModel = ueMaterialSlice.reducer(
            state.ueMaterialModel,
            action,
          );
          return { ...state, ueMaterialModel: newUeMaterialModel };
        },
      );
  },
  selectors: {
    selectSequenceMaterial: (state: SequenceState) => state.materialModel,
    selectUeMaterial: (state: SequenceState) => state.ueMaterialModel,
  },
});

// REDUCER

export default sequenceSlice.reducer;

// SELECTORS
export const { selectSequenceMaterial, selectUeMaterial } =
  sequenceSlice.selectors;
