import { Component } from "react";
import AddPasswordForm from "../../../common/components/auth/password/PasswordForm";
import Auth from "../../../common/services/Auth";
import * as API from "../../../common/constants/ApiRoutes";

class addPassword extends Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
  }

  componentDidMount() {
    if (Auth.isUserAuthenticated()) {
      // user is authenticated -> go to subjects
      this.props.jumpToStep(1);
    }
  }

  /**
   * fill storage in register and go away
   */
  async nextSection() {
    const { props } = this;

    const res = await fetch(API.API_ROUTE_AUTH_REGISTER, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname: props.register_data.firstname,
        lastname: props.register_data.lastname,
        email: props.register_data.email,
        password: props.password,
      }),
    });

    const data = await res.json();
    // set a message
    localStorage.setItem("successMessage", data.message);

    // save the token
    Auth.authenticateUser(data.token);
    Auth.customerData(data.user);

    this.props.onSubmit({ ...data.user, token: data.token });
    this.props.jumpToStep(1);
  }

  prevSection() {}

  render() {
    return (
      <div id="password" className="step step1">
        <div className="step-text">
          <div className="title">Passwort</div>
          Wähle ein Passwort mit dem du dich bei Freigeist anmelden kannst.
          Damit dein Passwort sicher ist, solltes es Kleinbuchstaben,
          Großbuchstaben, Zahlen und Sonderzeichen beinhalten. Du kannst das
          Passwort später jederzeit ändern.
        </div>

        <AddPasswordForm
          prevSection={this.prevSection}
          nextSection={this.nextSection}
        />
      </div>
    );
  }
}
export default addPassword;
