import { DateTime } from "luxon";
import FreigeistLogo from "../../../../assets/images/svg/freigeist-logo-free.svg";
import { getSubscriptionScheduleText } from "./util";
import { classList } from "../../../utils/classList";

/**
 * return the trial end date as tring dd. MMMM e.g 25. September
 * @param trialDate
 * @returns {string}
 */
function getTrialDateString(trialDate) {
  return DateTime.fromISO(trialDate).toFormat("dd. MMMM");
}

function BasicBox({
  logoColorClass,
  title,
  isCancelled,
  price,
  description,
  trialEndIsoDate,
}) {
  return (
    <article className="flex px-5.5 pt-5.5 pb-8 bg-gray-100 rounded">
      <div
        className={`flex items-center justify-center w-32 h-32 rounded ${logoColorClass}`}
      >
        <FreigeistLogo className="w-1/2 text-gray-100" />
      </div>
      <div className="flex basis-[26.6875rem] px-4">
        <div>
          {trialEndIsoDate && (
            <div className="text-xl font-bold pb-1.5 text-gray-50">
              Testzeitraum bis {getTrialDateString(trialEndIsoDate)}
            </div>
          )}
          <h3
            className={classList({
              "text-xl font-bold pb-1.5": true,
              "text-gray-60": isCancelled,
            })}
          >
            Freigeist - {title}
          </h3>
          {price ? (
            <div
              className={classList({
                "line-through text-gray-60": isCancelled,
              })}
            >
              {price.toLocaleString()} €
            </div>
          ) : null}
          <span>{description}</span>
        </div>
      </div>
    </article>
  );
}

export function AboBox({
  paymentData,
  aboType,
  hasClassicAboProduct,
  payStateFree,
  hasBookedProduct,
  bookedProductName,
  formattedProductPrice,
  payStateFreeTrial,
  isTrialOver,
  formattedNextPayDate,
  formattedTrialEndDate,
  aboCancelled,
  hasActiveAboWithTrial,
}) {
  const isMonthlyPackage = bookedProductName === "Monatsabo";

  const hasBoughtFutureSubscription =
    paymentData.payment_provider_customer_data?.hasBoughtFutureSubscription ??
    false;

  if (aboCancelled) {
    return (
      <BasicBox
        logoColorClass="bg-gray-70"
        title={hasClassicAboProduct ? "Monatsabo Klassik" : bookedProductName}
        price={formattedProductPrice}
        isCancelled
        description={
          <>
            Du hast dein Abo gekündigt. Bis zum{" "}
            <strong>{formattedNextPayDate}</strong> kannst du Freigeist
            weiterhin nutzen. Danach endet dein Abo.
          </>
        }
      />
    );
  }

  if (payStateFreeTrial) {
    const descriptionDefaultUser = isTrialOver ? (
      <>
        Dein Testzeitraum ist abgelaufen. Um Freigeist weiter nutzen zu können,
        musst du ein Abo abschließen, oder nachweisen, dass du für die
        kostenfreie Nutzung legitimiert bist.
      </>
    ) : (
      <>
        Dein Testzeitraum geht noch bis zum{" "}
        <strong>{formattedTrialEndDate}</strong>. Um Freigeist danach weiterhin
        nutzen zu können, musst du ein Abo abschließen, oder nachweisen, dass du
        für die <span className="underline">kostenfreie Nutzung</span>{" "}
        legitimiert bist.
      </>
    );

    const freeTrialYears = DateTime.fromISO(paymentData.end_date_free)
      .diffNow("years")
      .as("years");
    const descriptionUserLongTrial = "Du nutzt Freigeist kostenfrei";
    const description =
      freeTrialYears > 10 ? descriptionUserLongTrial : descriptionDefaultUser;

    return (
      <BasicBox
        title="Testzeitraum"
        logoColorClass="bg-gray-70"
        description={description}
      />
    );
  }

  if (payStateFree) {
    const description = isTrialOver ? (
      <>
        Dein Testzeitraum ist abgelaufen. Um Freigeist weiter nutzen zu können,
        musst du ein Abo abschließen, oder nachweisen, dass du für die
        kostenfreie Nutzung legitimiert bist.
      </>
    ) : (
      <>
        Du nutzt Freigeist noch bis zum <strong>{formattedTrialEndDate}</strong>{" "}
        kostenfrei. Um Freigeist danach weiterhin nutzen zu können, musst du ein
        Abo abschließen, oder nachweisen, dass du weiterhin für die{" "}
        <span className="underline">kostenfreie Nutzung</span> legitimiert bist.
      </>
    );
    return (
      <BasicBox
        title="Kostenfreie Nutzung"
        logoColorClass="bg-green"
        description={description}
      />
    );
  }

  // TODO
  if (hasClassicAboProduct && !aboCancelled) {
    return (
      <BasicBox
        title="Monatsabo Klassik"
        logoColorClass="bg-green"
        price={formattedProductPrice || "4.99"}
        description={
          <>
            Die nächste Zahlung findet am{" "}
            <strong>{formattedNextPayDate}</strong> statt.
          </>
        }
      />
    );
  }

  if (hasBookedProduct) {
    const trialEndIsoDate = hasActiveAboWithTrial
      ? paymentData.end_date_free
      : null;

    const description = hasActiveAboWithTrial ? (
      <div className="pt-1.5">
        Die erste Zahlung findet am <strong>{formattedNextPayDate}</strong>{" "}
        statt. Bis dahin nutzt du Freigeist im kostenlosen Testzeitraum.
      </div>
    ) : (
      <div className="pt-1.5">
        <span>
          {hasBoughtFutureSubscription ? (
            getSubscriptionScheduleText(paymentData, aboType)
          ) : (
            <>
              Die nächste Zahlung findet am{" "}
              <strong>{formattedNextPayDate}</strong> statt.
            </>
          )}
        </span>
      </div>
    );

    return (
      <BasicBox
        trialEndIsoDate={trialEndIsoDate}
        title={bookedProductName}
        logoColorClass={isMonthlyPackage ? "bg-gray-25" : "bg-green"}
        price={formattedProductPrice}
        description={description}
      />
    );
  }

  return (
    <div className="text-2xl text-red uppercase">
      Beim Laden der Abodaten ist etwas schiefgegangen. Bitte informiere das
      Freigeist-Team unter{" "}
      <a href="mailto:hilfe@freigeist-app.de">hilfe@freigeist-app.de</a>.
    </div>
  );
}
