import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import DaySlots from "../DaySlots";
import Timeslots from "./timeslots/TimeslotsComponent";
import FGEditor from "../../../components/editor/FGEditor";
import SidebarArticulation from "../parts/SidebarArticulationComponent";
import Material from "../../../components/material/Materials";
import {
  ueMaterialSlice,
  ueMaterialThunks,
} from "../../../../features/material/material-slice";

import { deepSet } from "../../../../common/utils/assortments";
import {
  selectLessonDuration,
  selectSettings,
} from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { selectUeMaterial } from "../../../../features/sequence/sequence-slice";
import { api } from "../../../../services/api";
import {
  selectSchoolRegion,
  selectSequenceDetail,
} from "../../../combined-selectors/index";
import { type RootState } from "../../../store";
import { type Slot } from "../../../../features/sequence/types";
import { moveDayslots } from "../../../../features/sequence/move-dayslots";
import { useReinitializingState } from "../../../../common/utils/hooks";

type OwnProps = { isVisible: boolean };

const selectProps = createSelector(
  [
    selectSequenceDetail,
    selectSettings,
    selectCurrentSchoolyear,
    selectSchoolRegion,
    selectLessonDuration,
  ],
  (sequenceDetail, settings, schoolyear, region, lessonDuration) => ({
    activeSlotIdx: sequenceDetail.active_slot_idx,
    active_sequence: sequenceDetail.active_sequence_item, // new,
    timeslots: sequenceDetail.timeslots,
    dayslots: sequenceDetail.slotdays, // zeitblöcke
    dayslotid: sequenceDetail.dayslotid,
    dayObjId: sequenceDetail.dayObjId,
    dayslotdate: sequenceDetail.dayslotdate,
    sequences: sequenceDetail,
    dayslot_title: sequenceDetail.dayslot_title,
    dayslot_tab: sequenceDetail.dayslot_tab,
    dayslot_note: sequenceDetail.dayslot_note,
    dayslot_hours: sequenceDetail.dayslot_hours,
    editor_idx: sequenceDetail.editor_idx,
    settings,
    schoolyear,
    region,
    lessonDuration,
  }),
);

// TODO how get we get a proper date here?
// very hacky way to get date
const hackyDateParsing = (dayslotdate: string, dayslotid: string) => {
  const year = Number(dayslotid.slice(0, 4));
  const day = Number(dayslotdate.split(" ")?.[1]?.replace(/\.$/, ""));
  const month = Number(dayslotid.slice(4).replace(new RegExp(`${day}$`), ""));

  if (!(year && month && day)) {
    return null;
  }
  const date = DateTime.fromObject({ year, day, month }, { zone: "local" });
  return date.isValid ? date : null;
};

function ArticulationComponent(ownProps: OwnProps) {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const sequenceId = params.sequenceId ?? "";
  const ueId = searchParams.get("ueId");
  const props = useSelector((state: RootState) =>
    selectProps(state, { sequenceId, ueId }),
  );

  // make sure updates in other tabs are reflected
  const [lessonTitle, setLessonTitle] = useReinitializingState(
    props.dayslot_title,
  );

  const [putSequenceTrigger] = api.endpoints.putSequence.useMutation();
  const [updateSettingsTrigger] = api.endpoints.updateSettings.useMutation();

  const newUpdateItems = (sequence: { timeslots: Slot[] }) => {
    putSequenceTrigger({
      sequenceId: Number(sequenceId),
      schoolyear: props.schoolyear,
      region: props.region,
      timeslots: sequence.timeslots,
    });
  };

  const handleDayNote = (note: string) => {
    const id = props.dayslotid;

    const newTimeslots = props.timeslots.map((el) =>
      String(el.id) === String(id) ? { ...el, note } : el,
    );

    newUpdateItems({ timeslots: newTimeslots });
  };

  /**
   * set the clicked active day from articulation to the settings object
   */
  const handleSequenceActiveDaySettings = (activeDay: number) => {
    const sequences = props.settings.settings.sequences ?? [];
    const sequenceIdNum = Number(sequenceId);
    const { schoolyear } = props;
    const index = sequences.findIndex(
      (elem) => elem.seq_id && Number(elem.seq_id) === sequenceIdNum,
    );

    const newSettings =
      index === -1
        ? // append new item to sequence settings
          deepSet(
            ["settings", "sequences", sequences.length],
            { seq_id: sequenceIdNum, active_day: activeDay },
            props.settings,
          )
        : // set active day on found sequence
          deepSet(
            ["settings", "sequences", index],
            { ...sequences[index], active_day: activeDay },
            props.settings,
          );

    updateSettingsTrigger({ ...newSettings, schoolyear });
  };

  const handleSlotMove = (data: {
    sourceIndex: number;
    targetIndex: number;
  }) => {
    const activeIndexBefore: number = props.activeSlotIdx;
    const activeItem = props.timeslots[activeIndexBefore];

    // move slots
    const update = moveDayslots(
      {
        sourceIndex: data.sourceIndex,
        targetIndex: data.targetIndex,
        slotDays: props.dayslots,
      },
      props.timeslots,
    );

    const activeIndexAfter: number = update.timeslots.findIndex((item: Slot) =>
      activeItem ? activeItem.ueId === item.ueId : false,
    );

    const newActiveItem = update.timeslots[activeIndexAfter];
    const newSlotDay = update.slotdays[activeIndexAfter];

    if (newActiveItem && newSlotDay) {
      putSequenceTrigger({
        sequenceId: Number(sequenceId),
        schoolyear: props.schoolyear,
        region: props.region,
        timeslots: update.timeslots,
      });
    }
  };

  /**
   * set id as dayslotid and check if an array element with this data exists.
   * if not create it and update state.
   * @param id
   */
  const setEditSlotId = (id: string, clicked_index: number) => {
    if (id !== props.dayslotid) {
      handleSequenceActiveDaySettings(clicked_index);
    }
  };

  /**
   * change the tab of active day
   * @param value
   * @param namespace
   */
  const changeDayslotTab = (value: Slot["tab"]) => {
    const id = props.dayslotid;

    const newTimeslots = props.timeslots.map((el) =>
      String(el.id) === String(id) ? { ...el, tab: value } : el,
    );

    newUpdateItems({ timeslots: newTimeslots });
  };

  const sequence_title = props.active_sequence.title;
  const title_dayslot_date = props.dayslotdate;
  const hours_text = props.dayslot_hours;
  const { title } = props.active_sequence;
  const { weeks } = props.active_sequence;
  const { hours } = props.active_sequence;
  const idx_update_key = `${props.dayslotid}-${props.editor_idx}`;
  let { dayslot_note } = props;

  if (!dayslot_note || dayslot_note === "<p>undefined</p>") {
    dayslot_note = "";
  }

  const unit_count = props.sequences.slot_duration / props.lessonDuration;

  const current_tab =
    props.dayslot_tab && props.dayslot_tab ? props.dayslot_tab : "notes-unit";

  const isMaterialVisible = ownProps.isVisible && current_tab === "material";

  const { dayslotdate, dayslotid, sequences } = props;
  const date =
    dayslotdate && dayslotid ? hackyDateParsing(dayslotdate, dayslotid) : null;

  const { ueMaterialModel } = sequences;
  const timeslots = props.timeslots.map((slot) => ({
    ...slot,
    hasMaterials: ueMaterialModel.material.some((material) =>
      material.ues.includes(slot.ueId),
    ),
  }));

  return (
    <div id="articluation_wrapper" className="tw-pf">
      <div className="bg-gray-80 pl-5">
        <div className="content-inner-wrapper">
          <DaySlots
            activeSlotIdx={props.activeSlotIdx}
            timeslots={timeslots}
            daylist={props.dayslots}
            onSlotClick={setEditSlotId}
            onSlotMove={handleSlotMove}
            lessonDuration={props.lessonDuration}
          />
        </div>
      </div>

      <div className="tw-pf font-bold pt-3.5 pb-3 px-5">
        <div className="flex font-bold gap-2 text-gray-10">
          <input
            type="text"
            className="flex-1 text-xl font-bold text-gray-10 placeholder:text-xl placeholder:font-bold"
            value={lessonTitle}
            onChange={(event) => {
              setLessonTitle(event.target.value);
            }}
            placeholder="Thema der Unterrichtseinheit hier eintragen"
            onBlur={(e) => {
              const id = props.dayslotid;
              const newTimeslots = props.timeslots.map((el) =>
                String(el.id) === String(id)
                  ? { ...el, title: e.target.value }
                  : el,
              );
              newUpdateItems({ timeslots: newTimeslots });
            }}
          />
          {date ? (
            <a
              className="text-sm underline opacity-60"
              href={`/kalender?weekstart=${date.toISODate()}`}
            >
              Im Kalender anzeigen
            </a>
          ) : null}
        </div>
        <div className="text-sm font-normal">
          {title_dayslot_date} | {unit_count}{" "}
          {unit_count > 1 ? "Schulstunden" : "Schulstunde"}
        </div>
      </div>

      <Tabs
        name="schoolunitTab"
        className="tabs-second-wrapper"
        handleSelect={changeDayslotTab}
        selectedTab={current_tab}
      >
        <div className="tab-links">
          <TabLink to="notes-unit">Notizen</TabLink>
          <TabLink to="material">Material</TabLink>
          <TabLink to="articulation">Artikulation</TabLink>
        </div>

        <div className="content">
          <TabContent for="notes-unit">
            <div className="editor-wrapper note-editor-wrapper">
              <FGEditor
                print_id={`print_note-${idx_update_key}`}
                key={`seq_note_editor-${idx_update_key}`}
                placeholder="Trage hier Notizen zu dieser Unterrichtseinheit ein"
                value={dayslot_note}
                onChange={() => {}}
                onSave={handleDayNote}
              />
            </div>
          </TabContent>

          <TabContent for="material">
            <Material
              sequenceId={sequenceId}
              ueId={props.dayObjId}
              actions={ueMaterialSlice.actions}
              thunks={ueMaterialThunks}
              modelSelector={selectUeMaterial}
              isVisible={isMaterialVisible}
            />
          </TabContent>

          <TabContent for="articulation">
            <div id="slot_edit" className="content-inner-wrapper">
              <div className="wrapper-slot-edit">
                <div className="timeslot-header">
                  <div className="flex">
                    <div className="sequence-title">{sequence_title}</div>
                  </div>
                  <div className="flex text-right">
                    {title_dayslot_date}
                    &nbsp;<span className="demiliter">|</span>&nbsp;
                    {hours_text} von {hours} {title} ({weeks} Wochen)
                  </div>
                </div>

                <div className="flex-wrapper">
                  <Timeslots />

                  <SidebarArticulation />

                  <div style={{ clear: "both" }} />
                </div>
              </div>
            </div>
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
}

export default ArticulationComponent;
