import { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "../../common/utils/routing";
import ArrowBack from "../../assets/images/svg/pfeil-zurueck.svg";
import { FreigeistHeader } from "../../common/web-components/freigeist-header";
import * as VIEW_ACTION from "../../features/dataview/actions";
import { selectCurrentSchoolyearData } from "../../features/current-schoolyear/current-schoolyear-slice";

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  authentication: state.authentication,
});

const mapDispatchToProps = {
  switchSchoolyear: VIEW_ACTION.loadSchoolyearData,
};

class LayoutSettings extends Component {
  constructor(props) {
    super(props);

    this.renderArchivContainer = this.renderArchivContainer.bind(this);
    this.switchArchivView = this.switchArchivView.bind(this);
  }

  componentDidMount() {
    document.getElementById("content-container").classList.remove("isblured");
    const element = document.getElementById("loading-spinner");
    element.classList.remove("running");
  }

  switchArchivView() {
    this.props.switchSchoolyear(this.props.current_schoolyear.latest, false);
  }

  renderArchivContainer() {
    return (
      <div className="archiv_container">
        <div className="row">
          <div className="label-left">
            Du siehst: Schuljahr {this.props.current_schoolyear.label}
          </div>
          <div className="action-right">
            <a onClick={this.switchArchivView}>
              zurück zum aktuellen Schuljahr
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const currentLocation = this.props.location.pathname;

    let title = "";
    if (currentLocation === "/downloads") {
      title = "Downloads";
    } else if (currentLocation === "/vorlagen") {
      title = "Vorlagen";
    } else {
      title = "Einstellungen";
    }

    let name = "";
    if (this.props.authentication) {
      name = this.props.authentication.name;
    }

    const { current_schoolyear } = this.props;
    let archiv_class;
    let archiv_container;
    if (!current_schoolyear.is_active) {
      archiv_class = "archiv_view";
      document.getElementById("body").classList.add("archiv_view");
      archiv_container = this.renderArchivContainer();
    } else {
      document.getElementById("body").classList.remove("archiv_view");
    }

    return (
      <div className={`layout-register ${archiv_class}`}>
        {archiv_container}

        <FreigeistHeader name="" classes={[]} activeTab="" />

        <div className="Main">
          <div>
            <div id="backlink_bar" className="mt-8 tw-pf">
              <div className="max-w-[1200px] mx-auto py-5 flex items-center">
                <div
                  className="flex justify-center items-center box-border font-bold h-[35px]
                      after:border-0 after:border-r-2 after:border-solid after:border-[#afafaf] after:h-full after:content-[''] after:block"
                >
                  <Link
                    to="/zeitleiste"
                    className="flex items-center h-full pr-2.5"
                  >
                    <ArrowBack width="0.5rem" />
                  </Link>
                </div>
                <div className="pl-2.5">
                  <h1 className="text-[21px] font-bold">{title}</h1>
                  <div className="text-sm font-bold opacity-40">
                    Benutzerkonto von {name}
                  </div>
                </div>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LayoutSettings));
