/**
 * Created by makkuz on 06.02.18.
 */

import { Component } from "react";

class ClassSubjectRowItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleHoverOut = this.handleHoverOut.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleHover() {
    this.setState({
      isHovered: true,
    });
    this.props.handleHover();
  }

  /**
   * click on subject item
   */
  handleItemClick() {
    this.props.handleItemClick(this.props.subject_id);
  }

  handleHoverOut() {
    this.setState({
      isHovered: false,
    });
    this.props.handleHoverOut();
  }

  render() {
    const hoverClass = this.state.isHovered ? "active" : "";
    const preselectClass = this.props.preselect ? "preselect" : "";

    return (
      <span
        className={`item ${hoverClass} ${preselectClass}`}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHoverOut}
        onClick={this.handleItemClick}
      >
        <span
          className="color circle"
          style={{ backgroundColor: this.props.color }}
        />
        <span className="name">{this.props.title}</span>
      </span>
    );
  }
}
export default ClassSubjectRowItem;
