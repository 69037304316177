/* eslint-disable import/no-import-module-exports */
import { createBrowserRouter } from "react-router-dom";
import { addListener } from "@reduxjs/toolkit";

import { createDataStrategy } from "./data-strategy";
import store, { type RootState, type AppDispatch } from "../store";

import routes from "./routes";

import { selectCurrentSchoolyear } from "../../features/current-schoolyear/current-schoolyear-slice";
import { selectSchoolRegion } from "../combined-selectors/index";
import { routerNavigate } from "../../features/routing/actions";

function createRouter() {
  const { dataStrategy, dispatchUnloadEvents } = createDataStrategy();
  const router = createBrowserRouter(routes, {
    dataStrategy,
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      // v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  });

  // add redux listener to invalidate loaders when things needed for API calls change
  const removeRevalidateListener = store.dispatch(
    addListener.withTypes<RootState, AppDispatch>()({
      predicate: (_, currentState, previousState) => {
        const currentRegion = selectSchoolRegion(currentState);
        const previousRegion = selectSchoolRegion(previousState);
        const currentYear = selectCurrentSchoolyear(currentState);
        const previousYear = selectCurrentSchoolyear(previousState);

        const schoolyearsSet: boolean =
          Boolean(currentYear) && Boolean(previousYear);
        const schoolyearChanged: boolean = currentYear !== previousYear;
        const regionsSet: boolean =
          Boolean(currentRegion) && Boolean(previousRegion);
        const regionChanged: boolean = currentRegion !== previousRegion;

        return (
          (schoolyearsSet && schoolyearChanged) || (regionsSet && regionChanged)
        );
      },
      effect: () => {
        router.revalidate();
      },
    }),
  );

  // add redux listener to allow triggering navigation with an action.
  // This is otherwise hard to achieve in thunks where hooks are not available
  // and the router cannot be imported due to circular dependencies
  const removeNavigateListener = store.dispatch(
    addListener.withTypes<RootState, AppDispatch>()({
      actionCreator: routerNavigate,
      effect: (action) => {
        router.navigate(action.payload.to, action.payload.opts);
      },
    }),
  );

  // handle hot reloading
  if (module.hot) {
    module.hot.dispose(() => {
      removeRevalidateListener();
      removeNavigateListener();
      dispatchUnloadEvents([]);
      router.dispose();
    });
  }

  return router;
}

export default createRouter;
