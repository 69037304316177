import { useEffect, useState } from "react";
import * as React from "react";
import { classList } from "../../../utils/classList";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import {
  defaultSettings,
  selectLessonDuration,
} from "../../../../features/settings/settings-slice";
import { api } from "../../../../services/api";
import { useAppSelector } from "../../../../app/hooks";

const messageLessonWarning =
  "Bist du sicher, dass deine Unterrichtsstunden so lang sein sollen?";
const messageLessonError = "Die Stundenlänge muss größer als 0 sein.";

type LessonState = {
  lessonDuration: number;
  editMode: boolean;
};

function LessonMinutesForm() {
  const year = useAppSelector(selectCurrentSchoolyear);
  const lessonDuration = useAppSelector(selectLessonDuration);

  const { isLoading, data: settings = defaultSettings } =
    api.endpoints.getSettings.useQueryState(year);

  const [state, setState] = useState<LessonState>({
    editMode: false,
    lessonDuration,
  });

  useEffect(() => {
    setState({
      ...state,
      lessonDuration,
    });
  }, [lessonDuration]);

  const [updateSettings] = api.useUpdateSettingsMutation();

  const saveLessonDuration = () => {
    const newSettings = {
      ...settings,
      schoolyear: year,
      settings: {
        ...settings.settings,
        lessonDuration: {
          ...settings.settings.lessonDuration,
          [year]: state.lessonDuration,
        },
      },
    };
    updateSettings(newSettings);
    setState({ ...state, editMode: false });
  };

  /**
   * @param val
   */
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = Math.round(event.target.valueAsNumber);
    setState({
      ...state,
      editMode: true,
      lessonDuration: val,
    });
  };

  const showMessage = state.lessonDuration > 240 || state.lessonDuration <= 0;
  const message =
    state.lessonDuration > 240 ? messageLessonWarning : messageLessonError;
  const disabled =
    state.lessonDuration <= 0 || Number.isNaN(state.lessonDuration);

  const lessonDurationText = !isLoading ? state.lessonDuration : "";

  return (
    <div id="lession-minutes-form" className="tw-pf">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="bg-gray-100 rounded mx-auto mb-4 max-w-[610px] pt-3 pb-1.5">
          {state.editMode ? (
            <div className="edit px-4 pb-10">
              <div className="field_input">
                <label>
                  <strong>Stundenlänge</strong>
                  <span className="block mt-8">
                    <input
                      type="number"
                      className="h-[40px] mr-4 pl-4 bg-gray-90 w-20 border border-gray-70 rounded"
                      min="1"
                      max="240"
                      value={state.lessonDuration}
                      onInput={handleInput}
                    />{" "}
                    Minuten
                  </span>
                </label>
              </div>
              {showMessage && (
                <div
                  className={classList({
                    "mt-6 rounded p-4 text-gray-100": true,
                    "bg-gray-25": state.lessonDuration > 240,
                    "bg-red": state.lessonDuration <= 0,
                  })}
                >
                  {message}
                </div>
              )}
              <div className="flex">
                <button
                  type="submit"
                  disabled={disabled}
                  className="fg-btn fg-btn-primary mt-6"
                  onClick={() => {
                    saveLessonDuration();
                  }}
                >
                  Speichern
                </button>
                <span className="pl-3">
                  <button
                    type="button"
                    className="fg-btn fg-btn-tertiary mt-6"
                    onClick={() => {
                      setState({
                        ...state,
                        editMode: false,
                      });
                    }}
                  >
                    Abbrechen
                  </button>
                </span>
              </div>
            </div>
          ) : (
            <div
              className={classList({
                "flex pl-4 pr-3": true,
                "opacity-50": isLoading,
              })}
            >
              <span className="font-bold">Stundenlänge</span>
              <span className="pl-32">{lessonDurationText} Minuten</span>
              <span className="ml-auto">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setState({
                      ...state,
                      editMode: true,
                    });
                  }}
                >
                  <img
                    src="/assets/images/edit-icon.png"
                    className="max-w-[23px]"
                  />
                </button>
              </span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export { LessonMinutesForm };
